import store from "../store"
export const abbreviateNumber = (number) => {
    const SI_SYMBOL = ["", "K", "M", "B", "T", "P", "E"];

    const absNumber = Math.abs(number);
    // what tier? (determines SI symbol)
    const tier = (Math.log10(absNumber) / 3) | 0;

    // if zero, we don't need a suffix

    if (tier === 0) {
        return number < 0 ? "(" + absNumber.toFixed(2) + ")" : absNumber.toFixed(2);
    }

    let suffix = "";
    if (tier >= 7) {
        suffix = "...";

    } else {
        // get suffix and determine scale
        suffix = SI_SYMBOL[tier];
    }


    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = absNumber / scale;
    const final = scaled.toFixed(2) + suffix;

    return number < 0 ? "(" + final + ")" : final;
};

export const cut_off_date = () => {
    const business = store.state.user.current_business;
    const cutOffSettings = business.cut_off_date;

    if (!cutOffSettings) {
        return null;
    }

    if (!cutOffSettings.is_on) {
        return null;
    }

    return cutOffSettings.cut_off_date;

}