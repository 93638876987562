import Vue from 'vue'
import VueRouter from 'vue-router'
import cart from "../views/Cart";
import Home from "../views/Home";
import itemScan from "../views/itemScan";
import Transactions from "../views/Transactions";
import inventory from "../views/Inventory";
import productDetail from "../views/productDetail.vue";
import Settings from "../views/Settings.vue";
import index from "../views/reports/index.vue";
import incomestatementComponent from "../views/reports/incomestatementComponent.vue";
import cashRegistry from "../views/reports/cashRegistry.vue";
import allproducts from "../views/reports/allproducts.vue";
import Orders from "../views/Orders.vue";
import OrderSummaries from "../views/orderSummaries.vue";
import OrderList from "../views/OrderList.vue";
import NewOrder from "../views/NewOrder.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings
    },

    {
        path: '/reports',
        name: 'Reports',
        component: index,
        children: [
            {
                path: "",
                name: "IncomeStatement",
                component: incomestatementComponent
            },
            {
                path: "cash-register",
                name: "CashRegister",
                component: cashRegistry
            },
            {
                path: "products-income",
                name: "ProductsIncome",
                component: allproducts
            }
        ]
    },
    {
        path: '/cart',
        name: 'Cart',
        component: cart
    },

    {
        path: '/item-scan',
        name: 'ItemScan',
        component: itemScan
    }
    , {
        path: '/transactions',
        name: 'Transaction',
        component: Transactions
    }, {
        path: '/inventory',
        name: 'Inventory',
        component: inventory
    }, {
        path: '/inventory/view/:id',
        name: 'InventoryPreview',
        component: productDetail
    },
    {
        path: '/transactions/:id',
        name: 'Transaction',
        component: Transactions
    }, {
        path: "/orders",
        name: "orders",
        component: Orders,
        children: [

            {
                path: "",
                name: "OrderSummaries",
                component: OrderSummaries
            },

            {
                path: "list",
                name: "OrderList",
                component: OrderList
            } ,   {
                path: "list/:order_id",
                name: "OrderList",
                component: OrderList
            } ,
            {
                path: "new",
                name: "NewOrder",
                component: NewOrder
            }

        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
