<template>
  <div>
    <v-row>
      <v-col col="8" class="mx-auto">
        <v-row>
          <v-col cols="9" class="text-muted">
            <h4 class="font-weight-light">{{ filterDiscription }}
              <span v-if="filter_applied">({{ from | humanDate }} - {{ to |humanDate }})</span>
            </h4>
          </v-col>
          <v-col cols="3">
            <v-spacer></v-spacer>
            <v-btn

                rounded
                depressed
                height="50"
                @click="filterMenu=true"
                v-if="$vuetify.breakpoint.mobile"
            >
              <v-badge
                  color="red"
                  dot
                  :value="filter_applied"
                  left
                  overlap

              >
                <v-icon>mdi-filter-outline</v-icon>
              </v-badge>
              Filter
            </v-btn>
            <v-bottom-sheet v-if="$vuetify.breakpoint.mobile" v-model="filterMenu">
              <v-card flat>

                <v-card-title class="font-weight-light">
                  Filter Transactions
                  <v-spacer></v-spacer>
                  <v-btn v-if="$vuetify.breakpoint.mobile" text @click="filterMenu=false">Close
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>



                  <v-list>
                    <v-list-item v-if="filter_applied">
                      <v-list-item-title>
                        <v-btn @click="reset" text color="red">Clear Filter</v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-for="(filter,i) in filters"
                        :key="i"
                        class="border-bottom"
                        @click="selectFilter(filter)"
                    >

                      <v-list-item-content>
                        <v-list-item-title>{{ filter.description }}</v-list-item-title>
                        <v-list-item-subtitle>{{ filter.from | humanDate }} - {{
                            filter.to |
                                humanDate
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                    </v-list-item>
                  </v-list>

                  <p>Custom</p>

                  <v-menu
                      v-model="datemenu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="from"
                          label="From"
                          v-on="on"
                          outlined

                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="from" no-title
                                   @input="datemenu1 = false"></v-date-picker>
                  </v-menu>

                  <v-menu
                      v-model="datemenu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="to"
                          label="To"
                          v-on="on"
                          outlined

                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="to" no-title @input="datemenu2 = false"></v-date-picker>
                  </v-menu>

                </v-card-text>
                <v-card-actions>
                  <v-btn
                      block
                      rounded
                      large
                      color="blue darken-4"
                      dark
                      @click="selectFilter({from:from,to:to, description:'Custom'})"
                  >Apply Filter
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-bottom-sheet>

            <v-menu
                transition="scale-transition"
                bottom
                left
                max-width="400px"
                v-model="filterMenu"
                :close-on-content-click="false"
                v-else

            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    rounded
                    depressed
                    height="50"
                >
                  <v-badge
                      color="red"
                      dot
                      :value="filter_applied"
                      left
                      overlap
                  >
                    <v-icon>mdi-filter-outline</v-icon>
                  </v-badge>
                  Filter
                </v-btn>

              </template>

              <v-card flat>

                <v-card-title class="font-weight-light">
                  Filter Transactions
                  <v-spacer></v-spacer>
                  <v-btn v-if="$vuetify.breakpoint.mobile" text @click="filterMenu=false">Close
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>



                  <v-list>
                    <v-list-item v-if="filter_applied">
                      <v-list-item-title>
                        <v-btn @click="reset" text color="red">Clear Filter</v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-for="(filter,i) in filters"
                        :key="i"
                        class="border-bottom"
                        @click="selectFilter(filter)"
                    >

                      <v-list-item-content>
                        <v-list-item-title>{{ filter.description }}</v-list-item-title>
                        <v-list-item-subtitle>{{ filter.from | humanDate }} - {{
                            filter.to |
                                humanDate
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                    </v-list-item>
                  </v-list>

                  <p>Custom</p>

                  <v-menu
                      v-model="datemenu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="from"
                          label="From"
                          v-on="on"
                          outlined
                          color="orange"

                      ></v-text-field>
                    </template>
                    <v-date-picker
                        color="orange"
                        v-model="from" no-title
                                   @input="datemenu1 = false"></v-date-picker>
                  </v-menu>

                  <v-menu
                      v-model="datemenu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="to"
                          label="To"
                          v-on="on"
                          outlined
                          color="orange"

                      ></v-text-field>
                    </template>
                    <v-date-picker
                        color="orange"
                        v-model="to" no-title @input="datemenu2 = false"></v-date-picker>
                  </v-menu>

                </v-card-text>
                <v-card-actions>
                  <v-btn
                      block
                      rounded
                      x-large
                      color="orange"
                      dark
                      depressed
                      @click="selectFilter({from:from,to:to, description:'Custom'})"
                  >Apply Filter
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-menu>


          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "filterBarComponent",
  props: {
    auto: {
      default: true,
      type: Boolean
    },
    title:{
      default:"All Transactions",
      type:String
    }
  },
  data() {
    return {
      datemenu1: false,
      datemenu2: false,
      filterDiscription: "All Transactions",
      from: '',
      to: '',
      filterMenu: false,
      channels: [],
      selectedChannel: [],
      filter: null,
      filter_applied: false,
    }
  },

  computed: {
    balance() {
      return this.$store.state.wallet ? this.$store.state.wallet.balance : 0;

    },
    filters() {
      const now = moment();
      return [
        {
          description: "Last 3 Months",
          from: moment(now).subtract(3, 'M').format("Y-MM-DD"),
          to: moment().format("Y-MM-DD"),
        },

        {
          description: "Last 6 Months",
          from: moment(now).subtract(6, 'M').format("Y-MM-DD"),
          to: moment().format("Y-MM-DD")
        },
        {
          description: "Last 1 Year",
          from: moment().subtract('y', 1).format("Y-MM-DD"),
          to: moment().format("Y-MM-DD")
        },

      ]
    }
  },
  watch: {

    balance() {
      if (this.auto) {
        this.selectFilter(this.filter);
      }
    }

  },
  methods: {
    reset() {
      this.filter_applied = false;

      this.filterDiscription = "All Transactions";
      this.filterMenu = false;
      this.$emit('reset');
    },
    selectFilter(filter) {
      if (filter) {
        this.filterDiscription = filter.description;

        this.$emit('applied', filter);
        this.filter_applied = true;
      }
      this.filterMenu = false;

    },
    getChannels() {
      axios.get('/api/bw/channels')
          .then(res => {
            this.channels = res.data;
            this.channels.unshift('All');

          })
    }
  },
  mounted() {

    this.filterDiscription=this.title;

    const now = moment();
    this.from = moment(now).subtract(3, 'M').format("Y-MM-DD");
    this.to = moment().format("Y-MM-DD");
    if (this.auto) {

      this.selectFilter({
        from: this.from,
        to: this.to,
        description: "Last 3 Months"
      });
    }
    this.getChannels();
  }
}
</script>

<style scoped>

</style>
