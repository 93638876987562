<template>
  <span class="white">
    <v-row style="height: 100vh">
      <v-col :style="$vuetify.theme.isDark ? 'background-color:#FF98000D' : 'background-color:#FFFFFFFF'" class="pa-15" cols="12" md="4" sm="4" xl="12">

<v-stepper elevation="0" v-model="loginStep">

  <v-stepper-items >

  <v-stepper-content step="1">


            <center>
              <h2
                  class="font-weight-light text-center"

              >Log in to your Storefront</h2
              >
              <span class="d-block">You can login with existing Built account</span>
            </center>

            <v-form ref="loginForm" class="mt-6">
              <v-text-field
                  v-model="email"
                  :disabled="progress"
                  :rules="[
                  $store.state.validationRules.required,
                  $store.state.validationRules.email,
                ]"
                  color="orange"
                  flat
                  outlined
                  placeholder="Email"
                  type="email"
                  @keydown.enter="login"
              ></v-text-field>
              <v-text-field
                  v-model="password"
                  :append-icon="
                  password_visible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                "
                  :disabled="progress"
                  :rules="[$store.state.validationRules.required]"
                  :type="password_visible ? 'text' : 'password'"
                  color="orange"
                  flat
                  outlined
                  placeholder="Password"
                  @keydown.enter="login"
                  @click:append="password_visible = !password_visible"
              ></v-text-field>

            </v-form>


              <v-btn
                  :loading="progress"
                  block
                  color="orange"
                  dark
                  depressed
                  rounded
                  x-large
                  @click="login"
              >Login</v-btn>

        <p class="mt-8 text-center">Don't have an account yet?</p>
        <v-btn @click="loginStep=2" block color="orange" x-large rounded depressed dark text>Create Account <v-icon>mdi-arrow-right</v-icon></v-btn>

  </v-stepper-content>
    <v-stepper-content step="2">
      <signup-component></signup-component>

        <p class="mt-8 text-center">Already have an account?</p>
        <v-btn @click="loginStep=1" block color="orange" x-large rounded depressed dark text><v-icon>mdi-arrow-left</v-icon>Login</v-btn>

    </v-stepper-content>
  </v-stepper-items>

</v-stepper>
      </v-col>

      <v-col v-if="!$vuetify.breakpoint.mobile"
             cols="12"
             sm="8"
             class="orange"
      >

        <h1 class="text-h3 white--text font-weight-black mt-15 text-center">Welcome to <img  width="140" src="https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo2.d9c417ee.png&w=828&q=75"/> POS</h1>

        <v-carousel cycle :show-arrows="false" hide-delimiters>

          <v-carousel-item class="text-center white--text">

            <h1 class="white--text font-weight-light">Run your storefront the smart way with <br> Built POS anywhere on the internet.</h1>
        <v-img class="mx-auto mt-5" width="70%" src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/built_pos_framed.png"></v-img>
          </v-carousel-item>

          <v-carousel-item class="text-center white--text">
            <h1 class="white--text font-weight-light">Gain realtime insights, view, print <br> and send receipts.</h1>
        <v-img class="mx-auto mt-5" width="70%" src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/insights.png"></v-img>
          </v-carousel-item>

          <v-carousel-item class="text-center white--text">
            <h1 class="white--text font-weight-light">Accept instant payments in <br> Ghana and Kenya.</h1>
        <v-img class="mx-auto mt-5" width="70%" src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/pos_payment.png"></v-img>
          </v-carousel-item>


        </v-carousel>

        <div class="text-center ma-16 pl-16 pr-16 white--text">
          © 2024 Built Financial Technologies Inc. "Built Online Accounting", "Thrive Microcredit", "Built Financial Technologies Ltd" are registered subsidiaries of the Company.
Built is not a bank. Built virtual cards and accounts are issued in partnership with licensed banks and mobile money operators.
          <p><a class="white--text ma-3" href="https://built.africa/pos">Home</a> <a class="white--text ma-3" href="https://support.built.africa/en/?_gl=1*5ovzwe*_gcl_au*MTAxMTYwMzc4MC4xNzI0NzY0Mjg0LjE2NzgwMTQ0MDAuMTcyNTU2NzY4MC4xNzI1NTY3NzUw">Help</a> <a class="white--text ma-3" href="https://built.africa/gh/termsOfService">Terms of use</a><a class="white--text ma-3" href="https://built.africa/gh/privacy&policy">Privacy Policy</a></p>
        </div>


      </v-col>
    </v-row>
  </span>
</template>

<script>
import SignupComponent from "./SignupComponent.vue";

export default {
  name: "loginComponent",
  components: {SignupComponent},
  data() {
    return {
      email: "",
      password: "",
      password_visible: false,
      progress: false,
      loginStep:1
    };
  },
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.progress = true;
        const formData = new FormData();
        formData.append("email", this.email);
        formData.append("password", this.password);

        window.axios
          .post("/api/auth/login", formData)
          .then((res) => {
            document.cookie = "token=" + res.data.access_token;

            location.reload();

            this.progress = false;
          })
          .catch(() => {
            this.progress = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
