<template>
  <span>
      <cash-register-skeleton v-if="!report"></cash-register-skeleton>

<span v-else>
<v-row class="border-top">
  <v-col cols="12" sm="5">
    <filter-bar-component @applied="getReport"></filter-bar-component>
  </v-col>
  <v-col cols="12" sm="7" class="text-right">
    <h1 class=" font-weight-black">Collections Summary</h1>
  </v-col>
</v-row>

  <v-row>


    <v-col class="rounded-lg" :class="$vuetify.theme.isDark ? 'dark-orange' : 'orange lighten-5'" cols="12" v-if="report">
      <v-row dense>

        <v-col cols="12" sm="4">
          <v-card flat :loading="progress" height="183">
            <template v-slot:progress>
              <v-progress-linear color="orange" indeterminate></v-progress-linear>
            </template>
            <v-card-text class="text-center pa-16">
 <v-tooltip bottom>

                <template v-slot:activator="{on}">


              <h1 v-on="on" class="font-weight-black  ">{{
                  abbreviateNumber((report.cash_invoice_collections + report.cash_sales_collections))  | currency_symbol
                }}</h1>
                </template>
   <span>{{
       (report.cash_invoice_collections + report.cash_sales_collections) | toMoney | currency_symbol
     }}</span>
 </v-tooltip>
            <small class="text--disabled">Total Collections</small>
            </v-card-text>
          </v-card>
        </v-col>


        <v-col cols="12" sm="4">
          <v-card flat :loading="progress">
             <template v-slot:progress>
              <v-progress-linear color="orange" indeterminate></v-progress-linear>
            </template>
            <v-card-text class="text-center">
              <v-progress-circular width="10" color="orange" size="100" v-model="totalCashCollectionPercent">
                <strong>
                    {{ totalCashCollectionPercent }}%

                </strong>
              </v-progress-circular>
              <v-tooltip bottom>

                <template v-slot:activator="{on}">


              <h1 v-on="on" class="font-weight-black mt-2">{{
                  abbreviateNumber(report.total_cash_collections) | currency_symbol
                }}</h1>
                </template>
                <span>{{
                    (report.total_cash_collections) | toMoney | currency_symbol
                  }}</span>
              </v-tooltip>

            <small class="text--disabled">Total Cash Collections</small>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card flat :loading="progress">
             <template v-slot:progress>
              <v-progress-linear color="orange" indeterminate></v-progress-linear>
            </template>
            <v-card-text class="text-center">
                <v-progress-circular width="10" color="green" size="100" v-model="totalNonCashCollectionPercent">
                <strong>
                    {{ totalNonCashCollectionPercent }}%

                </strong>
              </v-progress-circular>

              <v-tooltip bottom>

                <template v-slot:activator="{on}">

              <h1 v-on="on" class="font-weight-black mt-2">{{
                  abbreviateNumber(report.total_non_cash_collections) | currency_symbol
                }}</h1>
                </template>
              <span>{{ (report.total_non_cash_collections) | toMoney | currency_symbol }}</span>
              </v-tooltip>

            <small class="text--disabled">Total non-Cash Collections(Online)</small>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row dense>
        <v-col cols="12" sm="12">
          <v-card :loading="progress" flat >
             <template v-slot:progress>
              <v-progress-linear color="orange" indeterminate></v-progress-linear>
            </template>
            <v-card-title class="border-bottom">
            <v-spacer></v-spacer><v-icon large color="orange">mdi-printer-pos</v-icon>  <h2>Collections</h2>
            </v-card-title>
            <v-card-text class="pt-4" style="height: 600px; overflow: auto">
              <v-row dense>

                <v-col cols="12" sm="4">
                  <v-card class="text-center" outlined>
                    <v-card-text class="pa-5">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">


                      <h3 v-on="on"
                          class="font-weight-black mb-0">{{
                          abbreviateNumber(report.total_cash_from_sales) |currency_symbol
                        }}</h3>
                             </template>
                        <span>{{ report.total_cash_from_sales | toMoney |currency_symbol }}</span>
                      </v-tooltip>
                      <small class="text--disabled">Total Collections</small>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-card class="text-center" outlined>
                    <v-card-text class="pa-5">
                         <v-tooltip bottom>
                        <template v-slot:activator="{on}">


                      <h3 v-on="on" class="font-weight-black mb-0">{{
                          abbreviateNumber(report.cash_sales_collections) |currency_symbol
                        }}</h3>
                        </template>
                           <span>{{ report.cash_sales_collections | toMoney |currency_symbol }}</span>
                         </v-tooltip>
                      <small class="text--disabled">Total cash Collections</small>
                      <v-progress-linear height="8" rounded color="orange"
                                         :value="totalSalesCashCollectionPercent"></v-progress-linear>

                    </v-card-text>

                  </v-card>
                </v-col>

    <v-col cols="12" sm="4">
                  <v-card class="text-center" outlined>
                    <v-card-text class="pa-5">
                         <v-tooltip bottom>
                        <template v-slot:activator="{on}">

                      <h3 v-on="on" class="font-weight-black mb-0">{{
                          abbreviateNumber( report.non_cash_sales_collections)  |currency_symbol
                        }}</h3>
                        </template>
                                                     <span>{{
                                                         report.non_cash_sales_collections | toMoney |currency_symbol
                                                       }}</span>

                         </v-tooltip>

                      <small class="text--disabled">Total non-cash collections(online)</small>
                      <v-progress-linear height="8" rounded color="green"
                                         v-model="totalSalesCashNonCollectionPercent"></v-progress-linear>
                    </v-card-text>

                  </v-card>
                </v-col>

                <v-col cols="12">
                  <strong class="font-weight-black">Collections by cashiers:</strong>
                    <v-list two-line>
                      <v-list-item :key="person.name" v-if="person.amount" class="border-bottom"
                                   v-for="person in report.collection_by_cashiers">
                        <v-list-item-avatar rounded="xl" :size="70">
                          <avatar-component :size="70" :label="person.name"></avatar-component>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                              class="font-weight-black">{{
                              person.amount | toMoney | currency_symbol
                            }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                              person.name
                            }}({{
                              ((person.amount / report.total_cash_from_sales) * 100).toFixed(2)
                            }}%)</v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-progress-linear height="8" rounded color="orange"
                                               :value="((person.amount/report.total_cash_from_sales)*100).toFixed(2)"></v-progress-linear>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                </v-col>

              </v-row>
            </v-card-text>

          </v-card>
        </v-col>




      </v-row>
    </v-col>



  </v-row>


</span>
      </span>

</template>

<script>
import FilterBarComponent from "../../components/filterBarComponent";
import AvatarComponent from "../../components/avatarComponent";
import CashRegisterSkeleton from "./cashRegisterSkeleton";
import {abbreviateNumber} from "../../utils";

export default {
  name: "cashRegistry",
  components: {CashRegisterSkeleton, AvatarComponent, FilterBarComponent},
  data() {
    return {
      report: null,
      progress:false
    }
  },
  computed: {


    totalInvoiceCashCollectionPercent() {
      let p = 0;
      if (this.report) {


        p = ((this.report.cash_invoice_collections / this.report.total_cash_from_invoices) * 100).toFixed(2);


        return p;

      }

      return p;

    },

    totalInvoiceCashNonCollectionPercent() {
      let p = 0;
      if (this.report) {


        p = ((this.report.non_cash_invoice_collections / this.report.total_cash_from_invoices) * 100).toFixed(2);


        return p;

      }

      return p;

    },


    totalSalesCashNonCollectionPercent() {
      let p = 0;
      if (this.report) {


        p = ((this.report.non_cash_sales_collections / this.report.total_cash_from_sales) * 100).toFixed(2);


        return p;

      }

      return p;

    },


    totalSalesCashCollectionPercent() {
      let p = 0;
      if (this.report) {


        p = ((this.report.cash_sales_collections / this.report.total_cash_from_sales) * 100).toFixed(2);

        return p;

      }

      return p;

    },
    totalCashCollectionPercent() {
      let p = 0;
      if (this.report) {
        const total = this.report.total_cash_collections + this.report.total_non_cash_collections;


        p = ((this.report.total_cash_collections / total) * 100).toFixed(2);


        return p;

      }

      return p;

    },
    totalNonCashCollectionPercent() {
      let p = 0;
      if (this.report) {
        const total = this.report.total_cash_collections + this.report.total_non_cash_collections;


        p = ((this.report.total_non_cash_collections / total) * 100).toFixed(2);


        return p;

      }

      return p;

    }
  },
  methods: {
    abbreviateNumber,
    getReport(data) {


      this.progress=true;
      const url = data ? "/api/analytics/cash-register?from=" + data.from + "&to=" + data.to : "/api/analytics/cash-register";
      axios.get(url)
          .then(res => {
            this.report = res.data;
            this.progress=false;

          })
    }
  },
  mounted() {
    this.getReport();
  }
}
</script>

<style scoped>

</style>