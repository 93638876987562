<template>
  <v-row dense class="mt-5">
    <v-col cols="12" sm="8" class="mx-auto">
      <v-card flat rounded="lg">
        <v-list-item-title>
          <v-tabs
              v-model="tab"
              centered
              color="orange"
              icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#profile">
              Profile Settings
              <v-icon>mdi-account-outline</v-icon>
            </v-tab>

            <v-tab href="#business"
                   v-if="$store.state.user.is_admin"
            >
              Business Settings
              <v-icon>mdi-briefcase-outline</v-icon>
            </v-tab>

            <v-tab href="#billing"
                   v-if="$store.state.user.is_admin"
            >
              Billing
              <v-icon>mdi-card-outline</v-icon>
            </v-tab>

            <v-tab href="#users"
                   v-if="$store.state.user.is_admin"
            >
              Users & Permissions
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-tab>


            <v-tab href="#audit">
              Audit Trail
              <v-icon>mdi-binoculars</v-icon>
            </v-tab>


          </v-tabs>

        </v-list-item-title>
        <v-card-text>

          <v-tabs-items v-model="tab">

            <v-tab-item
                value="profile"
            >
              <profile-component></profile-component>
            </v-tab-item>

            <v-tab-item
                value="business"
                v-if="$store.state.user.is_admin"
            >
              <business-profile-component></business-profile-component>
            </v-tab-item>

            <v-tab-item
                value="billing"
                v-if="$store.state.user.is_admin"
            >
              <subscriptions-component></subscriptions-component>
            </v-tab-item>

            <v-tab-item
                value="users"
                v-if="$store.state.user.is_admin"
            >
              <users-and-permission-component></users-and-permission-component>
            </v-tab-item>

            <v-tab-item
                value="audit"
            >

              <audit-trail-component></audit-trail-component>

            </v-tab-item>


          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>
<script>
import {defineComponent} from 'vue'
import ProfileComponent from "../components/ProfileComponent.vue";
import BusinessProfileComponent from "../components/BusinessProfileComponent.vue";
import UsersAndPermissionComponent from "../components/UsersAndPermissionComponent.vue";
import AuditTrailComponent from "../components/auditTrailComponent.vue";
import SubscriptionsComponent from "../components/subscriptionsComponent.vue";

export default defineComponent({
  name: "Settings",
  components: {
    SubscriptionsComponent,
    AuditTrailComponent, UsersAndPermissionComponent, BusinessProfileComponent, ProfileComponent},
  data() {
    return {
      tab: "profile"
    }
  }
})
</script>


<style scoped>

</style>