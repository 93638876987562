<template>
  <v-row class="fill-height white">
    <v-col cols="12" sm="6">
      <v-card flat :class="$vuetify.breakpoint.mobile ? '':'pl-15 pr-15 ml-15 mr-15'">
        <v-progress-circular color="orange" indeterminate class="mx-auto" size="150"
                             v-if="loading"></v-progress-circular>

        <v-card-text style="height: 90vh; overflow:auto" v-else-if="pos_plan">

          <h1
              class="text-center orange--text font-weight-black"
              style="margin-top: 20%">Subscribe now to continue
            for
          </h1>
          <h1 class="text-center orange--text text-h2 mt-4" v-if="pos_plan">
            {{ pos_plan.base_currency_code }}{{ pos_plan.amount |  toMoney }}/Month
          </h1>
          <v-form class="mt-5">
            <v-autocomplete v-model="cycle" :items="cycles" outlined placeholder="Select your billing cycle"
                            color="orange"
                            item-color="orange"></v-autocomplete>
          </v-form>
          <p class="text-center font-weight-light text-h5 " :class="DISCOUNT_AMOUNT>0 ? 'cancel' : ''">
            Amount:{{ pos_plan.base_currency_code }}{{ this.AMOUNT |  toMoney }}
          </p>

          <p v-if="DISCOUNT_AMOUNT>0" class="text-center font-weight-light  text-h5">
            Discount:{{ pos_plan.base_currency_code }}{{ this.DISCOUNT_AMOUNT |  toMoney }}({{ discountP }}%)
          </p>

          <p v-if="DISCOUNT_AMOUNT>0" class="text-center font-weight-light  text-h5">
            Amount Due:{{ pos_plan.base_currency_code }}{{ this.AMOUNT_DUE |  toMoney }}
          </p>

          <v-btn @click="showMobileMoneyCheckout=true"
                 v-if="HAS_MOBILE_MONEY"
                 block color="orange" dark rounded x-large depressed class="mt-5">Pay with mobile Money</v-btn>
          <v-btn :loading="card_loading" @click="initCardPayment" block color="orange" :outlined="HAS_MOBILE_MONEY" dark rounded x-large depressed class="mt-5">Pay with Card</v-btn>
          <v-btn @click="showCouponDialog=true" class="mt-3" color="orange" rounded block text depressed x-large>Apply
            coupon
          </v-btn>

        </v-card-text>

      </v-card>

    </v-col>
    <v-col cols="12" sm="6" class="orange white--text text-center" style="height: 100vh; position:relative"
           v-if="!$vuetify.breakpoint.mobile">

      <div class="vertical-center text-center mx-auto" style="width: 100%">
        <v-img width="200" class="mx-auto" style="margin-top: 20%" src="/img/clock_stop.svg"></v-img>
        <p class="text-h2 mt-10 text-center">Hello {{ $store.state.user.first_name }},</p>
        <p class="text-h5 font-weight-light ma-4">Your subscription to
          <img width="60" class="mt-2"
               src="https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo2.d9c417ee.png&w=828&q=75"/>POS
          for
          <strong class="font-weight-black">{{ $store.state.user.current_business.name }}</strong> has ended</p>
        <v-btn @click="showLogout=true" color="orange lighten-5" text small>Log Out</v-btn>


      </div>

    </v-col>


    <v-dialog
        v-if="pos_plan"
        width="500"
        :persistent="$store.state.momo.progress"
        v-model="showMobileMoneyCheckout">
<v-card>

      <checkout
      :amount="AMOUNT_DUE"
      :account_id="0"
      :record_id="cycle"
      @paymentComplete="send_success"
      :record_type="'subscription-'+pos_plan.name.toLowerCase()"
      >
      </checkout>
</v-card>

    </v-dialog>

    <v-dialog width="500" v-model="showLogout">
      <v-card>
        <v-card-title>Confirm Logout</v-card-title>
        <v-card-text>
          <h1 class="font-weight-light">Do you want to logout?</h1>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loggingOut" @click="logOut" color="red" block rounded depressed x-large dark>Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="400" v-model="showCouponDialog" :persistent="couponLoading">
      <v-card>
        <v-card-title>Redeem your coupon</v-card-title>
        <v-card-text>
          <v-form ref="couponForm">
            <v-text-field
                color="orange"
                v-model="couponCode"
                outlined placeholder="Enter coupon code"
                :rules="[store.state.validationRules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
              block
              color="orange"
              depressed
              dark
              rounded
              :loading="couponLoading"
              x-large
              @click="redeemCoupon"
          >Apply Coupon
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import {defineComponent} from 'vue'
import PhoneNumberInput from "./PhoneNumberInput.vue";
import store from "../store";
import moment from "moment";
import Checkout from "./Checkout.vue";

export default defineComponent({
  name: "SubscribeComponent",
  components: {Checkout, PhoneNumberInput},
  data() {
    return {
      showCouponDialog: false,
      showLogout: false,
      loggingOut: false,
      plans: [],
      loading: false,
      couponLoading: false,
      card_loading:false,
      couponCode: "",
      discountP: 0,
      showMobileMoneyCheckout: false,
      cycle: 3,
      cycles: [
        {
          value: 3,
          text: "Every three months"
        },
        {
          value: 4,
          text: "Every four months"
        }, {
          value: 5,
          text: "Every five months"
        },
        {
          value: 6,
          text: "Every six months"
        },
        {
          value: 7,
          text: "Every seven months"
        }
        ,
        {
          value: 8,
          text: "Every eight months"
        }
        ,
        {
          value: 9,
          text: "Every nine months"
        }
        ,
        {
          value: 10,
          text: "Every ten months"
        }
        ,
        {
          value: 11,
          text: "Every eleven months"
        }
        ,
        {
          value: 12,
          text: "Annually"
        }
        ,
        {
          value: 24,
          text: "Every two years"
        }


      ]
    }
  },
  computed: {
    HAS_MOBILE_MONEY(){
      const country_codes=["GH","SL","KE","RW"];

      return this.business &&  country_codes.includes(this.business.country.code);

    },
    business(){
      return this.store.state.user.current_business;
    },
    store() {
      return store
    },
    pos_plan() {
      return this.plans.find(plan => {
        return plan.name.toLowerCase() === "starter";
      })
    },

    DISCOUNT_AMOUNT() {

      if (this.discountP > 0) {

        return (this.discountP / 100) * this.AMOUNT;

      }

      return 0;

    },
    AMOUNT() {

      if (this.pos_plan) {
        return this.pos_plan.amount * this.cycle;
      }

      return 0;

    },
    AMOUNT_DUE() {

      return this.AMOUNT - this.DISCOUNT_AMOUNT;

    },
    reference() {
      let text = "";
      let possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      let d = new Date();
      let timestamp = d.getTime();
      return "Subscription_" + timestamp + "" + text;
    }



  },
  methods: {
    send_success() {

      location.reload();

    },
    send_success_direct() {
      this.progress = true;
      let formData = new FormData();
      formData.append("startdate", moment().format("Y-M-D"));
      formData.append("enddate", moment().add(this.cycle, "months").format("Y-M-D"));
      formData.append("amount", this.AMOUNT_DUE);
      formData.append("plan", this.pos_plan.name);
      formData.append("coupon_applied", this.discountP > 0);
      formData.append("coupon_code", this.couponCode);
      formData.append("billing_cycle", this.cycle);
      axios
          .post("/api/appsubcription", formData)
          .then((res) => {
            this.progress = false;
            location.reload();
          })
          .catch((error) => {
            this.progress = false;

          });
    },
    redeemCoupon() {

      if (this.$refs.couponForm.validate()) {

        this.couponLoading = true;

        axios.post("/api/coupon", {
          code: this.couponCode
        })
            .then(res => {
              this.discountP = res.data.discount;
              this.couponLoading = false;
              this.showCouponDialog = false;
              this.couponCode = ""
              if (this.discountP >= 100) {
                this.send_success_direct();
              }

            })

            .catch(() => {
              this.couponLoading = false;
            })

      }

    },

    logOut() {

      this.loggingOut = true;

      axios.get("/api/auth/logout")
          .then(res => {

            this.loggingOut = false;
            window.location.reload();
          })
          .catch(() => {
            this.loggingOut = false;

          })


    },
    getPlans() {
      this.loading = true;
      axios.get("/api/subscriptionplans/" + this.$store.state.user.current_business.country_id)
          .then(res => {

            this.plans = res.data;

            this.loading = false;

          })
          .catch(() => {
            this.loading = false;

          })

    },
    initCardPayment() {
      this.card_loading = true;
      const payload = {
        amount: this.AMOUNT_DUE,
        type: "subscription-" + this.pos_plan.name.toLowerCase(),
        type_id: this.cycle,
        reference: this.reference
      };

      axios.post("/api/collection", payload).then((res) => {
        this.card_loading = false;

        this.$launchFlutterwave({
          tx_ref: res.data.reference,
          amount: this.AMOUNT_DUE,
          country: this.$store.state.user.current_business.country.code,
          currency: this.pos_plan.base_currency_code,
          payment_options: "card,mobilemoney,ussd",
          customer: {
            email: this.$store.state.user.email,
            phonenumber: this.$store.state.user.phone_number,
            name: this.$store.state.user.first_name + " " + this.$store.state.user.last_name,
            billing_cycle: this.cycle,
            plan: this.pos_plan.name,
          },
          callback: function () {
          },
          customizations: {
            title: `Subscribe to the ${this.pos_plan.name} plan for ${this.pos_plan.base_currency_code} ${this.AMOUNT_DUE}.00 every ${this.cycle} months`,
            description: this.$store.state.user.current_business.address,
            logo: "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/app_name.png",
          },
        });
      });
    },

  },
  mounted() {
    this.getPlans();
    this.discountP = 0;

  }
})
</script>


<style>

.cancel {
  text-decoration: line-through;
}
</style>