<template>
  <v-card>
    <v-card-title class="font-weight-light">
      VAT Calculator <v-spacer></v-spacer>
      <v-btn small color="red lighten-5 red--text"
             v-if="showClose"
       rounded
       depressed
       @click="$emit('closed')"
      >Close <v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>

    <v-card-text class="text-dark pt-2">
      <v-row dense>

      <v-col
          class="d-flex"
          cols="12"
      >
        <v-select
            v-model="tax"
            :items="taxes"
            item-text="display_name"
            item-value="id"
            return-object
            label="Select tax"
            color="orange"
            item-color="orange"
            outlined
        >
          <template v-slot:item="{item}">
            {{item.display_name}}({{item.type}})
          </template>
        </v-select>

      </v-col>

      <v-col
          class="d-flex"
          cols="12"
      >
        <v-select
            v-if="tax"
            v-model="type"
            :items="types"
            label="Select conversion process"
            outlined
            color="orange"
            item-color="orange"

        ></v-select>

      </v-col>

      <v-col class="d-flex" cols="12" v-if="type === 'AtoA'">
        <v-text-field
            color="orange"
            v-model="amount_without_tax"
            label="Amount without sales taxes"
            outlined
            type="number"
        >
        </v-text-field>
      </v-col>

      <v-col class="d-flex" cols="12" v-if="type === 'AtoS'">
        <v-text-field
            color="orange"
            v-model="amount_with_tax"
            label="Amount with sales taxes"
            outlined
            type="number"
        >
        </v-text-field>
      </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="text-center">
          <div v-if="type === 'AtoA' && amount_without_tax">
            <h3 class="font-weight-light">Tax amount:{{ (CaculatedInclusiveTax-amount_without_tax)  | toMoney | currency_symbol }}</h3>
            <h3 class="font-weight-light">Amount with sales tax:{{ CaculatedInclusiveTax | toMoney | currency_symbol }}<copy-bottun :content="CaculatedInclusiveTax.toFixed(2)"></copy-bottun></h3>
          </div>
          <div v-if="type === 'AtoS' && amount_with_tax">
            <p>Original Amount<p>
            <h3 class="font-weight-light">{{ calculateTaxExclusive | toMoney | currency_symbol }}<copy-bottun :content="calculateTaxExclusive.toFixed(2)"></copy-bottun></h3>
          </div>
        </v-col>
      </v-row>

    </v-card-text>

  </v-card>

</template>

<script>
import CopyBottun from "./copyBottun";
export default {
  name: "VatCalculator",
  components: {CopyBottun},
  props:{
    showClose:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      tax:null,
      type:"",
      converted_amount: '',
      vat_dialog: false,
      gross_dialog: false,
      amount_with_tax: 0,
      amount_without_tax: 0,
      types:[
        {
          text:"Original Amount to Amount with sales taxes",
          value:"AtoA"
        },
        {
          text:"Amount with sales taxes to Original Amount",
          value:"AtoS"
        },

      ]
    }
  },
  computed:{
    taxes(){
      return this.$store.state.user.current_business.taxes;
    },
    CaculatedInclusiveTax() {
      if (this.amount_without_tax && this.tax.type.toLowerCase() === 'compound') {

        const subTax = (Number(this.tax.sub_rate)/100)*Number(this.amount_without_tax);
        const compoundAmount = subTax+Number(this.amount_without_tax);

        const compoundtax = (Number(this.tax.rate)/100)*compoundAmount;
        const totalTax = compoundtax+subTax;
        return totalTax+Number(this.amount_without_tax);

      } else if (this.amount_without_tax && this.tax.type.toLowerCase() === 'flat') {


        return ((Number(this.tax.rate)/100)*Number(this.amount_without_tax))+Number(this.amount_without_tax);


      }

    },
    calculateTaxExclusive(){

      if (this.amount_with_tax && this.tax.type.toLowerCase() === 'compound') {

        const amount=1;
        const subTax = (Number(this.tax.sub_rate)/100)*Number(amount);
        const compoundAmount = subTax+Number(amount);
        const compoundtax = (Number(this.tax.rate)/100)*compoundAmount;
        const totalTax = compoundtax+subTax+amount;

        return  Number(this.amount_with_tax) / totalTax;

      } else if (this.amount_with_tax && this.tax.type.toLowerCase() === 'flat') {

        const amount=1;
        const totalTax = (Number(this.tax.rate)/100)*Number(amount)+amount;

        return  Number(this.amount_with_tax) / totalTax;

      }

    }

  }
}
</script>

<style scoped>

</style>