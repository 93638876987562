<template>
  <span>

    <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>
    <span v-else>
    <center>

    <v-img contain :src="item.photo" width="80" class="rounded-lg"></v-img>
    <h5 class="mt-3">Inventory adjustment for {{ item.name }} from production</h5>
    </center>

      <v-alert dense type="info" color="orange" prominent text>
        <h5>Raw materials are items that track inventory</h5>
      </v-alert>

      <v-form ref="prodForm">


        <v-menu
            v-model="showAddDialog"
            transition="slide-y-transition"
            max-width="500"
            min-width="400"
            :close-on-content-click="false"
            bottom
            max-height="500"
        >

          <template v-slot:activator="{on}">

        <v-btn
            :disabled="saving"
            :loading="loading_materials"
            outlined color="orange" v-on="on" x-large block rounded>Select Raw Materials <v-icon>mdi-chevron-down</v-icon></v-btn>

          </template>
          <v-card>
            <v-card-actions>
              <v-text-field color="orange" v-model="searchKey" append-icon="search" rounded filled flat clearable
                            placeholder="Search"></v-text-field>
            </v-card-actions>
            <v-card-text class="overflow-auto">

              <span v-if="rawMaterials.length">

            <v-list-item
                two-line
                v-for="item in rawMaterials"
                :key="item.id"
                @click="addMaterial(item)"
            >
              <v-list-item-avatar rounded class="rounded-lg">
                <v-img contain :src="item.photo"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>Unit Cost: {{
                    item.real_unitPrice | toMoney | currency_symbol
                  }}</v-list-item-subtitle>
                <v-list-item-subtitle>Quantity: {{ item.quantity }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>

              </span>


            </v-card-text>

          </v-card>

        </v-menu>

        <span v-if="selectedRawMaterials.length">
          <h3 class="mt-3 font-weight-black">Selected raw materials</h3>

          <v-list>
            <v-list-item
                :disabled="saving"
                dense
                class="border rounded-lg"
                v-for="(item,i) in selectedRawMaterials"
                :key="item.id">
              <v-list-item-content>
                <v-list-item-title class="font-weight-black">{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>Current QTY:{{
                    item.quantity
                  }} | Unit Cost:{{ item.unit_cost | toMoney | currency_symbol }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-text-field
                      :rules="[$store.state.validationRules.required]"
                      class="mt-3"
                      label="QTY*"
                      outlined
                      :disabled="saving"
                      dense
                      style="width: 120px;"
                      color="orange"
                      type="number"
                      min="0"
                      v-model="item.usedQuantity"></v-text-field>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>
               Cost: {{ (item.usedQuantity * item.unit_cost) | toMoney }}
              </v-list-item-action-text>
                <v-list-item-action>
                <v-btn @click="removeItem(i)" icon x-small color="red"><v-icon>mdi-delete-outline</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
      <h2 class="ma-2">Total Cost:{{ totalCost | toMoney | currency_symbol }}</h2>
      <p class="ma-2">Current Quantity:{{ item.quantity}}</p>
      <h4 class="ma-2">New Quantity:{{ Number(item.quantity) + Number(adjustment_quantity) }}</h4>

          <v-text-field
              :rules="[$store.state.validationRules.required]"
              v-model="adjustment_quantity"
              type="number"
              :disabled="saving"
              min="0"
              label="Quantity produced*"
              color="orange"
              outlined></v-text-field>


             <v-menu
                 v-model="Date_menu"
                 :close-on-content-click="false"
                 transition="scale-transition"
                 offset-y
                 min-width="290px"
             >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="date"
                          label="Date of production*"
                          readonly
                          outlined
                          color="orange"
                          v-on="on"
                          :disabled="saving"
                          :rules="[$store.state.validationRules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @input="Date_menu =false"
                        no-title
                        color="orange"

                    ></v-date-picker>
                  </v-menu>

          <v-textarea color="orange" label="Note" v-model="note" outlined auto-grow rows="2"></v-textarea>


          <V-btn :loading="saving" @click="saveAdjustment" block rounded depressed x-large dark
                 color="orange">Save Adjustment</V-btn>

        </span>

      </v-form>

    </span>


  </span>
</template>

<script>
import {defineComponent} from 'vue'
import moment from "moment";

export default defineComponent({
  emits:["done"],
  name: "ProductionAdjustment",
  props: {
    item: {
      type: Object
    }
  },
  computed: {

    totalCost() {

      let total = 0;

      this.selectedRawMaterials.forEach(item => total += (item.unit_cost * item.usedQuantity));

      return total;

    },
    rawMaterials() {

      const list = this.items.filter(item => {

        return (item.id !== this.item.id) && item.inventory_accountID && item.type.toLowerCase() == "product";

      });


      const FilteredList = list.filter(item => {
        return !this.selectedRawMaterials.map(selected => selected.id).includes(item.id);
      })


      if (this.searchKey) {

        return FilteredList.filter(item => item.name.toLowerCase().includes(this.searchKey.toLowerCase()));
      }

      return FilteredList;

    }
  },
  data() {
    return {
      note: "",
      items: [],
      selectedRawMaterials: [],
      loading: false,
      showAddDialog: false,
      searchKey: "",
      quantity: 0,
      Date_menu: false,
      date: moment().format('Y-M-D'),
      saving: false,
      adjustment_quantity: 0,
      loading_materials: false

    }
  },
  methods: {

    getExistingRawMaterials() {
      const URL = "/api/item/" + this.item.id + "/raw-materials";

      axios.get(URL)
          .then(res => {
            const list = res.data;

            if (list.length > 0) {

              list.forEach(item => {
                this.addMaterial(item);

              })

              this.loading_materials = false;

            }
          })

    },

    saveAdjustment() {

      if (this.$refs.prodForm.validate()) {
        this.saving = true;

        const data = {
          adjustment_quantity: this.adjustment_quantity,
          materials: this.selectedRawMaterials,
          product_id: this.item.id,
          date: this.date,
          note: this.note
        };
        axios.post("/api/item/adjust/from-production", data)
            .then(res => {

              this.saving = false;

              this.$emit("done", res.data);

            })
            .catch(error => {

              this.saving = false;

            })


      }


    },

    removeItem(index) {
      this.selectedRawMaterials.splice(index, 1);

    },
    addMaterial(item) {

      this.selectedRawMaterials.push({
        id: item.id,
        name: item.name,
        unit_cost: item.real_unitPrice,
        inventory_accountID: item.inventory_accountID,
        quantity: item.quantity,
        usedQuantity: 1
      });

      this.showAddDialog = false;


    },

    getRawMeterials() {

      this.loading = true;

      axios.get("/api/billitems")
          .then(res => {
            this.items = res.data;
            this.loading = false;
          })

    }
  },
  mounted() {
    this.getRawMeterials();
    this.getExistingRawMaterials();
  }
})
</script>

<style scoped>

</style>