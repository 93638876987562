<template>
<span>
  <v-row>
    <v-col cols="12" sm="3">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-col>
      <v-col cols="12" sm="3">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-col>
      <v-col cols="12" sm="3">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-col>

    <v-col cols="12" sm="3">

    <v-row dense>
      <v-col cols="12">
      <v-skeleton-loader height="100" type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
      <v-skeleton-loader height="100" type="card"></v-skeleton-loader>
      </v-col>
    </v-row>

     </v-col>

  </v-row>
  <v-row>

    <v-col cols="12" sm="6">
      <v-skeleton-loader type="card" ></v-skeleton-loader>
    </v-col>
     <v-col cols="12" sm="6">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-col>


  </v-row>

</span>
</template>

<script>
export default {
  name: "cashRegisterSkeleton"
}
</script>

<style scoped>

</style>