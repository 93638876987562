<template>
<span>
  <v-row dense>
    <v-col cols="12" sm="12">
      <v-card flat>
        <v-card-title>
          Sales Orders <v-spacer></v-spacer> <v-btn to="/orders/new" x-large color="orange" rounded depressed dark>New Order</v-btn>
        </v-card-title>
        <v-card-text>

          <v-tabs color="orange">
            <v-tab to="/orders">
              Summaries
            </v-tab>
            <v-tab to="/orders/list">
              Orders
            </v-tab>

          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <router-view></router-view>
    </v-col>
  </v-row>
</span>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Orders"
})
</script>


<style scoped>

</style>