<template>
  <div>
    <div v-if="$store.state.user.is_admin">
      <v-row>
        <v-col cols="12" sm="12">
          <v-simple-table fixed-header height="90vh">
            <thead>
              <tr>
                <th v-for="head in headers">
                  {{ head.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bill in bills" :key="bill.id">
                <td>
                  <span v-if="bill.active">
                    <v-icon small class="green--text"
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    Active
                  </span>

                  <span v-else>
                    <v-icon small class="text-muted" l
                      >mdi-checkbox-blank-circle</v-icon
                    >
                    Completed
                  </span>
                </td>
                <td>
                  {{ bill.amount | toMoney }}
                </td>
                <td>
                  <strong> {{ bill.planname }}</strong>
                  <p class="text-small" v-if="bill.add_ons">
                    <strong>Add-ons </strong>
                    (
                    <span
                      v-if="
                        bill.add_ons.employee_extension &&
                        Number(bill.add_ons.employee_extension.amount > 0)
                      "
                    >
                      Extra Employees:
                      {{ bill.add_ons.employee_extension.extraNumber }}, current
                      limit:{{ bill.add_ons.employee_extension.totalNumber }}
                    </span>
                    <span
                      v-if="
                        bill.add_ons.onsite_visits &&
                        Number(bill.add_ons.onsite_visits.amount > 0)
                      "
                    >
                      | Hours: {{ bill.add_ons.onsite_visits.hours }},
                      {{ bill.add_ons.onsite_visits.visits_per_week }} visits
                      per week,
                      {{ bill.add_ons.onsite_visits.visits_per_month }} per
                      month |
                    </span>

                    )
                  </p>
                </td>
                <td>
                  {{ bill.start_date | humanDate }}
                </td>
                <td>
                  {{ bill.end_date | humanDate }}
                </td>
                <td>
                  <v-btn
                    @click="
                      cancel_dialog = true;
                      id = bill.id;
                    "
                    color="red"
                    small
                    :disabled="!bill.active"
                    text
                    >Cancel</v-btn
                  >
                  <a
                      class="orange--text"
                    :href="
                      $store.state.baseUrl +
                      '/' +
                      'subscriptioninvoice/' +
                      bill.id
                    "
                    >Download <v-icon color="orange">mdi-download</v-icon></a
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-dialog width="500" v-model="cancel_dialog">
        <v-card>
          <v-card-title>
            Why do you want to cancel?
          </v-card-title>
          <v-card-text >
            <h4 class="red--text pa-2">
              Are you sure you want cancel this subscription? you will NOT
              receive any credits or refund afterwards
            </h4>


            <h5>Select Reason</h5>
            <v-autocomplete
              outlined
              color="orange"
              item-color="orange"
              :items="reasons"
              v-model="reason"
              v-if="!other"
            ></v-autocomplete>
            <v-switch
              v-model="other"
              color="orange"
              inset
              label="Other Reasons, please specify"
            ></v-switch
            ><br />
            <v-textarea  color="orange" outlined v-model="reason" v-if="other"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancel_dialog = false" text>Close</v-btn>
            <v-btn
              :loading="progress"
              @click="cancel_subscription"
              text
              color="red"
              >Yes, cancel Subscription
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-alert v-else class="text-center bg-danger-gradient" type="error">
      Your user role is not allowed to view subscription billing information
    </v-alert>
    <v-snackbar v-model="error_message" color="red" :timeout="-1">
      {{ error_msg }}
      <v-btn text @click="get_bills" dark>Retry</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "subscriptionsComponent",
  data() {
    return {
      other: false,
      id: null,
      reason: "",
      cancel_dialog: false,
      success_message: false,
      success_msg: "",
      error_msg: "",
      error_message: false,
      progress: false,
      subscrptions: [],
      reasons: [
        {
          value: "Don't provide the functionality i need ",
          text: "Don't provide the functionality i need ",
        },
        {
          value: "Was too expensive",
          text: "Was too expensive",
        },
        {
          value: "Inadequate support",
          text: "Inadequate support",
        },
        {
          value: "Shutdown business",
          text: "Shutdown business",
        },
        {
          value: "Ended by mistake",
          text: "Ended by mistake",
        },
      ],
      headers: [
        {
          text: "Status",
          value: "active",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Plan",
          value: "planname",
        },
        {
          text: "Start Date",
          value: "start_date",
        },
        {
          text: "End Date",
          value: "end_date",
        },

        { text: "Actions", value: "id", align: "center", sortable: false },
      ],
      bills: [],
    };
  },
  methods: {
    cancel_subscription() {
      this.progress = true;
      const formData = new FormData();
      formData.append("bill_id", this.id);
      formData.append("reason", this.reason);
      axios
        .post("/api/cancelsubscrption", formData)
        .then((res) => {
          this.progress = false;
          this.$router.push({
            path: "/plans",
          });
          location.reload();
        })
        .catch((error) => {});
    },

    get_bills() {
      this.progress = true;
      this.error_message = false;

      axios
        .get("/api/appbills")
        .then((res) => {
          this.bills = res.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          // this.error_msg =
          //   "Something went wrong, could not get billing information";
          // this.error_message = true;
        });
    },
  },
  mounted() {
    this.get_bills();
  },
};
</script>

<style scoped></style>
