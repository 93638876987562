<template>
  <v-container class="home" fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card-title>
          <h2 class="font-weight-light ma-3" style="font-size: 2rem">
            {{ filterText }}
          </h2>
          <v-spacer></v-spacer>
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-btn
                color="orange"
                dark
                depressed
                rounded
                x-large
                v-on="on"
                class="text-capitalize"
                >New Transaction
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item to="/cart">
                <v-list-item-icon class="mr-0">
                  <v-icon>mdi-storefront-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-0">
                  <v-list-item-title> Store Front </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon small color="grey">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item @click="showCharge = true">
                <v-list-item-icon class="mr-0">
                  <v-icon>mdi-contactless-payment</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Charge Customer </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon small color="grey">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="3">
        <cash-register-skeleton
          v-if="loading && !report"
        ></cash-register-skeleton>
        <v-card v-else flat>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-card flat>
                <v-progress-linear
                  color="orange"
                  indeterminate
                  v-if="loading"
                ></v-progress-linear>

                <v-card-title class="border-bottom">
                  <v-icon color="orange" large>mdi-printer-pos-outline</v-icon>
                  <h2 class="font-weight-light">Cash Register</h2>
                </v-card-title>
                <v-card-text class="pt-4" style="height: 600px; overflow: auto">
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-card class="text-center" outlined>
                        <v-card-text class="pa-5">
                          <h2
                            class="font-weight-bold mb-0"
                            style="font-size: 2rem"
                          >
                            {{ currency.code
                            }}{{
                              (report ? report.total_cash_from_sales : 0)
                                | toMoney
                            }}
                          </h2>
                          <small>Total Collections</small>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-card class="text-center" outlined>
                        <v-card-text class="pa-5">
                          <h3
                            class="font-weight-bold mb-0"
                            style="font-size: 1.5rem"
                          >
                            {{ currency.code
                            }}{{
                              (report ? report.cash_sales_collections : 0)
                                | toMoney
                            }}({{ totalSalesCashCollectionPercent }}%)
                          </h3>

                          <small>Total cash Collections</small>
                          <v-progress-linear
                            :value="totalSalesCashCollectionPercent"
                            color="blue"
                            height="20"
                            rounded
                          ></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-card class="text-center" outlined>
                        <v-card-text class="pa-5">
                          <h3
                            class="font-weight-bold mb-0"
                            style="font-size: 1.5rem"
                          >
                            {{ currency.code
                            }}{{
                              (report ? report.non_cash_sales_collections : 0)
                                | toMoney
                            }}({{ totalSalesCashNonCollectionPercent }}%)
                          </h3>

                          <small
                            >Total non-cash collections(Mobile Money)</small
                          >
                          <v-progress-linear
                            v-model="totalSalesCashNonCollectionPercent"
                            color="green"
                            height="20"
                            rounded
                          ></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <strong class="font-weight-black"
                        >Collections by cashiers:</strong
                      >
                      <v-list v-if="report" two-line>
                        <v-list-item
                          v-for="person in report.collection_by_cashiers"
                          v-if="person.amount"
                          :key="person.name"
                          class="border-bottom"
                        >
                          <v-list-item-avatar>
                            <avatar-component
                              :label="person.name"
                            ></avatar-component>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-black"
                              >{{ currency.code }}{{ person.amount | toMoney }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="report"
                              >{{ person.name }}({{
                                (
                                  (person.amount /
                                    report.total_cash_from_sales) *
                                  100
                                ).toFixed(2)
                              }}%)
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-progress-linear
                                v-if="report"
                                :value="
                                  (
                                    (person.amount /
                                      report.total_cash_from_sales) *
                                    100
                                  ).toFixed(2)
                                "
                                color="orange"
                                height="8"
                                rounded
                              ></v-progress-linear>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-row>
          <v-col cols="12" sm="12">
            <v-card class="mt-3" v-if="settings" color="orange" dark flat>
              <v-card-title class="text-center">
                <v-icon x-large>mdi-tune-vertical</v-icon>
                Admin Preferences
              </v-card-title>
              <v-card-text>
                <p class="text-center pa-4 border-bottom">
                  This section shows some restrictions and preferences set by
                  the owner and admin of this business ({{ business.name }}).
                </p>

                <v-list color="transparent">
                  <v-subheader>Presets:</v-subheader>
                  <v-list-item
                    class="mb-2"
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Taxes</v-list-item-title>
                      <v-list-item-subtitle>
                        <ul v-if="taxes.length">
                          <li v-for="tax in taxes" :key="tax.id">
                            {{ tax.name }}({{ tax.rate }}%)
                          </li>
                        </ul>
                        <p v-else>No taxes set by admin</p>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Collection Account</v-list-item-title>

                      <v-list-item-subtitle>
                        <ul v-if="setupAccounts.length">
                          <li
                            v-for="account in setupAccounts"
                            :key="account.id"
                          >
                            {{ account.account_name }}
                          </li>
                        </ul>
                        <p v-else>No collection account set by admin</p>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader>Constraints:</v-subheader>

                  <v-list-item
                    class="mb-2"
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Ability to change unit price:</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-icon
                        v-if="settings.can_change_unit_price"
                        color="white"
                        x-large
                        >mdi-toggle-switch-outline
                      </v-icon>
                      <v-icon v-else color="white" x-large
                        >mdi-toggle-switch-off-outline</v-icon
                      >
                      <strong class="ml-2">{{
                        settings.can_change_unit_price ? "Yes" : "No"
                      }}</strong>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-list-item
                    class="mb-2"
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Ability to change taxes:</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-icon
                        v-if="settings.can_change_taxes"
                        color="white"
                        x-large
                        >mdi-toggle-switch-outline</v-icon
                      >
                      <v-icon v-else color="white" x-large
                        >mdi-toggle-switch-off-outline</v-icon
                      >
                      <strong class="ml-2">{{
                        settings.can_change_taxes ? "Yes" : "No"
                      }}</strong>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-list-item
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Ability to change discounts:</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-icon
                        v-if="settings.can_change_discounts"
                        color="white"
                        x-large
                        >mdi-toggle-switch-outline
                      </v-icon>
                      <v-icon v-else color="white" x-large
                        >mdi-toggle-switch-off-outline</v-icon
                      >
                      <strong class="ml-2">{{
                        settings.can_change_discounts ? "Yes" : "No"
                      }}</strong>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-list-item
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Ability to delete transactions:</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-icon v-if="settings.can_delete" color="white" x-large
                        >mdi-toggle-switch-outline
                      </v-icon>
                      <v-icon v-else color="white" x-large
                        >mdi-toggle-switch-off-outline</v-icon
                      >
                      <strong class="ml-2">{{
                        settings.can_delete ? "Yes" : "No"
                      }}</strong>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-list-item
                    style="border: 1px solid white; border-radius: 15px"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Ability to change date:</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-icon
                        v-if="settings.change_change_date"
                        color="white"
                        x-large
                        >mdi-toggle-switch-outline
                      </v-icon>
                      <v-icon v-else color="white" x-large
                        >mdi-toggle-switch-off-outline</v-icon
                      >
                      <strong class="ml-2">{{
                        settings.change_change_date ? "Yes" : "No"
                      }}</strong>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="9">
        <v-row dense>
          <v-col cols="12" sm="12">
            <v-card flat>
              <v-card-title>
                <v-tabs
                  v-model="tab"
                  color="orange"
                  style="border-radius: 15px"
                >
                  <v-tab
                    key="today"
                    tab-value="today"
                    @click="filterTransactions('today')"
                    >Today</v-tab
                  >
                  <v-tab
                    key="week_1"
                    tab-value="week_1"
                    @click="filterTransactions('week', 1)"
                    >This week</v-tab
                  >
                  <v-tab
                    key="week_2"
                    tab-value="week_2"
                    @click="filterTransactions('week', 2)"
                    >Last two weeks</v-tab
                  >
                  <v-tab
                    key="month_1"
                    tab-value="month_1"
                    @click="filterTransactions('month', 1)"
                    >Last month</v-tab
                  >
                  <v-tab
                    key="month_3"
                    tab-value="month_3"
                    @click="filterTransactions('month', 3)"
                    >Last three months
                  </v-tab>

                  <v-tab key="all" tab-value="all">
                    <v-btn
                      class="white--text text-capitalize"
                      color="orange"
                      dark
                      depressed
                      rounded
                      to="/transactions?filter_type=all"
                      x-large
                      >All
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                  </v-tab>
                </v-tabs>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="border-bottom" dense>
          <v-col cols="12" sm="4">
            <v-card flat>
              <v-progress-linear
                color="orange"
                indeterminate
                v-if="loading"
              ></v-progress-linear>
              <v-card-text class="text-center">
                <p>Transactions</p>
                <div class="d-flex align-center justify-center">
                  <span
                    style="font-size: 2rem"
                    class="orange--text font-weight-bold"
                    >{{ transactionsCount | toMoney }}</span
                  >
                  <span class="orange lighten-5" style="border-radius: 15px"
                    ><v-icon class="ma-2" color="orange"
                      >mdi-cart-outline</v-icon
                    ></span
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card flat>
              <v-progress-linear
                color="orange"
                indeterminate
                v-if="loading"
              ></v-progress-linear>

              <v-card-text class="text-center">
                <p>Total Amount</p>
                <div class="d-flex align-center justify-center">
                  <span
                    style="font-size: 2rem"
                    class="green--text font-weight-bold"
                    >{{ currency.code }}{{ totalAmount | toMoney }}</span
                  >
                  <span class="green lighten-5" style="border-radius: 15px"
                    ><v-icon class="ma-2" color="green"
                      >mdi-wallet-bifold-outline</v-icon
                    ></span
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="4">
            <v-card flat>
              <v-progress-linear
                color="orange"
                indeterminate
                v-if="loading"
              ></v-progress-linear>

              <v-card-text class="text-center">
                <p>Number of items sold</p>
                <div class="d-flex align-center justify-center">
                  <span style="font-size: 2rem" class="font-weight-bold">{{
                    totalQuantity | toMoney
                  }}</span>
                  <span
                    :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'"
                    style="border-radius: 15px"
                  >
                    <v-icon class="ma-2">mdi-application-export</v-icon>
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-card flat min-height="127vh">
              <v-card-title>
                <h4 style="font-size: 2rem" class="font-weight-light pl-2">
                  <v-icon x-large>mdi-history</v-icon>
                  Recent Transactions
                </h4>
              </v-card-title>
              <v-card-text>
                <v-list v-if="items" rounded>
                  <template v-if="$vuetify.breakpoint.mobile">
                    <v-list-item
                      v-for="item in items.data"
                      :key="item.id"
                      color="orange"
                      style="border-radius: 15px"
                      two-line
                      @click="
                        showPreview = true;
                        showItem(item.id);
                      "
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-h6 font-weight-light"
                          >{{ item.sales_number }}
                          <v-icon v-if="item.pos_sale" color="orange" x-small
                            >mdi-contactless-payment</v-icon
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          formatDate(item.entry_date)
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action-text>
                        <v-chip color="orange lighten-5 orange--text"
                          >{{ currency.code }}{{ item.amount_due | toMoney }}
                        </v-chip>
                      </v-list-item-action-text>
                      <v-list-item-icon>
                        <v-icon color="grey" small>mdi-chevron-right</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-list-item
                      v-for="item in items.data"
                      :key="item.id"
                      :to="'/transactions/' + item.id"
                      color="orange"
                      style="border-radius: 15px"
                      two-line
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-h6 font-weight-light">{{
                          item.sales_number
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          formatDate(item.entry_date)
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action-text>
                        <v-chip color="orange lighten-5 orange--text"
                          >{{ currency.code }}{{ item.amount_due | toMoney }}
                        </v-chip>
                      </v-list-item-action-text>
                      <v-list-item-icon>
                        <v-icon class="mt-4" color="grey" small
                          >mdi-chevron-right</v-icon
                        >
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  block
                  color="orange"
                  text
                  to="/transactions?filter_type=all"
                  x-large
                  >View All</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="scanItem"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
      scrollable
      transition="dialog-bottom-transition"
      width="500px"
    >
      <item-scan-component @closed="scanItem = false"></item-scan-component>
    </v-dialog>

    <v-dialog
      v-model="showCharge"
      :fullscreen="$vuetify.breakpoint.mobile"
      scrollable
      transition="dialog-bottom-transition"
      width="500px"
    >
      <charge-component @close="showCharge = false"></charge-component>
    </v-dialog>

    <v-dialog
      v-model="showPreview"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="red" icon x-large @click="showPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <transaction-preview
          @changed="
            filterTransactions(filterType, filterUnit);
            showPreview = false;
          "
        ></transaction-preview>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ChargeComponent from "../components/ChargeComponent";
import ItemScanComponent from "../components/itemScanComponent";
import moment from "moment";
import AvatarComponent from "../components/avatarComponent";
import CashRegisterSkeleton from "../components/cashRegisterSkeleton";
import TransactionPreview from "../components/TransactionPreview";

export default {
  name: "Home",
  components: {
    TransactionPreview,
    CashRegisterSkeleton,
    AvatarComponent,
    ItemScanComponent,
    ChargeComponent,
  },
  computed: {
    setupAccounts() {
      return this.accounts.filter(
        (account) => account.id == this.SetAccount_id
      );
    },
    SetAccount_id() {
      return this.$store.state.account_id;
    },
    setUpTaxes() {
      return this.$store.state.taxes;
    },
    taxes() {
      return this.$store.state.user.current_business.taxes.filter((tax) =>
        this.setUpTaxes.includes(tax.id.toString())
      );
    },
    settings() {
      return this.$store.state.settings;
    },

    totalSalesCashNonCollectionPercent() {
      let p = 0;
      if (this.report && this.report.total_cash_from_sales > 0) {
        p = (
          (this.report.non_cash_sales_collections /
            this.report.total_cash_from_sales) *
          100
        ).toFixed(2);

        return p;
      }

      return p;
    },

    totalSalesCashCollectionPercent() {
      let p = 0;
      if (this.report && this.report.total_cash_from_sales > 0) {
        p = (
          (this.report.cash_sales_collections /
            this.report.total_cash_from_sales) *
          100
        ).toFixed(2);

        return p;
      }

      return p;
    },
    business() {
      return this.$store.state.user.current_business;
    },
    chargeCardDescription() {
      return this.business.country.code.toLowerCase() === "gh"
        ? "Charge your customers directly via mobile money"
        : "Charge your customers directly via Mpesa";
    },

    currency() {
      return this.business.currency;
    },
  },
  data() {
    return {
      showPreview: false,
      showCharge: false,
      scanItem: false,
      tab: "today",
      transactionsCount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      filterText: "Today",
      loading: false,
      report: null,
      accounts: [],
      items: {
        data: [],
      },
    };
  },
  methods: {
    showItem(itemID) {
      this.$router.replace({
        path: this.$route.path,
        query: { item_id: itemID },
      });
    },
    formatDate(date) {
      return moment(date).format("MMM Do, YYYY");
    },

    getInterval(unit = null, value = null) {
      if (!unit) {
        return null;
      }

      if (unit === "today") {
        const today = moment();

        this.filterText = "Today-" + today.format("MMM Do, YYYY");

        this.from = today.format("Y-M-D");
        this.to = today.format("Y-M-D");

        return {
          from: this.from,
          to: this.to,
        };
      }

      if (unit === "week") {
        const today = moment();

        const lastDate = moment().subtract(unit + "s", value);
        const unitName = value > 1 ? unit + "s" : unit;

        if (unit == 1) {
          this.filterText =
            "This week, " +
            lastDate.format("MMM Do, YYYY") +
            " - " +
            today.format("MMM Do, YYYY");
        } else {
          this.filterText =
            "Last  " +
            value +
            " " +
            unitName +
            ", " +
            lastDate.format("MMM Do, YYYY") +
            " - " +
            today.format("MMM Do, YYYY");
        }

        this.from = lastDate.format("Y-M-D");
        this.to = today.format("Y-M-D");

        return {
          from: this.from,
          to: this.to,
        };
      } else {
        const today = moment();
        const lastDate = moment().subtract(unit + "s", value);
        const unitName = value > 1 ? unit + "s" : unit;

        this.filterText =
          "Last " +
          value +
          " " +
          unitName +
          ", " +
          lastDate.format("MMM Do, YYYY") +
          "to" +
          today.format("MMM Do, YYYY");

        this.from = lastDate.format("Y-M-D");
        this.to = today.format("Y-M-D");

        return {
          from: this.from,
          to: this.to,
        };
      }
    },
    getAccounts() {
      window.axios.get("/api/pos/bank-accounts").then((res) => {
        this.accounts = res.data;
      });
    },
    filterTransactions(unit = null, value = null) {
      this.loading = true;
      this.filterType = unit;
      this.filterUnit = value;

      const tabValue = unit;

      if (value) {
        this.tab = tabValue + "_" + value;
      } else {
        this.tab = tabValue;
      }

      if (
        this.$route.query.filter_type !== unit ||
        this.$route.query.filter_value !== value
      ) {
        this.$router.replace({
          path: this.$route.path,
          query: { filter_type: unit, filter_value: value },
        });
      }

      const range = this.getInterval(unit, value);
      const URL = "/api/pos/dashboard?from=" + range.from + "&to=" + range.to;

      window.axios.get(URL).then((res) => {
        this.filterOn = true;

        this.items.data = res.data.transactions;

        this.totalAmount = res.data.total_amount;
        this.transactionsCount = res.data.record_count;
        this.totalQuantity = res.data.items_sold;

        this.report = res.data.cash_register;

        this.loading = false;
      });
    },
    chargeCustomer() {
      this.showCharge = true;
    },
    changeTheme() {
      if (this.$vuetify.theme.isDark) {
        this.$vuetify.theme.isDark = false;
        localStorage.setItem("theme", "light");
      } else {
        this.$vuetify.theme.isDark = true;
        localStorage.setItem("theme", "dark");
      }
    },
  },
  mounted() {
    if (this.$route.query.filter_type == "all") {
      this.getItems();
    } else {
      const defaultFilterType = this.$route.query.filter_type
        ? this.$route.query.filter_type
        : "today";
      const defaultUnit = this.$route.query.filter_value
        ? this.$route.query.filter_value
        : null;

      this.filterTransactions(defaultFilterType, defaultUnit);
    }
    this.getAccounts();
  },
};
</script>
