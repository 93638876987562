<template>
  <v-container>
    <v-card-title>
      <h2 class="pa-3 font-weight-light" style="font-size: 2rem">
        <v-btn v-if="$vuetify.breakpoint.mobile" icon x-large @click="$store.state.minNav = true">
          <v-icon x-large>mdi-menu</v-icon>
        </v-btn>
        {{ filterText }}
      </h2>
      <v-spacer></v-spacer>

      <v-btn class="mr-3" color="orange" depressed outlined rounded x-large @click="
        showFindSalenDialog = true;
      query = '';
      ">Find Receipt
        <v-icon x-large>mdi-barcode-scan</v-icon>
      </v-btn>
    </v-card-title>

    <v-card flat>
      <v-card-title>
        <v-tabs v-model="tab" color="orange">
          <v-tab key="today" tab-value="today" @click="filterTransactions('today')">Today</v-tab>
          <v-tab key="week_1" tab-value="week_1" @click="filterTransactions('week', 1)">This week</v-tab>
          <v-tab key="week_2" tab-value="week_2" @click="filterTransactions('week', 2)">Last two weeks</v-tab>
          <v-tab key="month_1" tab-value="month_1" @click="filterTransactions('month', 1)">Last month</v-tab>
          <v-tab key="month_3" tab-value="month_3" @click="filterTransactions('month', 3)">Last three months</v-tab>
          <v-tab key="month_6" tab-value="month_6" @click="filterTransactions('month', 6)">Last six months</v-tab>
          <v-tab key="year_1" tab-value="year_1" @click="filterTransactions('year', 1)">Last year</v-tab>
          <v-tab key="all" tab-value="all" @click="
            getItems();
          filterText = 'All time';
          filterOn = false;
          ">All
          </v-tab>
          <v-tab>
            <v-menu :close-on-content-click="false" min-width="400px" transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-btn color="default" depressed rounded v-on="on" class="text-capitalize">Custom
                  <v-icon>mdi-filter-outline</v-icon>
                </v-btn>
              </template>
              <v-card flat>
                <v-card-title>
                  <h4 class="font-weight-light">Custom filter</h4>
                </v-card-title>
                <v-card-text>
                  <v-menu ref="menu1" v-model="dateMenu1" :close-on-content-click="false" max-width="290px"
                          min-width="auto" offset-y transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="orange" v-model="from" label="From" outlined persistent-hint v-bind="attrs"
                                    v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="orange" v-model="from" no-title @input="dateMenu1 = false"></v-date-picker>
                  </v-menu>

                  <v-menu v-model="dateMenu2" :close-on-content-click="false" max-width="290px" min-width="auto"
                          offset-y transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field color="orange" v-model="to" label="To" outlined persistent-hint v-bind="attrs"
                                    v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="orange" v-model="to" no-title @input="dateMenu2 = false"></v-date-picker>
                  </v-menu>
                </v-card-text>
                <v-card-actions>
                  <v-btn block color="orange" dark depressed rounded x-large @click="customFilter">Apply Filter</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-tab>
        </v-tabs>
      </v-card-title>
    </v-card>

    <v-row class="border-bottom" dense>
      <v-col cols="12" sm="4">
        <v-card class="ma-2" flat>
          <v-card-text class="text-center">
            <p>Transactions</p>
            <div class="d-flex align-center justify-center">
              <span class="orange--text font-weight-bold" style="font-size: 2rem">{{
                  transactionsCount | toMoney
                }}</span>
              <span class="orange lighten-5" style="border-radius: 15px">
                <v-icon
                    class="ma-2"
                    color="orange">mdi-cart-outline</v-icon></span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card class="ma-2" flat>
          <v-card-text class="text-center">
            <p>Total Amount</p>
            <div class="d-flex align-center justify-center">
              <span style="font-size: 2rem" class="green--text font-weight-bold">{{ currency.code }}{{
                  totalAmount |
                      toMoney
                }}</span>
              <span class="green lighten-5" style="border-radius: 15px"><v-icon class="ma-2"
                                                                                color="green">mdi-wallet-bifold-outline</v-icon></span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card class="ma-2" flat>
          <v-card-text class="text-center">
            <p>Number of items sold</p>
            <div class="d-flex align-center justify-center">
              <span style="font-size: 2rem" class="font-weight-bold">{{
                  totalQuantity | toMoney
                }}</span>
              <span :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'" class="font-weight-bold"
                    style="border-radius: 15px">
                <v-icon class="ma-2">mdi-application-export</v-icon>
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row dense>
      <v-col cols="12" sm="4">
        <v-card class="mt-5" flat style="height: 90vh">
          <v-card-title>
            <v-text-field v-model="query" clearable color="orange" filled flat hint="Press enter to search"
                          placeholder="Search by sale ID ..." rounded @keydown.enter="searchSale"
                          @click:clear="filterTransactions('today')">
              <template v-slot:append>
                <v-btn :dark="Boolean(query)" :disabled="!query" :loading="searching" class="ma-0 mb-4 text-capitalize"
                       color="orange" depressed rounded @click="searchSale">Search
                </v-btn>
              </template>
            </v-text-field>
          </v-card-title>
          <v-card-text class="overflow-auto" style="max-height: 70vh">
            <v-skeleton-loader v-if="loading" type="list-item@20"></v-skeleton-loader>

            <v-list v-else-if="items" rounded>
              <template v-if="$vuetify.breakpoint.mobile">
                <v-list-item v-for="item in items.data" :key="item.id" :to="'/transactions/' + item.id" color="orange"
                             style="border-radius: 15px" two-line @click="showPreview = true">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-light">{{ item.sales_number }}
                      <v-icon v-if="item.pos_sale" color="orange" x-small>mdi-contactless-payment</v-icon>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                        formatDate(item.entry_date)
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <v-chip color="orange lighten-5 orange--text">{{ currency.code }}{{ item.amount_due | toMoney }}
                    </v-chip>
                  </v-list-item-action-text>
                  <v-list-item-icon>
                    <v-icon color="grey" small>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item
                    v-for="item in items.data"
                    :key="item.id"
                    :to="'/transactions/' + item.id"
                    color="orange"
                    style="border-radius: 15px" two-line>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-light" style="font-size: 1.5rem">{{ item.sales_number }}
                      <v-icon v-if="item.pos_sale" color="orange" x-small>mdi-contactless-payment</v-icon>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                        formatDate(item.entry_date)
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <v-chip color="orange lighten-5 orange--text">{{ currency.code }}{{ item.amount_due | toMoney }}
                    </v-chip>
                  </v-list-item-action-text>
                  <v-list-item-icon>
                    <v-icon class="mt-4" color="grey" small>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>

            <div v-if="items && !items.data.length" class="text-center w-100">
              <h3 class="text-center">No transactions found</h3>

              We could not find any transactions in the selected date range

              <v-btn block color="orange" text to="/cart">Create new transaction</v-btn>
            </div>
          </v-card-text>
          <v-card-actions v-if="!filterOn">
            <v-autocomplete v-model="page" :items="pages" color="orange" dense filled flat item-color="orange"
                            label="Page" rounded @change="getItems">
            </v-autocomplete>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" sm="8">
        <transaction-preview @changed="filterTransactions(filterType, filterUnit)"></transaction-preview>
      </v-col>
    </v-row>

    <v-dialog v-model="showFindSalenDialog" :fullscreen="$vuetify.breakpoint.mobile" eager persistent scrollable
              width="500">
      <v-card>
        <v-card-title>Find Receipt
          <v-spacer></v-spacer>
          <v-btn color="grey" icon @click="showFindSalenDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <template v-if="searching">
            <div v-if="!item" class="text-center">
              <center>
                <v-progress-circular class="d-block" color="orange" indeterminate size="70"></v-progress-circular>
                Finding the receipt please, wait...
              </center>
            </div>

            <div v-else class="text-center">
              <lottie-animation :animationData="require('@/assets/success.json')" :autoPlay="true" :loop="true"
                                style="height: 200px"></lottie-animation>
              <h2 class="green--text font-weight-light">
                Great we have found the receipt
              </h2>
            </div>
          </template>
          <span v-show="!searching">
            <v-text-field id="saleCodeInput" ref="saleCodeInput" v-model="query"
                          :color="$vuetify.theme.isDark ? 'black' : 'white'" autofocus flat @blur="makeInputFocus()"
                          @keydown.enter="searchSale"></v-text-field>

            <lottie-animation :animationData="require('@/assets/barcode_animations.json')" :autoPlay="true" :loop="true"
                              style="height: 200px"></lottie-animation>

            <h2 class="font-weight-light orange--text" style="font-size: 2rem">
              Let's find the receipt you are looking for!
            </h2>
            <p class="text--disabled mt-3">
              To find the sales receipt you are looking for, scan the barcode on
              the receipt using the connected barcode scanner
            </p>
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showPreview" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="red" icon x-large @click="showPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <transaction-preview @changed="
          filterTransactions(filterType, filterUnit);
        showPreview = false;
        "></transaction-preview>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showError" color="error" dark>{{
        error_message
      }}
    </v-snackbar>
  </v-container>
</template>

<script>
import TransactionPreview from "../components/TransactionPreview";
import LottieAnimation from "lottie-web-vue";

import moment from "moment";

export default {
  name: "Transactions",
  components: {TransactionPreview, LottieAnimation},
  data() {
    return {
      item: null,
      showFindSalenDialog: false,
      tab: "today",
      showPreview: false,
      query: "",
      loading: false,
      findingSale: false,
      items: {
        data: [],
      },
      page: 1,
      filterText: "",
      transactionsCount: 0,
      totalAmount: 0,
      totalQuantity: 0,
      filterOn: true,
      searching: false,
      from: "",
      code: "",
      to: "",
      dateMenu1: false,
      dateMenu2: false,
      filterType: "today",
      error_message: "",
      showError: false,
      filterUnit: null,
    };
  },
  computed: {
    pages() {
      if (!this.items) {
        return [
          {
            value: 1,
            text: "Page 1",
          },
        ];
      }

      let list = [];

      for (let i = 1; i <= this.items.last_page; i++) {
        list.push({
          value: i,
          text: "Page " + i,
        });
      }

      return list;
    },
    business() {
      return this.$store.state.user.current_business;
    },
    currency() {
      return this.business.currency;
    },
  },
  methods: {
    findSale() {
    },
    makeInputFocus() {
      if (this.$refs.codeInput) {
        this.$refs.codeInput.focus();
      }
    },

    formatDate(date) {
      return moment(date).format("MMM Do, YYYY");
    },
    searchSale() {
      this.searching = true;
      this.filterOn = true;
      const URL = "/api/sales/search?query=" + this.query;

      window.axios.get(URL).then((res) => {
        this.items.data = res.data;
        this.searching = false;

        if (this.showFindSalenDialog) {
          this.query = "";

          if (res.data.length) {
            this.$router.push({path: "/transactions/" + res.data[0].id});
            this.showFindSalenDialog = false;
          } else {
            this.error_message = "Transaction not found";

            this.showError = true;
          }
        }
      });
    },

    getInterval(unit = null, value = null) {
      if (!unit) {
        return null;
      }
      const unitName = value > 1 ? unit + "s" : unit;

      if (unit === "today") {
        const today = moment();

        this.filterText = "Today-" + today.format("MMM Do, YYYY");

        this.from = today.format("Y-M-D");
        this.to = today.format("Y-M-D");

        return {
          from: this.from,
          to: this.to,
        };
      }

      if (unit === "week") {
        const today = moment();

        const lastDate = moment().subtract(unit + "s", value);

        if (unit == 1) {
          this.filterText =
              "This week, " +
              lastDate.format("MMM Do, YYYY") +
              " - " +
              today.format("MMM Do, YYYY");
        } else {
          this.filterText =
              "Last  " +
              value +
              " " +
              unitName +
              ", " +
              lastDate.format("MMM Do, YYYY") +
              " - " +
              today.format("MMM Do, YYYY");
        }

        this.from = lastDate.format("Y-M-D");
        this.to = today.format("Y-M-D");

        return {
          from: this.from,
          to: this.to,
        };
      } else {
        const today = moment();
        const lastDate = moment().subtract(unit + "s", value);

        if (unit == 1) {
          this.filterText =
              "Last " +
              value +
              " " +
              unit +
              ", " +
              lastDate.format("MMM Do, YYYY") +
              "to" +
              today.format("MMM Do, YYYY");
        } else {
          this.filterText =
              "Last " +
              value +
              " " +
              unitName +
              ", " +
              lastDate.format("MMM Do, YYYY") +
              "to" +
              today.format("MMM Do, YYYY");
        }
        this.from = lastDate.format("Y-M-D");
        this.to = today.format("Y-M-D");

        return {
          from: this.from,
          to: this.to,
        };
      }
    },
    customFilter() {
      this.loading = true;

      this.filterText =
          "Custom date range, " +
          moment(this.from).format("MMM Do, YYYY") +
          "-" +
          moment(this.from).format("MMM Do, YYYY");

      const URL = "/api/filtersales?from=" + this.from + "&to=" + this.to;

      window.axios.get(URL).then((res) => {
        this.filterOn = true;
        this.items.data = res.data.data;
        this.totalAmount = res.data.total_amount;
        this.transactionsCount = res.data.record_count;
        this.totalQuantity = res.data.items_sold;
        this.loading = false;
      });
    },

    filterTransactions(unit = null, value = null) {
      this.loading = true;
      this.filterType = unit;
      this.filterUnit = value;

      const tabValue = unit;

      if (value) {
        this.tab = tabValue + "_" + value;
      } else {
        this.tab = tabValue;
      }

      if (
          this.$route.query.filter_type !== unit ||
          this.$route.query.filter_value !== value
      ) {
        this.$router.replace({
          path: this.$route.path,
          query: {filter_type: unit, filter_value: value},
        });
      }

      const range = this.getInterval(unit, value);
      const URL = "/api/pos/filter?from=" + range.from + "&to=" + range.to;

      window.axios.get(URL).then((res) => {
        this.filterOn = true;

        this.items.data = res.data.data;

        this.totalAmount = res.data.total_amount;
        this.transactionsCount = res.data.record_count;
        this.totalQuantity = res.data.items_sold;
        this.loading = false;
      });
    },

    getItems() {
      this.loading = true;

      if (this.$route.query.filter_type !== "all") {
        this.$router.replace({
          path: this.$route.path,
          query: {filter_type: "all"},
        });
      }
      this.tab = "all";

      const URL = "/api/pos/transactions?page=" + this.page;

      window.axios.get(URL).then((res) => {
        this.items = res.data.data;
        this.transactionsCount = this.items.total;
        this.totalAmount = res.data.summary.total_amount;
        this.totalQuantity = res.data.summary.items_count;
        this.loading = false;
        this.filterOn = false;
        this.page = res.data.current_page;
      });
    },
  },
  mounted() {
    if (this.$route.query.filter_type == "all") {
      this.getItems();
    } else {
      const defaultFilterType = this.$route.query.filter_type
          ? this.$route.query.filter_type
          : "today";
      const defaultUnit = this.$route.query.filter_value
          ? this.$route.query.filter_value
          : null;

      this.filterTransactions(defaultFilterType, defaultUnit);
    }
    this.from = moment().format("Y-M-D");
    this.to = moment().format("Y-M-D");
  },
};
</script>

<style scoped></style>
