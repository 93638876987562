<template>
  <v-card>
    <v-card-title>
      <v-text-field autofocus v-model="searchKey" clearable color="orange" rounded flat filled
                    placeholder="Search..."></v-text-field>
    </v-card-title>
    <v-card-text style="height: 40rem; overflow:auto!important;">

      <v-list-item
          v-for="item in filteredItems"
          :key="item.id"
          class="border-bottom"
          @click="selectItem(item)"
          :disabled="item.track_inventory && item.quantity<=0"
      >
        <v-list-item-avatar size="60" rounded="lg">
          <v-img :src="item.photo"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>Current QTY:{{ item.quantity }}</v-list-item-subtitle>
          <v-list-item-subtitle>Type:{{ item.type }}</v-list-item-subtitle>
          <v-list-item-subtitle>Track Inventory:{{ item.track_inventory ? "Yes" : "No" }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.track_inventory && item.quantity<=0" class="red--text">Out of stock</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon color="grey" small>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>

    </v-card-text>
  </v-card>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  emits:["selected"],
  name: "OrderitemSelector",
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      searchKey: ""
    }
  },
  computed: {
    filteredItems() {

      if (!this.searchKey) {
        return this.items;
      }

      return this.items.filter(item => {
        return item.name.toLowerCase().includes(this.searchKey.toLowerCase())
      })


    }
  },
 methods:{
   selectItem(item){

     this.$emit("selected",{
       id:item.id,
       product_id:item.id,
       name:item.name,
       quantity:1,
       unit_price:item.selling_price,
       amount:item.selling_price,
       photo:item.photo,
       discount:0,
       tax_amount:0,
       net_amount:item.selling_price,
       amount_due:item.selling_price,
       applied_taxes:item.applied_taxes,
       description:item.description
     })
   }
 }
})
</script>


<style scoped>

</style>