<template>
  <span>

    <center>

    <v-avatar :size="size" rounded class="d-block ma-auto">
      <v-img :src="preview" contain class="rounded-lg">
              <v-progress-circular v-if="loading" class="ma-auto" color="white" indeterminate width="3"></v-progress-circular>
      </v-img>

    </v-avatar>
    </center>

    <v-btn class="mt-2" color="orange" block text small
           @click="selectPhoto">{{ CAN_REMOVE_PHOTO ? 'Change' : 'Select' }}</v-btn>
    <v-btn block v-if="CAN_REMOVE_PHOTO" class="mt-2" color="red" text small
           @click="preview = defaultPreview; $emit('updated',preview)">Remove</v-btn>

    <v-file-input
        :id="index+'photo_selector'"
        v-model="file"
        accept=".png,.jpg,.jpeg,.webp"
        class="d-none"
        @change="fileSelected"
    ></v-file-input>

    <small v-if="name && !CAN_REMOVE_PHOTO" class="text--disabled d-block text-small">Photo of {{ name }}</small>

  </span>


</template>

<script>

export default {
  props: {
    default_path:{
      type:String,
      default:"https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/photo_placeholder.jpg"
    },
    name: {
      type: String,
      default: ""
    },
    index: {
      default: ""
    },
    size: {
      type: Number,
      default: 300
    },
    directory: {
      type: String,
      default: "photo"
    },
    UserOnly:{
      type:Boolean,
      default:false
    }
  },
  name: "PhotoSelectorComponent",
  data() {
    return {
      loading: false,
      file: null,
      defaultPreview: "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/photo_placeholder.jpg",
      preview: "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/photo_placeholder.jpg"
    }
  },
  watch:{

    default_path(){
      this.preview = this.default_path;


    }
  },

  computed: {
    CAN_REMOVE_PHOTO() {

      return this.preview !== this.defaultPreview;

    }
  },
  methods: {
    selectPhoto() {
      document.getElementById(this.index + "photo_selector").click();
    },
    updatedPreview(preview) {

      this.$emit("updated", preview);


    },
    fileSelected() {

      if (!this.file) {
        return;
      }

      this.loading = true;
      const formData = new FormData();

      formData.append("file", this.file);
      formData.append("directory", this.directory);
      if (this.UserOnly){
        formData.append("user_only", "true");

      }

      const URL = "/api/bucket/put";

      axios.post(URL, formData)
          .then(res => {

            this.preview = res.data.preview;

            this.updatedPreview(this.preview);

            this.loading = false;
          })
          .catch(error => {
            this.file=null;
            this.loading = false;
          })


    }
  },
  mounted() {

    this.preview = this.default_path;
    this.$emit("updated", this.preview);


  }

}
</script>

<style scoped>

</style>