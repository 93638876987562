<template>
  <v-app :class="$vuetify.theme.isDark ? 'dark-orange' : 'orange lighten-5'">
    <v-app-bar
        v-if="!$store.state.initializing && HAS_POS &&$store.state.user && $store.state.user.has_business && $route.name === 'Cart' && CAN_USE_POS"
        :color="$vuetify.theme.isDark? '#FF98000D' : 'orange lighten-5'"
        :height="!$vuetify.breakpoint.mobile ? '90px' : ''"
        app
        flat>
      <span style="background-color: rgba(255,152,0,0.05)" v-if="!$vuetify.breakpoint.mobile" class="ml-6 pl-5">
        <h2 class="font-weight-bold mt-3 pt-3 trim-text">
          Howdy, {{ $store.state.user.first_name }}
        </h2>
        <small class="text--disabled trim-text d-block">You are on {{ $store.state.user.current_business.name }}'s
          POS</small>
      </span>


      <v-text-field ref="searchBox" v-model="$store.state.search" :style="$vuetify.breakpoint.mobile
        ? 'margin-top: 40px !important; '
        : 'margin-top: 40px !important; margin-left: 20px;'
        " append-icon="mdi-magnify" clearable color="orange" flat placeholder="Search..." rounded solo
                    @blur="maintainFocus">
        <template v-slot:prepend-inner v-if="$vuetify.breakpoint.mobile">
          <v-btn color="orange" icon @click="$store.state.minNav = true">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <v-switch v-if="!$vuetify.breakpoint.mobile" v-model="$store.state.barcodeMode" class="mt-6 ml-5" color="orange"
                inset label="Barcode Mode" @change="setFocus"></v-switch>
    </v-app-bar>

    <v-navigation-drawer
        v-if="!$store.state.initializing && HAS_POS && $store.state.user && CAN_USE_POS &&  $store.state.user.has_business"
        v-model="$store.state.minNav" :mini-variant="!$vuetify.breakpoint.mobile" app floating
        mini-variant-width="90px">
      <v-list>
        <v-list-item class="px-2 border-bottom">
          <v-list-item-avatar rounded="xl">
            <v-img contain :src="$store.state.user.current_business.logo"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.user.first_name }}
              {{ $store.state.user.last_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $store.state.user.current_business.name }}
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              <span v-if="$store.state.user.is_admin" class="green--text">
                Admin <v-icon color="green" small>mdi-check-circle</v-icon>
              </span>

              <span v-else class="orange--text">
                Invited User
                <v-icon color="orange" small>mdi-account-circle</v-icon>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item v-for="item in navItems" :key="item.title" :title="item.title" :to="item.route"
                     :id="item.title + '_menu'" active-class="orange--text nav-active" link>
          <v-list-item-icon>
            <v-icon large>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list style="bottom: 0 !important; position: absolute; width: 100%">
        <span id="builtAppsSideLink">
          <v-list-item @click="showAppsDialog = true" color="green" class="green--text rounded"
                       style="background-color: rgba(0, 128, 0, 0.08)">
            <v-list-item-icon>
              <v-icon color="green" large>mdi-apps</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Built Apps</v-list-item-title>
          </v-list-item>
        </span>


        <v-menu transition="slide-y-transition">
          <template v-slot:activator="{on}">
            <v-list-item v-on="on">
              <v-list-item-icon>
                <v-icon large>mdi-dots-horizontal</v-icon>
              </v-list-item-icon>
              <v-list-item-title>More</v-list-item-title>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
                id="dark_mode_menu"
                active-class="orange--text nav-active"
                link
                @click="changeTheme">
              <v-list-item-icon>
                <v-icon v-if="!$vuetify.theme.isDark" large>mdi-lightbulb-outline</v-icon>
                <v-icon v-else large>mdi-lightbulb-on-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                  $vuetify.theme.isDark ? "Light Theme" : "Dark Theme"
                }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
                class="border-b"
                to="/settings"
                color="orange"
                link>
              <v-list-item-icon>
                <v-icon large>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings
              </v-list-item-title>
              <v-list-item-icon>
                <v-icon color="grey">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>


            <v-list-item id="logout_menu" active-class="orange--text nav-active" link @click="show_logout = true">
              <v-list-item-icon>
                <v-icon large>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


      </v-list>

    </v-navigation-drawer>

    <v-main :class="$vuetify.theme.isDark ? 'dark-orange' : 'orange lighten-5'">
      <v-container fluid :class="$vuetify.theme.isDark ? 'dark-orange' : 'orange lighten-5'">
        <v-dialog v-model="$store.state.showPrint" :fullscreen="$vuetify.breakpoint.mobile" persistent scrollable
                  transition="dialog-bottom-transition" width="500">
          <print-dialog-component @closed="$store.state.showPrint = false"></print-dialog-component>
        </v-dialog>
        <v-progress-circular
            v-if="$store.state.initializing"
            color="orange"
            indeterminate
            size="60"></v-progress-circular>
        <v-row dense v-if="!CAN_USE_POS && USER">
          <v-col class="mx-auto" cols="12" sm="4">
            <v-card flat class="mt-6">
              <v-card-text class="text-center">
                <center>
                  <div class="orange lighten-5" style="width: 160px; height: 160px; border-radius: 50%">
                    <v-icon class="mt-5" color="orange" x-large style="font-size: 100px">mdi-lock</v-icon>
                  </div>
                </center>
                <h2 class="error--text font-weight-light mt-4">
                  You have no access to the POS of this business
                </h2>
                <p class="text--disabled mt-5">
                  You cannot access the POS of
                  {{ USER.current_business.name }} at the moment, if you believe
                  this is unexpected contact the admin or owner of
                  {{ USER.current_business.name }}.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="Logout" x-large rounded color="orange" depressed block dark>Logout</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <span v-if="CAN_USE_POS && $store.state.user">
          <router-view v-if="!$store.state.initializing && HAS_POS && $store.state.user.has_business"></router-view>
          <create-first-business-component v-else-if="!$store.state.user.has_business"></create-first-business-component>
          <subscribe-component v-else></subscribe-component>
        </span>

        <login-component v-else-if="!$store.state.initializing && !$store.state.user"></login-component>

        <general-error-component></general-error-component>
        <v-dialog v-model="show_logout" width="500">
          <v-card>
            <v-card-title>Confirm Logout</v-card-title>
            <v-card-text>
              <h2 class="font-weight-light">
                Are you sure you want to logout?
              </h2>
            </v-card-text>
            <v-card-actions>
              <v-btn dark color="red" x-large rounded depressed block @click="Logout">Log out</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>

    <v-dialog v-model="showAppsDialog" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <AppCardComponent></AppCardComponent>
      </v-card>
    </v-dialog>

    <tour-component
        v-if="!$store.state.initializing && HAS_POS && $store.state.user &&  $store.state.user.has_business"></tour-component>
  </v-app>
</template>

<script>
import LoginComponent from "./components/loginComponent";
import GeneralErrorComponent from "./components/GeneralErrorComponent";
import PrintDialogComponent from "./components/printDialogComponent";
import TourComponent from "./components/tourComponent";
import AppCardComponent from "./components/dashboard/AppCardComponent.vue";
import CreateFirstBusinessComponent from "./components/CreateFirstBusinessComponent.vue";
import SubscribeComponent from "./components/SubscribeComponent.vue";

export default {
  name: "APp",
  components: {
    SubscribeComponent,
    CreateFirstBusinessComponent,
    TourComponent,
    PrintDialogComponent,
    GeneralErrorComponent,
    LoginComponent,
    AppCardComponent
  },
  data() {
    return {
      show_logout: false,
      miniNavBar: true,
      tempNav: false,
      showAppsDialog: false,
      navItems: [
        {
          title: "Home",
          icon: "mdi-home-outline",
          route: "/",
        },
        {
          title: "Sell",
          icon: "mdi-cart-plus",
          route: "/cart",
        },
        {
          title: "Orders",
          icon: "mdi-shopping-outline",
          route: "/orders",
        },
        {
          title: "Transactions",
          icon: "mdi-format-list-bulleted",
          route: "/transactions",
        },
        {
          title: "Inventory",
          icon: "mdi-list-box-outline",
          route: "/inventory",
        },{
          title: "Reports",
          icon: "mdi-file-chart-outline",
          route: "/reports",
        },
      ],
    };
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    CAN_USE_POS() {
      if (this.USER && this.USER.is_admin) {
        return true;
      } else {
        return this.USER && this.USER.invite.can_use_pos;
      }
    },
    HAS_POS(){
      return this.USER && this.USER.current_business  && this.USER.current_business.subscription.guards.POS;
    }
  },
  watch:{
    USER(){
      if (this.USER) {
        this.initItercom();
      }
}
  },
  methods: {

    initItercom() {
      this.$intercom.boot({
        user_id: this.$store.state.user.has_business
            ? this.$store.state.user.id +
            "-" +
            this.$store.state.user.current_business.country_id +
            "-" +
            this.$store.state.user.current_business.id
            : this.$store.state.user.id + "-NOB",
        name: this.$store.state.user
            ? this.$store.state.user.first_name +
            " " +
            this.$store.state.user.last_name
            : "User",
        email: this.$store.state.user.email,
        phone: this.$store.state.user.phone_number,
      });

    },

    maintainFocus() {
      if (this.$store.state.barcodeMode) {
        this.setFocus();
      }
    },
    setFocus() {
      if (!this.$store.state.barcodeMode) {
        this.$store.state.search = "_";
        this.$store.state.search = "";
      }
      this.$refs.searchBox.focus();
    },
    changeTheme() {
      if (this.$vuetify.theme.isDark) {
        this.$vuetify.theme.isDark = false;
        localStorage.setItem("theme", "light");
      } else {
        this.$vuetify.theme.isDark = true;
        localStorage.setItem("theme", "dark");
      }
    },
    Logout() {
      document.cookie = "token=";
      location.reload();
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mobile) {
      this.miniNavBar = false;
    } else {
      this.miniNavBar = true;
    }
    if (this.$store.state.user) {
      this.initItercom();
    }
  },
  sockets: {
    connect() {
    },
    disconnect() {
    },
  },
};
</script>
<style>
.trim-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.nav-active {
  border-right: 5px solid #ff9800 !important;
}


.dark-orange {
  background-color: #FF98000D !important;
}

</style>
