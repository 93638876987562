<template>
  <v-card>
    <v-card-title v-if="title || closable" class="font-weight-light">
      <v-btn v-if="step>1" text color="orange" @click="step=1">
        <v-icon>mdi-arrow-left</v-icon>
        Back
      </v-btn>
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn
          color="red"
          rounded
          text
          depressed
          @click="$emit('closed')"
      >Close
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-stepper flat v-model="step">
        <v-stepper-items>
          <v-stepper-content step="1">
            <h2 class="font-weight-light text-center">How do you want to adjust {{ item.name }}'s quantity?</h2>
            <p class="grey--text text-center">The mode of adjustment would determine the kind of accounts that would
              handle this transaction to reduce mistakes</p>


            <v-row dense>

              <v-col cols="12" sm="6">
                <v-card @click="nextStep('purchase')" outlined class="text-center">
                  <v-card-text>
                    <v-icon color="green" size="60">mdi-cart-check</v-icon>
                    <h3 class="font-weight-black">New Purchase</h3>
                    <small>You want to increase the quantity of this item because you bought more of it.</small>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6">
                <v-card @click="nextStep('damage')" outlined class="text-center">
                  <v-card-text>
                    <v-icon color="red" size="60">mdi-image-broken</v-icon>
                    <h3 class="font-weight-black">Damages/Theft</h3>
                    <small>You want to reduce the quantity of this item due damages or theft</small>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6">
                <v-card @click="nextStep('production')" outlined class="text-center">
                  <v-card-text>
                    <v-icon color="green" size="60">mdi-dolly</v-icon>
                    <h3 class="font-weight-black">From production</h3>
                    <small>You want to increase the quantity of this item by converting raw materials </small>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6">
                <v-card outlined class="text-center" @click="nextStep('advance')">
                  <v-card-text>
                    <v-icon color="orange" size="60">mdi-grain</v-icon>
                    <h3 class="font-weight-black">Advanced</h3>
                    <small>Make your own adjustment by selecting the type and account </small>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

          </v-stepper-content>

          <v-stepper-content step="2">

            <v-alert v-if="['purchase','damage'].includes(adjustmentMode.toLowerCase())" prominent text type="success"
                     dense>
              <h5 v-if="adjustmentMode.toLowerCase()==='purchase'" class="font-weight-light">Record increment due to
                purchase for {{ item.name }}</h5>
              <h5 v-if="adjustmentMode.toLowerCase()==='damage'" class="font-weight-light">Record decrement due to
                damage or theft for {{ item.name }}</h5>

            </v-alert>


            <v-alert v-if="adjustmentMode.toLowerCase()==='advance'" dense text type="warning" prominent>
              <h2>Note:</h2>
              <p>We recommend that only accountants or people with bookkeeping knowledge use this method to adjust
                inventory.
              </p>
            </v-alert>

            <v-form ref="adjust_form" class="pt-3">
              <v-row dense>
                <v-col cols="12" sm="7" v-if="CansShowTypeSelection">

                  <v-select
                      :items="types"
                      v-model="type"
                      outlined
                      color="orange"
                      :rules="requiredRules"

                      :prepend-inner-icon="typeIcon"
                  >
                    <template v-slot:item="{item}">
                      <v-icon color="green" v-if="item.value=='increase'">mdi-plus</v-icon>
                      <v-icon color="red" v-else>mdi-minus</v-icon>

                      {{ item.text }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" :sm="CansShowTypeSelection ? '5' : '12'">
                  <v-menu
                      v-model="Date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          v-model="date"
                          :label="Datelabel"
                          readonly
                          color="orange"
                          outlined=""
                          v-on="on"
                          :rules="requiredRules"

                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @input="Date_menu =false"
                        no-title
                        color="orange"

                    ></v-date-picker>
                  </v-menu>

                </v-col>
              </v-row>

              <v-row dense>

                <v-col cols="12" sm="3">
                  <h3>{{ item.quantity }}</h3>
                  <small>Current Quantity</small>
                </v-col>

                <v-col cols="12" sm="6">

                  <v-text-field
                      :rules="requiredRules"
                      :label="Quantitylabel"
                      outlined
                      :prepend-icon="typeIcon"
                      append-outer-icon="mdi-equal"
                      v-model="adjust_quantity"
                      persistent-hint
                      type="number"
                      color="orange"
                      min="0"
                      :hint="'Adjustment Amount:'+adjustmentAMount"
                  ></v-text-field>
                </v-col>


                <v-col cols="12" sm="3">
                  <h3>{{ newQuantity }}</h3>
                  <small class="d-block">New Quantity</small>

                </v-col>


              </v-row>

              <v-row dense>
                <v-col cols="12" sm="6" v-if="showCost">
                  <v-text-field
                      label="Unit Cost"
                      outlined
                      :disabled="type=='decrease'"
                      v-model="unit_price"
                      color="orange"
                      :rules="requiredRules"
                      type="number"
                      :prefix="$store.state.user.current_business.currency.code"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" v-if="showAccounts">
                  <v-autocomplete
                      :label=" AccountsLabel"
                      outlined
                      :items="adjustment_accounts"
                      item-value="id"
                      item-text="account_name"
                      color="orange"
                      :rules="requiredRules"
                      v-model="account_id"
                      :disabled="getting_account"
                      :loading="getting_account"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-textarea
                      label="Notes"
                      outlined
                      rows="2"
                      color="orange"
                      auto-grow
                      v-model="note"
                  ></v-textarea>
                </v-col>

              </v-row>

            </v-form>
            <v-alert v-if="!isValid" type="error" color="red lighten-5 red--text">
              <p>Adjustment quantity or New quantity should not be less than 0.</p>
            </v-alert>

          </v-stepper-content>


          <v-stepper-content step="3">

            <production-adjustment
                @done="productionAdjustmentsDone"
                :item="item"
                v-if="step===3"
            ></production-adjustment>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>


    </v-card-text>

    <v-card-actions>
      <v-btn
          v-if="isValid && step==2"
          :loading="progress"
          block
          color="orange"
          dark
          rounded
          depressed
          x-large
          @click="save"
          :disabled="getting_account"
      >Save adjustment
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import ProductionAdjustment from "./ProductionAdjustment.vue";

export default {
  components: {ProductionAdjustment},
  emits: ["closed", "saved"],
  props: {
    item: {
      default: null,
      type: Object
    },
    title: {
      default: 'New Adjustment',
      type: String
    },
    closable: {
      default: true,
      type: Boolean
    },
    show: {
      default: false,
      type: Boolean
    }
  },
  name: "AdjustComponent",
  computed: {


    showCost() {

      return this.adjustmentMode.toLowerCase() !== "damage";

    },

    showAccounts() {

      return this.adjustmentMode.toLowerCase() !== "damage";

    },

    adjustment_accounts() {


      let accounts=[];

      if (this.assetAccountsCategory && this.incomeAccountsCategory && this.liabilityAccountsCategory && this.equityAccountsCategory) {
        if (this.adjustmentMode.toLowerCase() === "purchase") {

          let sub = this.assetAccountsCategory ? this.assetAccountsCategory.subtypes.filter(account_type => {
            return account_type.name === "Cash & Bank";
          }) : [];

          const cashBankAccount = sub[0].accounts;


          cashBankAccount.unshift({
            header: 'Bank & Cash',

          });


          const equitySub = this.equityAccountsCategory.subtypes[1].accounts;


          equitySub.unshift({
            header: 'Equity',

          });


          const liabilitySub = this.liabilityAccountsCategory.subtypes[2].accounts;


          liabilitySub.unshift({
            header: 'Liability',

          });


          accounts = [...cashBankAccount, ...equitySub, ...liabilitySub];


        } else {


          const ExpenseAccounts = this.expenseAccountsCategory ? this.expenseAccountsCategory.subtypes.filter(account_type => {
            return account_type.name === "Cost of Sale";
          }) : [];

          accounts = ExpenseAccounts[0].accounts;
        }

      }

      return accounts.filter(ac => {

        return !ac.archived || (!ac.currency || ac.currency.toLowerCase() === this.$store.state.user.current_business.currency.code.toLowerCase());

      })


    },

    AccountsLabel() {
      return this.adjustmentMode.toLowerCase() !== "purchase" ? " Adjustment Account" : "Paid from"

    },
    Quantitylabel() {

      if (this.adjustmentMode.toLowerCase() === "purchase") {
        return "Quantity Purchased";
      }


      if (this.adjustmentMode.toLowerCase() === "damage") {
        return "Quantity Lost";
      }


      return "Adjustment";

    },
    Datelabel() {


      if (this.adjustmentMode.toLowerCase() === "purchase") {

        return "Purchase Date";
      }

      if (this.adjustmentMode.toLowerCase() === "damage") {

        return "Date";
      }

      return "Stock Date";


    },

    CansShowTypeSelection() {

      return !['damage', 'purchase'].includes(this.adjustmentMode.toLowerCase());

    },

    isValid() {
      return this.newQuantity >= 0 && this.adjust_quantity >= 0;
    },
    typeIcon() {
      return this.type === 'increase' ? 'mdi-plus' : 'mdi-minus';
    },
    newQuantity() {
      return this.type === 'increase' ? Number(this.adjust_quantity) + Number(this.item.quantity) : Number(this.item.quantity) - Number(this.adjust_quantity);

    },

    newStockAMount() {
      return this.unit_price * this.newQuantity;
    },

    adjustmentAMount() {
      return (Number(this.unit_price) * Number(this.adjust_quantity)).toFixed(2);
    }

  },
  data() {
    return {
      incomeAccountsCategory: null,
      expenseAccountsCategory: null,
      assetAccountsCategory: null,
      liabilityAccountsCategory: null,
      equityAccountsCategory: null,
      step: 1,
      getting_account: false,
      note: '',
      adjustmentMode: "advance",
      requiredRules: [
        v => !!v || "This field is required",
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      accounts: [],
      unit_price: 0,
      adjust_quantity: 0,
      date: '',
      account_id: null,
      Date_menu: false,
      type: 'increase',
      types: [
        {
          value: 'increase',
          text: "Increase Quantity"
        },
        {
          value: 'decrease',
          text: "Decrease Quantity"
        },

      ],
      progress: false
    }
  },
  methods: {

    getExpenseAccountsCategory() {

      axios.get("/api/getexpense")
          .then(res => {
            this.expenseAccountsCategory = res.data;
          })

    },

    getLiabilityAccountsCategory() {

      axios.get("/api/getliability")
          .then(res => {
            this.liabilityAccountsCategory = res.data;
          })

    },
    getAssetAccountsCategory() {

      axios.get("/api/getassets")
          .then(res => {
            this.assetAccountsCategory = res.data;
          })

    },
    getEquityAccountsCategory() {

      axios.get("/api/getequity")
          .then(res => {
            this.equityAccountsCategory = res.data;
          })

    },

    getIncomeAccountsCategory() {

      axios.get("/api/getincome")
          .then(res => {
            this.incomeAccountsCategory = res.data;
          })

    },

    productionAdjustmentsDone(item) {
      this.$emit("saved", item);
      this.$emit('closed');

      this.step = 1;

    },

    /**
     *
     * @param type
     */

    nextStep(type) {

      this.adjustmentMode = type;

      if (this.adjustmentMode.toLowerCase() === 'purchase') {
        this.type = "increase";
      }

      if (this.adjustmentMode.toLowerCase() === 'damage') {
        this.type = "decrease";
      }

      if (this.adjustmentMode.toLowerCase() === 'production') {
        this.type = "production";
      }


      this.step = 2;

      if (this.adjustmentMode.toLowerCase() === 'production') {
        this.step = 3;

      }


    },
    getAccount(type = null) {

      this.getting_account = true;

      const URL = type ? '/api/v2/items/adjustmentaccount?type=' + type : '/api/v2/items/adjustmentaccount';

      axios.get(URL)
          .then(res => {
            const data = res.data;
            this.account_id = data.id;

            const test = this.adjustment_accounts.find(item => {
              return item.id === data.id;
            });

            if (!test) {
              this.adjustment_accounts.push(data);
            }

            this.getting_account = false;


          })
          .catch(error => {

            this.getting_account = false;

          })

    },

    save() {

      if (this.$refs.adjust_form.validate()) {

        this.progress = true;
        const formData = new FormData();

        formData.append('type', this.type);
        formData.append('account_id', this.account_id);
        formData.append('item_id', this.item.id);
        formData.append('quantity', this.newQuantity);
        formData.append('unit_price', this.unit_price);
        formData.append('amount', this.adjustmentAMount);
        formData.append('note', this.note);
        formData.append('date', this.date);
        formData.append('adjustment_quantity', this.adjust_quantity);

        axios.post('/api/v2/item/adjust', formData)
            .then(res => {
              this.progress = false;
              this.adjust_quantity = 0;
              this.note = "";
              this.$emit('saved', res.data);
              this.$emit('closed');

            })
            .catch(error => {
              this.progress = false;


            })

      }

    }
  },
  watch: {
    item() {
      this.unit_price = this.item.real_unitPrice;

    },
    adjustmentMode() {
      if (this.adjustmentMode.toLowerCase() === 'purchase') {

        this.account_id = null;

      } else if (this.adjustmentMode.toLowerCase() === 'damage') {
        this.getAccount('write_off');
      } else {

        this.getAccount();

      }
    }
  },
  mounted() {


    // const account_types = this.$store.state.user.countryInfo.expenseAccounts.subtypes.filter(account_type => {
    //   return account_type.name === "Cost of Sale";
    // });
    //
    // this.adjustment_accounts = account_types[0].accounts;

    this.unit_price = this.item.real_unitPrice;

    this.date = moment().format('Y-M-D');


    this.getAccount();


    this.getExpenseAccountsCategory();
    this.getIncomeAccountsCategory();
    this.getAssetAccountsCategory();
    this.getLiabilityAccountsCategory();
    this.getEquityAccountsCategory();

  }
}
</script>

<style scoped>

</style>
