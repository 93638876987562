import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import VueApexCharts from "vue-apexcharts";
import "./registerServiceWorker";
import VueTour from "vue-tour";
import Flutterwave from "vue-flutterwave";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import FlagIcon from "vue-flag-icon";
import moment from "moment";
import VueIntercom from "vue-intercom";

import {abbreviateNumber} from "./utils";

require("vue-tour/dist/vue-tour.css");

let localhostURl = "http://localhost:2323";
let prodURL = "https://soc-net.builtaccounting.com/";
Vue.use(
  new VueSocketIO({
    connection: SocketIO(prodURL),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.use(Flutterwave, { publicKey: store.state.flutterwave_key });


Vue.config.productionTip = false;
Vue.use(VueTour);
Vue.use(FlagIcon);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueIntercom, { appId: "ev5nda20" });


new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  created() {
    if (!this.$vuetify.breakpoint.mobile) {
      store.state.cartSideNav = true;
      store.state.minNav = true;
    }

    if (this.$route.query.s) {
      const token = this.$route.query.s;

      document.cookie = "token=" + token;
      window.location = "/";
    }
    const isDark = localStorage.getItem("theme") === "dark";

    if (isDark) {
      this.$vuetify.theme.isDark = true;
    }

    store.commit("initApp");
  },
}).$mount("#app");

Vue.filter("toMoney", (d) => {
  if (d) {
    return Number(d)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "0.00";
  }
});

Vue.filter("humanDate", (d) => {
  if (d) {
    return moment(d, "YYYY-MM-DD").format("MMM Do, YYYY");
  }
});

Vue.filter("humanDateTime", (d) => {
  if (d) {
    return moment(d).format("MMM Do YYYY, h:mm A");
  }
});


Vue.filter("absolute", (d) => {
  if (d) {
    if (!isNaN(d)) {
      return d < 0
          ? "(" +
          (Number(d) / -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
          ")"
          : Number(d)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return d;
    }
  } else {
    return 0;
  }
});
Vue.filter("short_number", (d) => {
  return abbreviateNumber(d);
});

Vue.filter("currency_symbol", (d) => {
  const currencySymbol =
      store.state.user.current_business.currency.symbol;
  if (!isNaN(d)) {
    return currencySymbol + Math.abs(d);
  } else {
    return currencySymbol + d;
  }

});
