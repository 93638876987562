<template>
<small :class="percentage >0 ? 'green--text' : 'red--text'">
  {{ Math.abs(percentage) }}%
  <sup class="text-small">

  <v-icon class="pa-0 ma-0" x-small color="green" v-if="percentage>0">mdi-arrow-up</v-icon>
  <v-icon class="pa-0 ma-0" x-small color="red" v-else>mdi-arrow-down</v-icon>
  </sup>

</small>
</template>

<script>
export default {
  props: {
    mainValue: {
      type: Number
    },
    compareValue: {
      type: Number
    },

  },
  computed: {
    percentage() {

      let diff = 0
      // if (Number(this.compareValue) < 0 || Number(this.mainValue) < 0) {
      //
      //   diff =  Number(this.compareValue)+ Number(this.mainValue);
      //
      // } else {

      diff = Number(this.compareValue) - Number(this.mainValue);

      // }

      const p = diff !== 0 ? (diff / Number(this.mainValue)) * 100 : 0;

      if (Number(this.compareValue) < 0 && p>0) {

        return (p / -1).toFixed(2);

      }

      if (Number(this.mainValue) <0 && Number(this.compareValue)>0) {

        return Math.abs(p).toFixed(2);

      }


      return p.toFixed(2);


    }

  },
  name: "ComparePercentageComponent"
}
</script>

<style scoped>

</style>