<template>
  <span>
    <v-autocomplete
        :items="filteredAccounts"
        v-model="account"
        :outlined="!flat"
        :dense="!!dense"
        item-value="id"
        item-text="account_name"
        :loading="isLoading"
        :placeholder="label"
        :hint="hint"
        :flat="flat"
        :rules="!nullable ? $store.state.requiredRule : []"
        :filled="filled"
        :rounded="rounded"
        @change="handleAccountChange"
        color="orange"
        item-color="orange"
        :return-object="this.returnObject"
    >

      <template v-slot:no-data>
        <p class="text-center grey--text">There are no accounts, create a new account</p>
      </template>

      <template v-slot:prepend-item>
        <v-btn color="orange" text block @click="createDialog = true">
          <v-icon>mdi-plus</v-icon>
         New Account
        </v-btn
        >
      </template>



      <template v-slot:item="{item}">


        <template v-if="typeof item !=='object'">
          <span class="blue--text">{{item}}</span>
        </template>
        <template v-else>


        <template v-if="item.currency">
          {{ item.account_name }} {{ `(${item.currency})` }}

        </template>

        <template v-else>
          {{ item.account_name }}

        </template>
        </template>


      </template>

    </v-autocomplete>
    <v-dialog
        v-model="createDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        width="500"
        scrollable
        persistent
    >
      <v-card>
        <v-card-title class="font-weight-light">
         Create Account
          <v-spacer></v-spacer>
          <v-btn
              depressed
              color="red"
              rounded
              text
              @click="createDialog = false"
              small
          >Close
            <v-icon small>mdi-close</v-icon>
          </v-btn
          >
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form ref="accountForm">
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-select
                    :items="mainTypes"
                    item-value="id"
                    item-text="name"
                    outlined
                    v-model="selectedAccount"
                    placeholder="Account Type"
                    color="orange"
                    item-color="orange"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                    outlined
                    v-model="accountName"
                    :rules="rules"
                    placeholder="Account Name"
                    color="orange"

                >
                </v-text-field>
              </v-col>


              <v-col cols="12" sm="12">
                <v-text-field
                    outlined
                    v-model="accountCode"
                    placeholder="Account Code"
                    color="orange"

                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-textarea
                    outlined
                    v-model="accountDescription"
                    placeholder="Description"
                    rows="2"
                    auto-grow
                    color="orange"

                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :loading="isSaving"
              @click="saveAccount"
              color="orange"
              dark
              block
              rounded
              x-large
              depressed
          >
            Create Account

          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>

import {CURRENCY} from "./data";

export default {
  emits:["accountChange"],
  props:{
    returnObject:{
      type:Boolean,
      default:false
    },
    label:{
      type:String,
      default:"Select Account"
    },
    hint:{
      type:String,
    },
    subAccounts:{
      type:Array,
    },
    mainTypes:{
      type:Array
    },
    accountTypes:{
      type:Array,
    },
    defaultAccount:{
      type:Number,
    },
    dense:{
      type:Boolean,
    },
    flat:{
      type:Boolean,
    },
    rounded:{
      type:Boolean,
    },
    filled:{
      type:Boolean,
    },
    nullable:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      account: null,
      isLoading: false,
      isSaving: false,
      createDialog: false,
      selectedAccount: null,
      accountName: "",
      accountCode: "",
      accountDescription: "",
      rules: [(v) => !!v || "Account name is required"],
      currencyList: CURRENCY,
      currency: null,
      allSubAccounts: [],
    };
  },

  watch: {

    subAccounts(val) {
      this.allSubAccounts = val
    },

    defaultAccount(val) {
      this.account = val
    }
  },
  methods: {
    handleAccountChange() {
      this.$emit("accountChange", this.account);
    },
    cache_accounts() {
      localStorage.setItem('accounts.' + this.$store.state.user.user_infor.current_business.id, JSON.stringify(this.$store.state.user.countryInfo));

    },
    saveAccount() {
      if (this.$refs.accountForm.validate()) {
        this.isSaving = true;
        const formdata = new FormData();
        formdata.append("subtype_id", this.selectedAccount);
        formdata.append("name", this.accountName);
        formdata.append("code", this.accountCode);
        formdata.append("description", this.accountDescription);
        if (this.currency) formdata.append("currency", this.currency.cc);
        axios
            .post("/api/createaccount", formdata)
            .then((res) => {
              this.account = res.data;
              this.allSubAccounts = [res.data, ...this.allSubAccounts];
              this.$emit("accountChange", res.data.id);
              this.isSaving = false;
              this.createDialog = false;
            })
            .catch((err) => {
              this.isSaving = false;
            });
      }
    },
    customFilter(item, queryText) {
      const textOne = item.cc.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    withCurrency({account_name, currency}) {
      return currency ? `${account_name} (${currency})` : `${account_name}`;
    },

    // getAccounts() {
    //   const fetchMap = {
    //     Assets: {
    //       url: "getassets",
    //       name: "assetAccounts",
    //     },
    //     Equity: {
    //       url: "getEquity",
    //       name: "equityAccounts",
    //     },
    //     Expense: {
    //       url: "getexpense",
    //       name: "expenseAccounts",
    //     },
    //     Income: {
    //       url: "getincome",
    //       name: "incomeAccounts",
    //     },
    //     Liability: {
    //       url: "getliability",
    //       name: "liabilityAccounts",
    //     },
    //   };
    //   this.isLoading = true;
    //
    //   const setAccount = (accountType) => {
    //     const account = fetchMap[accountType];
    //     axios
    //         .get(`/api/${account.url}`)
    //         .then((res) => {
    //           this.$store.state.user.countryInfo[account.name] = res.data;
    //           this.isLoading = false;
    //           this.cache_accounts();
    //         })
    //         .catch(() => (this.isLoading = false));
    //   };
    //   return Promise.all(
    //       this.accountTypes.map((accountType) => setAccount(accountType))
    //   ).catch(() => (this.isLoading = false));
    // },
  },

  computed: {
    IDChanged() {
      return this.id;
    },
    // Mainaccounts() {
    //   return {
    //     Assets: this.$store.state.user.countryInfo.assetAccounts.subtypes,
    //     Equity: this.$store.state.user.countryInfo.equityAccounts.subtypes,
    //     Expense: this.$store.state.user.countryInfo.expenseAccounts.subtypes,
    //     Income: this.$store.state.user.countryInfo.incomeAccounts.subtypes,
    //     Liability: this.$store.state.user.countryInfo.liabilityAccounts
    //         .subtypes,
    //   };
    // },
    // accounts() {
    //   const accountMap = {
    //     Assets: this.$store.state.user.countryInfo.assetAccounts.subtypes,
    //     Equity: this.$store.state.user.countryInfo.equityAccounts.subtypes,
    //     Expense: this.$store.state.user.countryInfo.expenseAccounts.subtypes,
    //     Income: this.$store.state.user.countryInfo.incomeAccounts.subtypes,
    //     Liability: this.$store.state.user.countryInfo.liabilityAccounts.subtypes,
    //   };
    //   return this.accountTypes.reduce(
    //       (accumulator, accountType) => [
    //         ...accumulator,
    //         ...accountMap[accountType],
    //       ],
    //       []
    //   );
    // },
    filteredAccounts() {
      return this.allSubAccounts.filter(account => {
        return !account.archived;
      });
    }
  },
  mounted() {

    this.allSubAccounts = this.subAccounts;


  }

};
</script>
