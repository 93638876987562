<template>
  <v-container fluid>
    <v-card flat>
      <item-summaries-component></item-summaries-component>
      <v-card-title>
        Inventory
        <v-spacer></v-spacer>
        <v-text-field
            v-model="searchQuery"
            :loading="searching"
            class="mr-3 mt-5"
            clearable
            color="orange"
            filled
            flat
            hint="Press enter to search"
            placeholder="Search..."
            rounded
            style="transition: 0.3s ease-in-out"
            type="search"
            @keydown.enter="searchItems"
        >
          <template v-slot:append>
            <v-btn
                :dark="Boolean(searchQuery)"
                :disabled="!searchQuery"
                :loading="searching"
                class="ma-0 mb-4 text-capitalize"
                color="orange"
                depressed
                rounded
                @click="searchItems"
            >Search
            </v-btn>
          </template>
        </v-text-field>
        <v-btn
            class="mr-3 text-capitalize"
            color="orange"
            depressed
            outlined
            rounded
            x-large
            @click="
            showFInDialog = true;
            makeInputFocus();
          "
        >Find Item
          <v-icon x-large>mdi-barcode-scan</v-icon>
        </v-btn>
        <v-btn
            target="_blank"
            color="orange"
            dark
            depressed
            rounded
            x-large
            class="text-capitalize"
            @click="showNewProductDialog=true"
        >
          New Item
        </v-btn>
        <v-menu transition="slide-y-transition">
          <template v-slot:activator="{on}">
            <v-btn v-on="on" x-large depressed rounded class="ml-2" text color="orange">More <v-icon>mdi-chevron-down</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item @click="showImportDialog=true">
              <v-list-item-title>Import Items</v-list-item-title>
            </v-list-item>

               <v-list-item :href="printURL" target="_blank">
              <v-list-item-title>Items List</v-list-item-title>
            </v-list-item>

               <v-list-item :href="printBarcodeURL" target="_blank">
              <v-list-item-title>Barcode List</v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <v-simple-table
            v-else
            height="70vh"
            fixed-header
        >
          <thead>
          <tr>
            <th class="text-center">Photo</th>
            <th>Name</th>
            <th>Code/SKU</th>
            <th class="text-right">Unit Price</th>
            <th class="text-right">Computer Cost Per Stock</th>
            <th class="text-center">Selling Price</th>
            <th class="text-right">Current QTY</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody v-if="items && items.data">
          <tr v-for="item in items.data" :key="item.id">
            <td>
              <v-avatar rounded="xl" size="80" class="ma-2">
                <v-img :src="item.photo" contain></v-img>
              </v-avatar>
            </td>
            <td>
              <v-btn
                  text
                  color="orange"
                  class="pa-0"
                  rounded
                  :to="'/inventory/view/'+item.id"
              >
                {{ item.name }}
              </v-btn>
              <small class="text--disabled d-block">{{
                  item.description
                }}</small>
              <small class="text--disabled d-block"
              >Type:{{ item.type }}</small
              >
              <small class="text--disabled d-block"
              >Code:{{ item.code }}</small
              >
              <small class="orange--text d-block"
              >Track Inventory:{{
                  item.track_inventory ? "Yes" : "No"
                }}</small
              >
            </td>
            <td>
              {{ item.code }}
            </td>

            <td class="text-right">
              {{ currency.code }} {{ item.unit_price | toMoney }}
            </td>

            <td class="text-right">
              {{ currency.code }} {{ item.unformated_balance | toMoney }}
            </td>

            <td class="text-center">
              {{ item.selling_price | toMoney }}
            </td>


            <td class="text-center">
              {{ item.quantity }}
            </td>

            <td>
              {{ formatDate(item.created_at) }}
            </td>
            <td>
              <v-menu transition="slide-y-transition">
                <template v-slot:activator="{on}">
                  <v-btn icon color="orange" v-on="on">
                    <v-icon color="orange">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item  :to="'/inventory/view/'+item.id">
                    <v-list-item-title>View Details</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                      v-if="item.track_inventory && item.type.toLowerCase()==='product'"
                      @click="selectedItem=item; showAdjustmentDialog=true;"
                  >
                    <v-list-item-title>Adjust Stock</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="showNewProductDialog=true; editing=true; selectedItem=item">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="showDeleteDialog=true; selectedItem=item">
                    <v-list-item-title class="red--text">Delete</v-list-item-title>
                  </v-list-item>


                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions v-if="!searchOn">
        <v-spacer></v-spacer>
        <v-btn
            :disabled="!items.prev_page_url || loading"
            color="orange"
            rounded
            text
            @click="getItems(items.prev_page_url)"
        >
          <v-icon>mdi-arrow-left</v-icon>
          Prev
        </v-btn>
        <v-autocomplete
            v-model="page"
            :items="pages"
            color="orange"
            filled
            flat
            item-color="orange"
            label="Page"
            rounded
            @change="getItems(null)"
        ></v-autocomplete>
        <v-btn
            :disabled="!items.next_page_url || loading"
            color="orange"
            rounded
            text
            @click="getItems(items.next_page_url)"
        >Next
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="showFInDialog"
        eager
        persistent
        scrollable
        width="500"
    >
      <v-card>
        <v-card-title
        >Find inventory item
          <v-spacer></v-spacer>
          <v-btn color="grey" icon @click="showFInDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <v-stepper v-model="$store.state.findStep" style="box-shadow: none">
            <v-stepper-items style="box-shadow: none">
              <v-stepper-content step="1">
                <template v-if="findingItem">
                  <p v-if="!item" class="text-center">
                    <center>
                      <v-progress-circular
                          class="d-block"
                          color="orange"
                          indeterminate
                          size="70"
                      ></v-progress-circular>
                      Finding the item please, wait...
                    </center>
                  </p>

                  <div v-else class="text-center">
                    <lottie-animation
                        :animationData="require('@/assets/success.json')"
                        :autoPlay="true"
                        :loop="true"
                        style="height: 200px"
                    ></lottie-animation>
                    <h2 class="green--text font-weight-light">
                      Great we have found the item
                    </h2>
                  </div>
                </template>
                <span v-show="!findingItem">
                  <v-text-field
                      flat
                      :color="$vuetify.theme.isDark ? 'black' : 'white'"
                      id="codeInput"
                      ref="codeInput"
                      v-model="itemCode"
                      autofocus
                      @blur="makeInputFocus()"
                      @keydown.enter="findID"
                  ></v-text-field>

                  <lottie-animation
                      :animationData="require('@/assets/barcode_animations.json')"
                      :autoPlay="true"
                      :loop="true"
                      style="height: 200px"
                  ></lottie-animation>

                  <h2 class="text-h4 font-weight-light orange--text">
                    Let's find the item you are looking for!
                  </h2>
                  <p class="text--disabled mt-3">
                    To find the item you are looking for, scan the barcode of
                    the item using the connected barcode scanner
                  </p>
                </span>
              </v-stepper-content>

              <v-stepper-content class="pa-0" step="2">
                <v-card-title>
                  <v-btn
                      small
                      text
                      @click="
                      $store.state.findStep = 1;
                      item = null;
                      findingItem = false;
                    "
                  >
                    <v-icon small>mdi-arrow-left</v-icon>
                    Back
                  </v-btn>
                </v-card-title>

                <v-avatar v-if="item" rounded size="180">
                  <v-img :src="item.photo" contain></v-img>
                </v-avatar>

                <v-list v-if="item" class="text-left">
                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title
                          class="text-h4 orange--text font-weight-light"
                      >
                        {{ item.code }}
                        <v-icon color="orange" x-large>mdi-tag-outline</v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">{{
                          item.name
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text--disabled">{{
                          item.description
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">{{
                          item.quantity
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Quantity</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5"
                      >{{ currency.code }}{{ item.selling_price | toMoney }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Price</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">{{
                          item.type
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Type</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">{{
                          item.track_inventory ? "Yes" : "No"
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                      >Track inventory
                      </v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="border-bottom">
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">{{
                          formatDate(item.created_at)
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Created At</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showError" color="error" dark>{{
        errorMessage
      }}
    </v-snackbar>


    <v-dialog
        width="500"
        v-model="showNewProductDialog"
        scrollable
        persistent
    >
      <new-item-component
          @ItemCreated="getItems(); showNewProductDialog=false; selectedItem=null;editing=false;"
          @closed="showNewProductDialog=false; selectedItem=null; editing=false;"
          :item="selectedItem"
          :editing="editing"
      ></new-item-component>

    </v-dialog>


    <v-dialog
        width="500"
        v-model="showDeleteDialog"
        :persistent="deleting"
    >
      <v-card>
        <v-card-title>Confirm Delete</v-card-title>
        <v-card-text>
          <h1 class="font-weight-light">Do you want to delete this item?</h1>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="deleting" @click="do_delete" rounded color="red" block depressed x-large dark>Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        width="500"
        v-model="showAdjustmentDialog"
    >
      <adjust-component @saved="showAdjustmentDialog=false; getItems();" @closed="showAdjustmentDialog=false"
                        :item="selectedItem" title="Adjustment Inventory" :closable="true"></adjust-component>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition"  persistent fullscreen v-model="showImportDialog">
      <v-card>
        <v-card-title>
         Import new items to your inventory
          <v-spacer></v-spacer>
          <v-btn rounded text color="red" @click="showImportDialog=false">Close<v-icon small>mdi-close</v-icon></v-btn>
        </v-card-title>
        <items-import-component @done="showImportDialog=false; getItems()"></items-import-component>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import moment from "moment";
import LottieAnimation from "lottie-web-vue";
import store from "../store";
import NewItemComponent from "../components/NewItemComponent.vue";
import ItemSummariesComponent from "../components/ItemSummariesComponent.vue";
import AdjustComponent from "../components/AdjustComponent.vue";
import ItemsImportComponent from "../components/ItemsImportComponent.vue";

export default {
  name: "Inventory",
  components: {
    ItemsImportComponent,
    AdjustComponent, ItemSummariesComponent, NewItemComponent, LottieAnimation},
  data() {
    return {
      showImportDialog:false,
      showAdjustmentDialog: false,
      errorMessage: "",
      showError: false,
      itemCode: "",
      items: [],
      loading: false,
      page: 1,
      searchQuery: "",
      searchOn: false,
      searching: false,
      showFInDialog: false,
      findingItem: false,
      item: null,
      showNewProductDialog: false,
      selectedItem: null,
      showDeleteDialog: false,
      deleting: false,
      editing: false
    };
  },
  watch: {
    searchQuery() {
      if (!Boolean(this.searchQuery)) {
        this.getItems(null);
      }
    },
  },
  computed: {

    printBarcodeURL(){
      return this.$store.state.baseUrl+"/itembarcodes?bid="+this.$store.state.user.current_business.enc_id;
    },
    printURL(){
      return this.$store.state.baseUrl+"/printallpitems?bid="+this.$store.state.user.current_business.enc_id;
    },
    pages() {
      if (!this.items) {
        return [
          {
            value: 1,
            text: "Page 1",
          },
        ];
      }

      let list = [];

      for (let i = 1; i <= this.items.last_page; i++) {
        list.push({
          value: i,
          text: "Page " + i,
        });
      }

      return list;
    },
    business() {
      return this.$store.state.user.current_business;
    },
    currency() {
      return this.business.currency;
    },
  },
  methods: {
    do_delete() {
      this.deleting = true;
      axios
          .delete("/api/deleteitem/" + this.selectedItem.id)
          .then(() => {

            this.deleting = false;
            this.showDeleteDialog = false;
            this.getItems()
          })
          .catch(() => {
            this.deleting = false;
          });
    },
    makeInputFocus() {
      this.$refs.codeInput.focus();
    },

    findID() {
      if (Boolean(this.itemCode)) {
        // this.itemCode = "";

        this.findingItem = true;
        this.item = null;
        window.axios
            .get("/api/pos/items?keyword=" + this.itemCode)
            .then((res) => {
              this.itemCode = "";
              if (!res.data.data.length) {
                this.errorMessage = "No item found with this code or SKU";
                this.showError = true;
                this.item = null;
                this.findingItem = false;
              } else {
                this.item = res.data.data[0];

                setTimeout(function () {
                  store.state.findStep = 2;
                }, 1500);
              }

              this.makeInputFocus();
            });
      }
    },
    searchItems() {
      this.searching = true;

      window.axios.get("/api/items/search/" + this.searchQuery).then((res) => {
        this.items.data = res.data;
        this.searchOn = true;
        this.searching = false;
      });
    },
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm A");
    },
    getItems(url = null) {
      this.searchOn = false;

      const URL = url ? url : "/api/v2/items?page=" + this.page + "&for_sale=true";

      this.loading = true;
      window.axios.get(URL).then((res) => {
        this.items = res.data;
        this.$router.replace({
          path: this.$route.path,
          query: {page: res.data.current_page},
        });
        this.page = res.data.current_page;

        this.loading = false;
      });
    },
  },
  mounted() {
    const current_page = this.$route.query.page;
    const page = current_page ? current_page : 1;

    const URL = "/api/v2/items?page=" + page + "&for_sale=true";

    this.getItems(URL);
  },
};
</script>

<style scoped></style>
