var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.user.is_admin)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-simple-table',{attrs:{"fixed-header":"","height":"90vh"}},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(head){return _c('th',[_vm._v(" "+_vm._s(head.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.bills),function(bill){return _c('tr',{key:bill.id},[_c('td',[(bill.active)?_c('span',[_c('v-icon',{staticClass:"green--text",attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" Active ")],1):_c('span',[_c('v-icon',{staticClass:"text-muted",attrs:{"small":"","l":""}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" Completed ")],1)]),_c('td',[_vm._v(" "+_vm._s(_vm._f("toMoney")(bill.amount))+" ")]),_c('td',[_c('strong',[_vm._v(" "+_vm._s(bill.planname))]),(bill.add_ons)?_c('p',{staticClass:"text-small"},[_c('strong',[_vm._v("Add-ons ")]),_vm._v(" ( "),(
                      bill.add_ons.employee_extension &&
                      Number(bill.add_ons.employee_extension.amount > 0)
                    )?_c('span',[_vm._v(" Extra Employees: "+_vm._s(bill.add_ons.employee_extension.extraNumber)+", current limit:"+_vm._s(bill.add_ons.employee_extension.totalNumber)+" ")]):_vm._e(),(
                      bill.add_ons.onsite_visits &&
                      Number(bill.add_ons.onsite_visits.amount > 0)
                    )?_c('span',[_vm._v(" | Hours: "+_vm._s(bill.add_ons.onsite_visits.hours)+", "+_vm._s(bill.add_ons.onsite_visits.visits_per_week)+" visits per week, "+_vm._s(bill.add_ons.onsite_visits.visits_per_month)+" per month | ")]):_vm._e(),_vm._v(" ) ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm._f("humanDate")(bill.start_date))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("humanDate")(bill.end_date))+" ")]),_c('td',[_c('v-btn',{attrs:{"color":"red","small":"","disabled":!bill.active,"text":""},on:{"click":function($event){_vm.cancel_dialog = true;
                    _vm.id = bill.id;}}},[_vm._v("Cancel")]),_c('a',{staticClass:"orange--text",attrs:{"href":_vm.$store.state.baseUrl +
                    '/' +
                    'subscriptioninvoice/' +
                    bill.id}},[_vm._v("Download "),_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-download")])],1)],1)])}),0)])],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.cancel_dialog),callback:function ($$v) {_vm.cancel_dialog=$$v},expression:"cancel_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Why do you want to cancel? ")]),_c('v-card-text',[_c('h4',{staticClass:"red--text pa-2"},[_vm._v(" Are you sure you want cancel this subscription? you will NOT receive any credits or refund afterwards ")]),_c('h5',[_vm._v("Select Reason")]),(!_vm.other)?_c('v-autocomplete',{attrs:{"outlined":"","color":"orange","item-color":"orange","items":_vm.reasons},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}):_vm._e(),_c('v-switch',{attrs:{"color":"orange","inset":"","label":"Other Reasons, please specify"},model:{value:(_vm.other),callback:function ($$v) {_vm.other=$$v},expression:"other"}}),_c('br'),(_vm.other)?_c('v-textarea',{attrs:{"color":"orange","outlined":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.cancel_dialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"loading":_vm.progress,"text":"","color":"red"},on:{"click":_vm.cancel_subscription}},[_vm._v("Yes, cancel Subscription ")])],1)],1)],1)],1):_c('v-alert',{staticClass:"text-center bg-danger-gradient",attrs:{"type":"error"}},[_vm._v(" Your user role is not allowed to view subscription billing information ")]),_c('v-snackbar',{attrs:{"color":"red","timeout":-1},model:{value:(_vm.error_message),callback:function ($$v) {_vm.error_message=$$v},expression:"error_message"}},[_vm._v(" "+_vm._s(_vm.error_msg)+" "),_c('v-btn',{attrs:{"text":"","dark":""},on:{"click":_vm.get_bills}},[_vm._v("Retry")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }