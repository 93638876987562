<template>
    <v-card>
        <v-card-title class="font-weight-light">
            Action required<v-spacer></v-spacer>
            <v-btn color="red lighten-5 red--text" rounded depressed small @click="$emit('closed')">close<v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
            <v-alert
                    type="warning"
                    outlined
                    v-if="instance.invoices.length>0 || instance.sales.length>0"
            >
                <h4 class="font-weight-black">You need to remove these transactions to continue</h4>
                <small>
                    To remove a stock adjustment record, all sales recorded after the adjustment should be removed,
                    this is to ensure that the inventory state of the product is reversed to it's actual initial state during this process.
                </small>
            </v-alert>
            <v-alert v-else type="success" outlined>
                <h3 class="font-weight-light">Great, you can now remove this adjustment record !</h3>
            </v-alert>

            <h3 v-if="instance.invoices.length"
                class="font-weight-black">
              {{instance.invoices.length}} Invoice {{instance.invoices.length>1 ? 's':''}} found</h3>

            <v-list v-if="instance.invoices.length">
                <v-list-item class="border-bottom" v-for="(invoice,index) in instance.invoices" :key="invoice.id" >
                    <v-list-item-icon>
                        <v-icon large color="blue">mdi-file-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title >

                        <v-btn target="_blank" color="blue" text small class="p-0" :to="'/income/invoices/'+invoice.id">

                            {{invoice.fx_currency}}{{invoice.amount_due | toMoney}} - {{invoice.invoice_number}}
                          <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>

                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <small>
                              Billed to: {{invoice.customer.business_name}} | {{invoice.paid_status}}
                          </small>

                        </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn text color="red" :loading="progress" small rounded
                               @click="deleteInvoice(invoice.id,index)">Delete<v-icon small>mdi-delete-outline</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>



            <h3 v-if="instance.sales.length" class="font-weight-black">{{instance.sales.length}} Quick Sale {{instance.sales.length>1 ? 's':''}} found</h3>

            <v-list>
                <v-list-item class="border-bottom" v-for="(sales,index) in instance.sales" :key="sales.id" >
                    <v-list-item-icon>
                        <v-icon large color="green">mdi-receipt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title >

                        <v-btn target="_blank" color="green" text small class="p-0" :to="'/income/sales/'+sales.id">

                            {{sales.amount_due | toMoney | currency_symbol}} - {{sales.sales_number}} <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>

                        </v-list-item-title>

                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn
                                text
                                color="red"
                                :loading="progress"
                                small
                                rounded
                                @click="deleteSale(sales.id,index)"
                        >Delete<v-icon small>mdi-delete-outline</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>

        </v-card-text>
        <v-card-actions>
            <v-btn
                    v-if="instance.invoices.length===0 && instance.sales.length===0"
                    block
                    rounded
                    depressed
                    large
                    dark
                    color="blue darken-4"
                    @click="$emit('done');$emit('closed');"
            >done</v-btn>
        </v-card-actions>
        <v-snackbar color="red" dark v-model="error">{{error_message}}</v-snackbar>
        <v-snackbar  v-model="success">{{success_message}}</v-snackbar>
    </v-card>
</template>

<script>
    export default {
        name: "FoundTransactions",
        props:['instance'],
        data(){
          return{
              error:false,
              error_message:"",
              progress:false,
              success_message:"",
              success:false
          }

        },
        methods:{
            deleteInvoice(id,index){
                this.progress=true;
                axios.delete('/api/deleteinvoice/'+id)
                .then(res=>{
                    this.$emit("invoiceDeleted",index);
                    this.progress=false;
                    this.success_message="Invoice deleted";
                    this.success = true;

                })
                .catch(error=>{
                    // this.error_message = error.response.status===302 ? error.response.data : "Something went wrong, could not delete invoice";
                    // this.error=true;
                    this.progress=false;

                })

            }  ,
            deleteSale(id,index){
                this.progress=true;
                axios.delete('/api/deletesale/'+id)
                .then(res=>{
                    this.$emit("saleDeleted",index);
                    this.progress=false;
                    this.success_message="Quick sale deleted";
                    this.success = true;
                })
                .catch(error=>{
                    // this.error_message = error.response.status===302 ? error.response.data : "Something went wrong, could not delete invoice";
                    // this.error=true;
                    this.progress=false;

                })

            }
        }
    }
</script>

<style scoped>

</style>
