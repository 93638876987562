<template>

  <span>
       <center>
              <h2
                  class="font-weight-light text-center"

              >Create your POS account</h2
              >
              <span class="d-block">Complete the form below to create your account</span>
            </center>

    <v-form class="mt-5" ref="account_form">

      <v-text-field
          outlined
          placeholder="First Name"
          color="orange"
          :rules="$store.state.rules.required"
          v-model="first_name"
      ></v-text-field>
      <v-text-field
          v-model="last_name"
          :rules="$store.state.rules.required"
          outlined
          placeholder="Last Name"
          color="orange"
      ></v-text-field>
      <v-select
          v-model="gender"
          :rules="$store.state.rules.required"
          outlined
          placeholder="Gender"
          :items="['female','male','none']"
          item-color="orange"
          color="orange"></v-select>
      <v-text-field
          type="email"
          v-model="email"
          outlined
          color="orange"
          placeholder="Email"
          :rules="[$store.state.validationRules.required,$store.state.validationRules.email]"
      ></v-text-field>
      <v-text-field
          v-model="password"
          :rules="$store.state.rules.required"
          type="password"
          outlined
          color="orange"
          placeholder="Password"
      ></v-text-field>
      <v-text-field
          v-model="password_confirmation"
          :rules="$store.state.rules.required"
          type="password"
          outlined
          color="orange"
          placeholder="Confirm Password"
      ></v-text-field>
      <phone-number-input
          @input="n=>phone_number=n"
      ></phone-number-input>

      <v-autocomplete
          color="orange"
          outlined
          placeholder="How did you hear about us"
          :items="media"
          item-color="orange"
          item-value="media_name"
          v-model="selected_media"
          item-text="media_name">
      </v-autocomplete>

    </v-form>

    <v-btn :loading="processing" @click="createAccount" block rounded color="orange" dark x-large depressed>Create Account</v-btn>
    <p>By creating an account you agree to our <a class="orange--text" href="https://built.africa/gh/termsOfService">terms and conditions</a></p>

  </span>
</template>

<script>
import {defineComponent} from 'vue'
import PhoneNumberInput from "./PhoneNumberInput.vue";

export default defineComponent({
  name: "SignupComponent",
  components: {PhoneNumberInput},
  data() {
    return {
      media: [],
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      password: "",
      password_confirmation: "",
      selected_media: null,
      loading:false,
      processing:false,
      phone_number:""
    }
  },
  methods: {
    getMedia() {
      axios.get("/api/media")
          .then(res => {
            this.media = res.data
          })
    },
    createAccount() {

      if (this.$refs.account_form.validate()) {

        this.processing=true;

        const data = {
          first_name:this.first_name,
          last_name:this.last_name,
          email:this.email,
          password:this.password,
          password_confirmation:this.password_confirmation,
          gender:this.gender,
          media:this.selected_media,
          phone_number:this.phone_number
        };

        axios.post("/api/auth/v2/signup",data)
            .then(res=>{

              document.cookie = "token=" + res.data.access_token;
              location.reload();
              this.processing=false;
            })
            .catch(()=>{
              this.processing=false;


            })


      }


    }
  },

  mounted() {
    this.getMedia();
  }
})
</script>

<style scoped>

</style>