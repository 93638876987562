<template>
  <v-card-text>
    <upgradeComponent
        v-if="!$store.state.user.current_business.subscription.guards.customer_import"
        class="ma-5"
        upgradeType="items_import"></upgradeComponent>

    <v-stepper v-else v-model="step" style="box-shadow: none">
      <v-stepper-items>
        <v-stepper-content step="1">

          <v-row dense>
            <v-col class="mx-auto" cols="12" sm="6">
              <h6>Make sure your file is structured as shown in the image below or
                <a download
                   href="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/sample_items.xlsx">
                  download sample file
                </a></h6>
              <v-img src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/sample_items.png"></v-img>

              <h2 class="font-weight-black mt-5">Note:</h2>

              <ul>

                <li class="text-h5 font-weight-light pa-2">The name column is strictly required</li>
                <li class="text-h5 font-weight-light pa-2">DO NOT add a column before the first column</li>
                <li class="text-h5 font-weight-light pa-2">DO NOT add empty columns between columns</li>
                <li class="text-h5 font-weight-light pa-2">The file MUST NOT BE PASSWORD PROTECTED</li>
                <li class="text-h5 font-weight-light pa-2">If item type is not determined it will be created as a
                  service
                </li>
                <li class="text-h5 font-weight-light pa-2">If the track inventory property is not set, then the item
                  will be created without inventory tracking
                </li>

              </ul>

              <v-file-input id="file_selector" v-model="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            class="d-none"
                            @change="itemSelected"></v-file-input>
              <v-btn :loading="loading" block color="orange" dark depressed rounded x-large @click="selectFile">
                Select File...
              </v-btn>
            </v-col>
          </v-row>

        </v-stepper-content>
        <v-stepper-content step="2">
          <v-btn class="mt-3" text @click="step=1">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <v-alert color="orange" prominent text type="info">
            <ul>
              <li>If item codes are not provided codes would be generated</li>
              <li>Make sure items codes are unique</li>
              <li>Items with codes that already exists would be skipped</li>
              <li>Items with names that already exists would be skipped</li>
              <li>You can import 200 items at a time</li>
            </ul>

          </v-alert>

          <v-card-title>
            <h3>{{ items.length }} items uploaded</h3>
            <v-spacer></v-spacer>
            <v-btn v-if="selection" rounded outlined color="red" x-large class="mr-2" @click="selection=null">Clear
              Copy
            </v-btn>
            <v-btn @click="showAddCategoryDialog=true" outlined color="orange" rounded x-large>Add Category</v-btn>
          </v-card-title>

          <v-alert v-if="items.length>200" color="red" prominent text type="error">
            <h3 class="font-weight-light">You have exceeded the import limit, you can only import a maximum of 200 items
              at a time.</h3>
          </v-alert>


          <v-form v-if="step==2" ref="itemsForm" class="table-responsive">

            <v-simple-table class="table-bordered">
              <thead>
              <tr class="bg-built white--text" style="color: white !important;">
                <th class="white--text" style="min-width: 250px">Name</th>
                <th class="white--text" style="min-width: 150px">Unit Cost</th>
                <th class="white--text" style="min-width: 150px">Selling Price</th>
                <th class="white--text" style="min-width: 150px">Quantity</th>
                <th class="white--text" style="min-width: 150px">Type</th>
                <th class="white--text" style="min-width: 150px">Track Inventory</th>
                <th class="white--text" style="min-width: 150px">I sell</th>
                <th class="white--text" style="min-width: 150px">I Buy</th>
                <th class="white--text" style="min-width: 150px">Category</th>
                <th class="white--text" style="min-width: 150px">Code or SKU</th>
                <th class="white--text" style="min-width: 150px">Photo</th>
                <th class="white--text text-center" style="min-width: 50px">--</th>
              </tr>
              </thead>

            </v-simple-table>
            <v-simple-table v-if="items && items.length" style="max-height: 80vh!important; overflow: auto">
              <tbody>

              <v-virtual-scroll
                  :bench="30"
                  :items="items"
                  height="70vh"
                  item-height="180"
                  width="114em"
              >
                <template v-slot:default="{ item,index }">

                  <tr :key="index" style="border-bottom:1px solid grey!important;">
                    <td class="pa-3" style="min-width: 250px">
                      <v-text-field
                          color="orange"
                          v-model="item.name"
                          :rules="[rules.required]"
                          class="mt-2" dense flat
                          outlined
                          placeholder="Name"
                      ></v-text-field>

                      <v-textarea
                          v-model="item.description"
                          auto-grow
                          dense
                          flat
                          label="Description"
                          outlined
                          color="orange"
                          rows="2"
                      ></v-textarea>
                    </td>

                    <td class="pa-3" style="min-width: 150px">
                      <v-text-field
                          v-model="item.unit_cost"
                          :prefix="businessCurrency"
                          class="mt-2"
                          dense
                          flat
                          hint="How much you buy it"
                          outlined
                          color="orange"
                          persistent-hint
                          placeholder="Unit Cost"
                          type="number">
                      </v-text-field>
                    </td>

                    <td class="pa-3" style="min-width: 150px">
                      <v-text-field v-model="item.selling_price"
                                    :prefix="businessCurrency"
                                    class="mt-2"
                                    dense
                                    color="orange"
                                    flat
                                    hint="How much you sell it"
                                    outlined
                                    persistent-hint
                                    placeholder="Selling Price"
                                    type="number"></v-text-field>
                    </td>

                    <td class="pa-3" style="min-width: 150px">
                      <input min="0" type="number" class="form-control" v-model="item.quantity">
                      <small class="text--disabled d-block">Quantity</small>

                    </td>
                    <td class="pa-3" style="min-width: 150px">

                      <select v-model="item.type" class="form-control">
                        <template v-if="item.type.toLowerCase()=='product'">
                          <option selected value="Product">
                            Product
                          </option>

                        </template>
                        <template v-else>
                          <option value="Product">
                            Product
                          </option>

                        </template>

                        <option :selected="item.type.toLowerCase()==='service'" value="Service">
                          Service
                        </option>
                        <option :selected="item.type.toLowerCase()==='fixed asset'" value="Fixed asset">
                          Fixed asset
                        </option>

                      </select>

                      <small class="text--disabled d-block">Type of item</small>

                    </td>

                    <td class="pa-3" style="min-width: 150px">

                      <select v-model="item.track_inventory" class="form-control">
                        <option :selected="item.track_inventory==='Yes'" value="Yes">
                          Yes
                        </option>
                        <option :selected="item.track_inventory==='No'" value="No">
                          No
                        </option>

                      </select>
                      <small class="text--disabled d-block">Do you track inventory?</small>
                    </td>

                    <td class="pa-3" style="min-width: 150px">

                      <select v-model="item.selling_item" class="form-control">
                        <option :selected="item.selling_item==='Yes'" value="Yes">
                          Yes
                        </option>
                        <option :selected="item.selling_item==='No'" value="No">
                          No
                        </option>

                      </select>

                      <small class="text--disabled d-block">Do you sell this item?</small>

                    </td>

                    <td class="pa-3" style="min-width: 150px">
                      <select v-model="item.buying_item" class="form-control">
                        <option :selected="item.buying_item==='Yes'" value="Yes">
                          Yes
                        </option>
                        <option :selected="item.buying_item==='No'" value="No">
                          No
                        </option>

                      </select>

                      <small class="text--disabled d-block">Do you buy this item?</small>

                    </td>

                    <td class="pa-3" style="min-width: 150px;"
                        :class="selection && selection.index!=index ? 'orange lighten-5' : ''">

                      <select :disabled="Boolean(selection)" v-model="item.category_id" class="form-control">
                        <option v-for="cat in categories" :key="cat.id" :selected="item.category_id===cat.id"
                                :value="cat.id">
                          {{ cat.name }}
                        </option>


                      </select>

                      <small class="text--disabled d-block">Item category</small>

                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn v-on="on" @click="copyCategory({item:item,index:index})" text x-small color="orange">
                            Copy
                            <v-icon x-small>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Click to copy this category so you can mass-assign it to other items
                        </span>
                      </v-tooltip>

                      <template v-if="selection">

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-btn @click="fillToBottom(index)" :disabled="index==(items.length-1)" v-on="on" text x-small
                                   color="green">Fill Down
                              <v-icon x-small>mdi-arrow-down</v-icon>
                            </v-btn>
                          </template>
                          <span>
                          Replace all the categories from this row and below
                        </span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-btn @click="fillToTop(index)" :disabled="index==0" v-on="on" text x-small
                                   color="blue">Fill Up
                              <v-icon x-small>mdi-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <span>Replace all the categories from this row and above</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-btn @click="pasteCategory(index)" :disabled="index==selection.index" v-on="on" text
                                   x-small
                                   color="indigo">Paste
                              <v-icon x-small>mdi-content-paste</v-icon>
                            </v-btn>
                          </template>
                          <span>Replace only this category with the copied category</span>
                        </v-tooltip>

                      </template>

                    </td>

                    <td class="pa-3" style="min-width: 150px">
                      <input v-model="item.code" class="form-control" type="text">
                      <small class="text--disabled d-block">Unique item code or SKU</small>

                    </td>

                    <td style="min-width: 150px">

                      <photo-selector-component :size="80" :key="index" :index="index" :name="item.name"
                                                directory="item_photos"
                                                style="margin-top: 55px"
                                                @updated="p=>item.photo=p"></photo-selector-component>

                    </td>

                    <td class="pa-3">
                      <v-btn color="red" icon @click="removeItem(index)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </td>

                  </tr>
                </template>
              </v-virtual-scroll>

              </tbody>
            </v-simple-table>
          </v-form>
          <v-row>
            <v-col cols="12" sm="10">

              <v-alert v-if="invalidData" color="red" prominent text>
                <h5>{{ error_message }}</h5>
              </v-alert>
            </v-col>

            <v-col cols="12" sm="2">
              <v-btn
                  :dark="items.length<200"
                  :disabled="items.length>200"
                  :loading="saving"
                  class="mt-2"
                  color="green"
                  depressed
                  rounded
                  x-large

                  @click="saveItems"
              >save items
                <v-icon x-large>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-stepper-content>
        <v-stepper-content class="text-center" step="3">
          <v-row>
            <v-col class="mx-auto" cols="12" sm="4">
              <lottie-animation
                  v-if="step==3"
                  ref="anim"
                  :animationData="require('../assets/success.json')"
                  :autoPlay="true"
                  :loop="false"
                  style="height: 200px"
              />


              <h2 class="text-h3 font-weight-light ma-3">You have successfully imported {{ numberImported }} items into
                your inventory.</h2>
              <p class="pa-3 text--disabled">If this number was unexpected it means that some items were skipped during
                the import process.</p>

              <v-btn block class="mt-5" color="green" dark depressed rounded x-large @click="$emit('done')">
                Okay
              </v-btn>

            </v-col>

          </v-row>


        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>


    <v-snackbar v-model="showSuccess" color="success" dark>{{ success_message }}</v-snackbar>

    <v-dialog width="500" v-model="showAddCategoryDialog" persistent>
      <v-card>
        <v-card-title>Create an item category
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="showAddCategoryDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="catForm">
            <v-text-field
                v-model="categoryName"
                :rules="[$store.state.validationRules.required]" outlined placeholder="Name"
                color="orange"></v-text-field>
            <v-textarea
                v-model="categoryDescription"
                rows="2"
                auto-grow
                outlined
                placeholder="Description"
                color="orange"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="savingCategory" dark block color="orange" @click="saveCategory" rounded x-large depressed>
            Save Category
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card-text>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

import UpgradeComponent from "./upgradeComponent";
import PhotoSelectorComponent from "./PhotoSelectorComponent";

export default {
  name: "ItemsImportComponent",
  components: {PhotoSelectorComponent, UpgradeComponent, LottieAnimation},
  data() {
    return {
      invalidData: false,
      saving: false,
      step: 1,
      file: null,
      loading: false,
      success_message: "",
      showSuccess: false,
      categoryName: "",
      categoryDescription: "",
      savingCategory: false,
      error_message: "Invalid data detected, fix all validation errors to continue",
      items: [],
      numberImported: 0,
      showAddCategoryDialog: false,
      categories: [],
      selection: null,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid'
        },
      }
    }
  },
  computed: {
    businessCurrency() {
      return this.$store.state.user.current_business.currency.code;
    }
  },
  methods: {
    pasteCategory(index) {

      this.items[index].category_id = this.selection.item.category_id;

      this.success_message = "Category pasted";
      this.showSuccess = true;
    },

    fillToTop(index) {
      let count = 0;
      if (this.selection && this.selection.index < index) {
        const min = this.selection.index;

        for (let i = index; i > min; i--) {

          this.items[i].category_id = this.selection.item.category_id;

          count++;

        }


      }

      if (count > 0) {
        this.success_message = count + " categories replaced.";
        this.showSuccess = true;
      }

    },

    fillToBottom(index) {

      let count = 0;

      if (this.selection && index <this.items.length) {

        for (let i = index; i <this.items.length; i++) {

          this.items[i].category_id = this.selection.item.category_id;

          count++;

        }


      }

      if (count > 0) {
        this.success_message = count + " categories replaced.";
        this.showSuccess = true;
      }

    },




    copyCategory(selection) {

      this.selection = selection;

      this.success_message = "Category copied, you can now apply on other rows";
      this.showSuccess = true;

    },

    saveCategory() {

      if (this.$refs.catForm.validate()) {

        this.savingCategory = true;
        const data = {
          name: this.categoryName,
          descriptor: this.categoryDescription
        };

        axios.post("/api/itemcategory", data)
            .then(res => {
              this.savingCategory = false;
              this.getCategories();
              this.showAddCategoryDialog = false;
              this.success_message = "New category saved, you can now assigned it to items before you save";
              this.showSuccess = true;

            })
            .catch(() => {
              this.savingCategory = false;

            })


      }

    },
    saveItems() {

      if (this.$refs.itemsForm.validate()) {
        this.invalidData = false;
        this.invalidData = false;

        this.saving = true;

        axios.post("/api/items/bulk", {items: JSON.stringify(this.items)}, {headers: {'content-type': 'application/json'}})
            .then(res => {

              this.numberImported = res.data.length;

              this.step = 3;

              this.saving = false;

            })
            .catch(error => {

              // this.invalidData = true;
              // this.error_message = "Something wen wrong, try again"

              this.saving = false;

            })


      } else {
        this.invalidData = true;
        this.error_message = "Invalid data detected, fix all validation errors to continue."

      }
    },
    getCategories() {
      axios.get("/api/itemcategories")
          .then(res => {

            this.categories = res.data;

          })
    },
    removeItem(i) {
      this.items.splice(i, 1);
    },
    itemSelected() {

      const formData = new FormData();

      formData.append("items", this.file);
      this.loading = true;
      this.invalidData = false;

      axios.post("/api/onboard/decode/items", formData)
          .then(res => {


            this.items = res.data;

            this.loading = false;
            this.step = 2;
          })
          .catch(error => {

            // this.invalidData = true;
            // this.error_message = "Something wen wrong, try again"

            this.loading = false;

          })

    },
    selectFile() {

      document.getElementById("file_selector").click();

    }
  },
  mounted() {
    this.getCategories();
  }
}
</script>

<style scoped>

.form-control {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  transition: 0.3s ease-in-out !important;
}

select.form-control {

  cursor: pointer !important;

}

.form-control:hover {
  border: 1px solid black

}

.form-control:focus {
  border: 1px solid orange;
  outline: 1px orange !important;

}

</style>