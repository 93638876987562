<template>
  <span>
    <v-row dense>
      <v-col cols="4" class="mr-0 pr-0">
        <v-autocomplete
            outlined
            :items="countries"
            item-text="alpha2Code"
            :item-value="country"
            auto-select-first
            v-model="country"
            color="orange"
            item-color="orange"
            @change="
            $refs['phone-input'].focus();
            $emit('input', prefix + phone_number);
          "
        >
          <template v-slot:prepend-inner>
            <v-img
                width="30px"
                :src="selectedCountry ? selectedCountry.flag : ''"
            ></v-img>
          </template>

          <template v-slot:item="{ item }">
            <flag  class="mr-2" :iso="item.alpha2Code"></flag>
            {{ item.alpha2Code }}({{ item.callingCodes[0] }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="8" class="ml-0 pl-0">
        <v-text-field
            label="Phone number"
            v-model="phone_number"
            @input="$emit('input', prefix + phone_number)"
            ref="phone-input"
            :prefix="prefix"
            outlined
            color="orange"
            type="tel"
            :rules="phone_number_rules"
            persistent-hint
            hint="Enter phone number excluding the leading 0"
        ></v-text-field>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import countries from "./countries.json";

export default {
  name: "PhoneNumberInput",
  props: ["default_number", "isEmailLogin"],
  emits: ["input"],
  data() {
    return {
      countries,
      country: "GH",
      phone_number: "",
      phone_number_rules: [(value) => !!value || "Phone number is Required."],
    };
  },
  watch: {
    default_number() {
      this.phone_number = this.default_number;
      this.$emit("input", this.prefix + this.phone_number);
    },
  },
  computed: {
    selectedCountry() {
      return this.getCountries.find((country) => {
        return country.alpha2Code === this.country;
      });
    },
    getCountries() {
      if (!this.isEmailLogin) {
        return countries.filter(
            (country) =>
                country.name === "Ghana" ||
                country.name === "Nigeria" ||
                country.name === "Kenya" ||
                country.name === "Sierra Leone"
        );
      } else {
        return countries;
      }
    },
    prefix() {
      const p = this.selectedCountry
          ? this.selectedCountry.callingCodes[0]
          : "";
      return p ? "+" + p : "";
    },
  },
  mounted() {
    if (this.default_number) {
      this.phone_number = this.default_number;

      this.$emit("input", this.prefix + this.phone_number);
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    const country = countries.find(country => {

      return country.capital && timeZone.toLowerCase().includes(country.capital.toLowerCase());

    })

    if (country){
      this.country=country.alpha2Code;

    }


  },
};
</script>

<style scoped>
</style>