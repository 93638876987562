<template>
  <v-tooltip
      top
      :color="$vuetify.theme.isDark ? 'black' : 'white'"
      max-width="400"
      class="shadow"
  >

  <template v-slot:activator="{on}">
    <v-btn class="pa-0" v-on="on" text x-small>
      {{label}}
      <v-icon
          class="ma-0"
          x-small
      >mdi-help-circle-outline</v-icon>
    </v-btn>

  </template>
  <span :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'" >
              <strong >{{title}}</strong><br>
             {{description}}
            </span>
  </v-tooltip>

</template>

<script>
export default {
  props:["title","description","label"],
  name: "HelpBubble"
}
</script>

<style scoped>

</style>