<template>
  <v-card flat>
    <v-card-title v-show="step == 1" class="font-weight-light">
      <v-btn @click="$emit('close')" text :disabled="progress">
        <v-icon>mdi-arrow-left</v-icon>
        Back
      </v-btn>
      <v-spacer></v-spacer>
      Charge a customer
    </v-card-title>
    <v-card-text class="text-center" style="padding-top: 100px">
      <div
        class="teal lighten-4 text-center"
        style="
          width: 60px !important;
          height: 60px !important;
          border-radius: 50%;
          margin: auto;
          padding-top: 10px;
        "
      >
        <v-icon x-large color="teal" style="margin: auto"
          >mdi-contactless-payment</v-icon
        >
      </div>

      <h1 class="black--text ma-3">
        {{ step === 1 ? "Begin charge" : "Confirm customer" }}
      </h1>
      <v-stepper v-model="step" class="pa-0" style="box-shadow: none">
        <v-stepper-items class="pa-0" style="box-shadow: none">
          <v-stepper-content step="1" class="pa-0" style="box-shadow: none">
            <v-form ref="charge_form">
              <v-text-field
                type="number"
                autofocus
                outlined
                label="Amount"
                :rules="$store.state.rules.text"
                v-model="amount"
                :disabled="progress"
                :prefix="$store.state.user.current_business.currency.code"
              ></v-text-field>
              <v-select
                :items="networks"
                outlined
                label="Select Network"
                :rules="$store.state.rules.required"
                v-model="network"
                :disabled="progress"
                v-if="business.country.code.toLowerCase() === 'gh'"
              >
              </v-select>
              <v-text-field
                type="tel"
                outlined
                label="Phone Number"
                v-model="phone_number"
                :rules="$store.state.rules.required"
                :disabled="progress"
              ></v-text-field>

              <v-autocomplete
                :rules="$store.state.rules.required"
                v-model="acccount_id"
                v-if="!this.$store.state.account_id"
                outlined
                label="Paid into"
                :items="accounts"
                item-text="account_name"
                item-value="id"
              ></v-autocomplete>
              <v-textarea
                v-model="description"
                rows="2"
                auto-grow
                label="Description"
                outlined
              ></v-textarea>
            </v-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <h2
              v-show="!$store.state.momo.progress"
              v-if="customerInfo"
              class="font-weight-light"
            >
              Do you want to charge
              <span class="font-weight-black">
                {{ customerInfo.account_name }}</span
              >
              on {{ network }} mobile money number {{ phone_number }}<br />
              {{ business.currency.code }}{{ amount | toMoney }} ?
            </h2>

            <p
              class="text-center"
              v-if="$store.state.momo.progress && customerInfo"
            >
              <center>
                <v-progress-circular
                  class="d-block"
                  size="80"
                  color="teal"
                  width="2"
                  indeterminate
                ></v-progress-circular>
              </center>
              waiting for {{ customerInfo.account_name }} to authorize payment
              <v-btn
                block
                class="d-block"
                text
                x-small
                color="red"
                @click="$store.state.momo.progress = false"
                >Cancel
                <v-icon x-small>mdi-close</v-icon>
              </v-btn>
              <small
                >if you cancel, tell {{ customerInfo.account_name }} to cancel
                the authorization too</small
              >
            </p>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions
      v-if="step == 1"
      v-show="business.country.code.toLowerCase() === 'gh'"
    >
      <v-btn
        color="teal"
        x-large
        block
        dark
        rounded
        depressed
        @click="verifyCustomer"
        :loading="progress"
        >Verify customer
        <v-icon x-large>mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-if="step == 1"
      v-show="business.country.code.toLowerCase() === 'ke'"
    >
      <v-btn
        color="teal"
        x-large
        block
        dark
        rounded
        depressed
        @click="verifyCustomer"
        :loading="progress"
        >Continue
        <v-icon x-large>mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions v-if="step == 2">
      <v-btn
        color="error"
        x-large
        block
        dark
        text
        rounded
        depressed
        @click="step = 1"
        :loading="progress"
        :disabled="sendingCharge"
        v-show="!$store.state.momo.progress"
        >No, Cancel charge
        <v-icon x-large>mdi-close</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-actions v-if="step == 2">
      <v-btn
        color="teal"
        x-large
        block
        dark
        rounded
        depressed
        @click="sendChange"
        :loading="sendingCharge"
        v-show="!$store.state.momo.progress"
        >Yes, send charge request
        <v-icon x-large>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>

    <v-snackbar color="error" v-model="error">{{ error_message }}</v-snackbar>

    <v-dialog
      width="500"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
      transition="dialog-bottom-transition"
      v-model="$store.state.showPrint"
      scrollable
    >
      <print-dialog-component></print-dialog-component>
    </v-dialog>
  </v-card>
</template>

<script>
import store from "../store";
import PrintDialogComponent from "./printDialogComponent";

export default {
  name: "ChargeComponent",
  components: { PrintDialogComponent },
  data() {
    return {
      description: "",
      error: false,
      error_message: "",
      charging: false,
      transactionRef: null,
      sendingCharge: false,
      step: 1,
      accounts: null,
      network: "MTN",
      phone_number: "",
      acccount_id: null,
      amount: 0,
      progress: false,
      customerInfo: null,
      wallet: null,
      networks: [
        {
          value: "MTN",
          text: "MTN",
          _code: "MTN",
        },
        {
          value: "VODAFONE",
          text: "VODAFONE",
          _code: "VOD",
        },
      ],
    };
  },
  computed: {
    business() {
      return this.$store.state.user.current_business;
    },
    paystackCode() {
      return this.networks.find((item) => item.value === this.network)._code;
    },
  },
  methods: {
    startListening(ch) {
      this.sockets.subscribe(ch, (e) => {
        console.log(e);

        let message = e;

        if (
          (message.data.code && message.data.code !== "00") ||
          (message.data.status && message.data.status !== "000000")
        ) {
          if (message.data.code) {
            store.state.momo.message =
              message.data.system_msg.length > 0
                ? message.data.system_msg
                : message.data.error_message;

            store.state.momo.failed = true;
            store.state.momo.progress = false;
          } else {
            store.state.momo.message = message.message;
            store.state.momo.failed = true;
            store.state.momo.progress = false;
          }
        } else {
          store.state.printURL =
            "https://webnext.builtaccounting.com/bw/receipt/" +
            message.data.metaData.uuid;
          store.state.showPrint = true;

          store.state.momo.progress = false;
          store.state.momo.complete2 = !store.state.momo.complete2;
          store.state.momo.requestPendding = false;
        }
      });
    },

    getAccounts() {
      window.axios.get("/api/pos/bank-accounts").then((res) => {
        this.accounts = res.data;
      });
    },

    loadWallet() {
      window.axios.get("/api/bw/wallet").then((res) => {
        if (res.data.status) {
          this.wallet = res.data.data;
        } else {
          this.wallet = null;
        }
      });
    },
    sendChange() {
      this.sendingCharge = true;

      const formData = new FormData();
      formData.append("network", this.network);
      formData.append("amount", this.amount);
      formData.append("account_number", this.phone_number);
      formData.append("record_id", this.wallet.id);
      formData.append("record_type", "pos_charge");
      formData.append("account_id", this.acccount_id);
      formData.append("description", this.description);

      window.axios
        .post("/api/nsano/debit", formData)
        .then((res) => {
          const trans = res.data;
          if (this.network.toLowerCase() === "mpesa") {
            if (trans.status === "000001") {
              store.state.momo.progress = true;

              console.log(res.data);

              const ch = "TANDA-MPESA_" + trans.id;
              this.startListening(ch);
            } else {
              this.$store.state.momo.progress = false;
              this.error_message = trans.message;
              this.error = true;
            }
          } else {
            if (trans.code === "00") {
              store.state.momo.progress = true;
              const ch = "debitconfirmation_" + trans.reference;

              this.startListening(ch);
            } else {
              this.$store.state.momo.progress = false;
              this.error_message = trans.msg;
              this.error = true;
            }
          }
          this.sendingCharge = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.sendingCharge = false;
        });
    },
    verifyCustomer() {
      if (this.$refs.charge_form.validate()) {
        if (this.business.country.code.toLowerCase() === "gh") {
          this.progress = true;
          const url =
            "/api/resolveaccount?account_number=" +
            this.phone_number +
            "&bank_code=" +
            this.paystackCode;

          window.axios
            .get(url)
            .then((res) => {
              this.customerInfo = res.data;
              this.progress = false;
              this.step = 2;
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error) => {
              this.progress = false;
            });
        } else {
          this.customerInfo = {
            account_name: "This customer",
          };
          this.step = 2;
        }
      }
    },
  },
  mounted() {
    if (this.business.country.code.toLowerCase() === "ke") {
      this.network = "mpesa";
    }

    this.acccount_id = this.$store.state.account_id;

    this.getAccounts();
    this.loadWallet();
  },
};
</script>

<style scoped></style>
