<template>
    <div>
    <v-card flat>
        <v-card-title>

<v-row>
    <v-col cols="12" sm="4">
        <v-text-field
                rounded
                flat
                filled
                prepend-inner-icon="mdi-magnify"
                placeholder="Search..."
                v-model="search"
                color="orange"
        ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">

        <v-menu
                v-model="Date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        v-model="fromDate"
                        label="From"
                        readonly
                        rounded
                        flat
                        color="orange"

                        filled
                        v-on="on"
               ></v-text-field>
            </template>
            <v-date-picker
                color="orange"
                no-title v-model="fromDate" @input="Date_menu = false"></v-date-picker>
        </v-menu>

    </v-col>

    <v-col cols="12" sm="3">
        <v-menu
                v-model="Date_menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        v-model="toDate"
                        label="To"
                        readonly
                        rounded
                        flat
                        color="orange"

                        filled
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                color="orange"
                no-title v-model="toDate" @input="Date_menu2 = false"></v-date-picker>
        </v-menu>


    </v-col>

    <v-col cols="2">
        <v-btn
            color="orange"
            v-if="!filter_on"
            @click="filter()"
            depressed
            x-large
            dark
            rounded
            :loading="loading"
        >
            Filter <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn
            v-else
            color="red"
            rounded
            dark
            @click="filter_on=false; get_actions();"
        >
            Reset <v-icon>mdi-reload</v-icon>
        </v-btn>
    </v-col>

</v-row>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :loading="loading"
                :items="actions.data"
                :headers="headers"
                :items-per-page=100
                :search="search"
                fixed-header
                height="90vh"
                hide-default-footer
            >
                <template v-slot:item.user.id="{item}">
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-avatar>
                                <v-img :src="item.user.photo ? item.user.photo : '/img/photo.png'"></v-img>
                            </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{item.user.first_name+" "+item.user.last_name}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </template>



              <template v-slot:item.created_at="{item}">
                   {{item.created_at | humanDateTime}}

                </template>

                <template v-slot:item.action="{item}">
                   <span v-html="item.action"></span>
                </template>

            </v-data-table>

        </v-card-text>
        <v-card-actions v-if="!filter_on">

            <v-container>
                <v-row justify="center">
                    <v-col cols="3">
                        <small>Pages {{actions.current_page}} of {{actions.last_page}}</small>
                    </v-col>
                    <v-col cols="1">
                        <v-btn :disabled="actions.prev_page_url == null" @click="prev_page()" icon small><v-icon>mdi-chevron-left</v-icon></v-btn>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                                :loading="loading"
                                :disabled="loading"
                                :items="pages"
                                item-value="value"
                                item-text="text"
                                v-model="actions.current_page"
                                @change="go_Topage(actions.current_page)"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                        <v-btn :disabled="actions.next_page_url == null" @click="next_page()" icon small><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>

    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "auditTrailComponent",
        data(){
            return{
                actions:[],
                loading:false,
                Date_menu:false,
                Date_menu2:false,
                filter_on:false,
                search:'',
                fromDate:"",
                toDate:"",
                headers: [
                    {
                        text: "Action",
                        sortable: true,
                        value: 'action',
                    },
                    {
                        text: "User",
                        sortable: true,
                        value: 'user.id',
                    },
                    { text: "Timestamp", value: 'created_at' },


                ],
            }
        },
        computed:{
            is_admin(){
              return this.$store.state.user.is_admin===1;
            },
          base_url(){
              return this.$store.state.baseURL;
          },
            pages(){
                const p =[];
                for (let i=1; i<=this.actions.last_page;i++){
                    p.push({
                        value:i,
                        text:'Page '+i
                    })
                }
                return p;

            },

        },
        mounted() {
            this.fromDate = moment().format('Y-M-D');
            this.toDate = moment().format('Y-M-D');
            this.get_actions();

        },
        methods:{
            filter(){
                this.loading = true;
                axios.get('/api/useractions/filter/'+this.fromDate+'/'+this.toDate)
                        .then(res=>{
                            this.actions.data = res.data;
                            this.filter_on=true;
                            this.loading = false;
                        });

            },
            go_Topage(page){

                this.loading = true;
                axios.get('/api/useractions?page='+page)
                    .then(res=>{

                        this.actions = res.data;
                       this.loading = false;

                    });

            },
            prev_page(){
                this.loading = true;
                axios.get(this.actions.prev_page_url)
                    .then(res=>{
                        this.actions = res.data;

                        this.loading= false;
                    });

            },
            next_page(){
                this.loading = true;
                axios.get(this.actions.next_page_url)
                    .then(res=>{
                        this.actions = res.data;

                        this.loading= false;
                    });

            },
            get_actions(){
                this.loading= true;
                axios.get('/api/useractions')
                    .then(res=>{
                    this.actions = res.data;
                    this.loading = false;
                    })
                .catch(error=>{

                })
            }
        }
    }
</script>

<style scoped>

</style>