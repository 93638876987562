<template>
  <v-row class="fill-height white">
    <v-col cols="12" sm="6">
      <v-card flat :class="$vuetify.breakpoint.mobile ? '':'pl-15 pr-15 ml-15 mr-15'">
        <v-card-text style="height: 90vh; overflow:auto">

          <h2 class="text-center mb-3 font-weight-light">
            <v-icon color="orange" size="100" class="mx-auto d-block">mdi-storefront-outline</v-icon>
            Create your first business to continue
          </h2>
          <p class="grey--text text-center" style="width: 100%">Provide your business information below to continue</p>

          <v-form ref="saveBusinessForm">
            <v-img src=""></v-img>
            <v-text-field
                color="orange"
                outlined
                placeholder="Business Name"
                v-model="name"
                :rules="[$store.state.validationRules.required]"
            ></v-text-field>

            <phone-number-input @input="n=>phone_number=n"></phone-number-input>

            <v-text-field
                color="orange"
                outlined
                placeholder="Business Email"
                v-model="email"
                :rules="[$store.state.validationRules.required,$store.state.validationRules.email]"

            ></v-text-field>
            <v-textarea
                placeholder="Business address"
                rows="2"
                outlined
                color="orange"
                v-model="address"
                auto-grow
                :rules="[$store.state.validationRules.required]"
            ></v-textarea>
            <v-autocomplete
                color="orange"
                outlined
                placeholder="Country"
                :items="countries"
                item-text="name"
                item-value="id"
                item-color="orange"
                :rules="[$store.state.validationRules.required]"
                v-model="country_id"
            >

              <template v-slot:selection="{item}">
                <v-list-item-icon class="mr-0">
                  <flag :iso="item.code"></flag>
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:item="{item}">
                <v-list-item-icon class="mr-0">
                  <flag :iso="item.code"></flag>
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

            </v-autocomplete>
            <v-autocomplete
                color="orange"
                outlined
                placeholder="Currency"
                :items="currencies"
                item-text="code"
                v-model="currency_id"
                item-color="orange"
                item-value="id"
                :rules="[$store.state.validationRules.required]"
            >
              <template v-slot:selection="{item}">
                <v-list-item-content>

                  <v-list-item-title>
                    {{ item.code }} - {{ item.country }} {{ item.currency }}
                  </v-list-item-title>
                </v-list-item-content>

              </template>

              <template v-slot:item="{item}">
                <v-list-item-content>

                  <v-list-item-title>
                    {{ item.country }} {{ item.currency }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action-text>{{ item.code }}</v-list-item-action-text>

              </template>

            </v-autocomplete>
            <v-autocomplete
                color="orange"
                outlined
                placeholder="Industry"
                :items="industries"
                item-value="id"
                item-text="name"
                v-model="industry_id"
                :rules="[$store.state.validationRules.required]"

            ></v-autocomplete>

            <v-autocomplete
                :items="businessSizes"
                color="orange"
                outlined
                placeholder="Business Size"
                item-color="orange"
                v-model="size"
            ></v-autocomplete>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveBusiness" :loading="processing" color="orange" depressed x-large rounded block dark>Create Business</v-btn>
        </v-card-actions>
      </v-card>

    </v-col>
    <v-col cols="12" sm="6" class="orange white--text text-center" style="height: 100vh; position:relative" v-if="!$vuetify.breakpoint.mobile">

      <div class="vertical-center text-center mx-auto" style="width: 100%">
        <p class="text-h2 mt-10 text-center">Hello {{ $store.state.user.first_name }},</p>
        <p class="text-h4 font-weight-light">Let's setup your business on <br> <img width="100" class="mt-2"
                                                                                    src="https://built.africa/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo2.d9c417ee.png&w=828&q=75"/>
          POS</p>
        <v-btn @click="showLogout=true" color="orange lighten-5" text small>Log Out</v-btn>

      </div>

    </v-col>

    <v-dialog width="500" v-model="showLogout">
      <v-card>
        <v-card-title>Confirm Logout</v-card-title>
        <v-card-text>
          <h1 class="font-weight-light">Do you want to logout?</h1>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loggingOut" @click="logOut" color="red" block rounded depressed x-large dark>Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>

</template>

<script>
import {defineComponent} from 'vue'
import countries from "./countries.json";
import PhoneNumberInput from "./PhoneNumberInput.vue";

export default defineComponent({
  name: "CreateFirstBusinessComponent",
  components: {PhoneNumberInput},
  data() {
    return {
      showLogout: false,
      loggingOut: false,
      countries: [],
      currencies: [],
      industries: [],
      size: "",
      phone_number: "",
      name: "",
      email: "",
      address: "",
      country_id: null,
      currency_id: null,
      industry_id: null,
      processing: false,
      businessSizes: [
        {
          value: "less_than_5_employees",
          text: "Less than 5 employees",
        },
        {
          value: "5_to_10_employees",
          text: "5 to 10 employees",
        },
        {
          value: "10_to_30_employees",
          text: "10 to 30 employees",
        },

        {
          value: "30_to_50_employees",
          text: "30 to 50 employees",
        },
        {
          value: "More_than_50_employees",
          text: "More than 50 employees",
        },
      ],
    }
  },
  computed: {

    userCountry() {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


      const country = countries.find(country => {

        return country.capital && timeZone.toLowerCase().includes(country.capital.toLowerCase());

      });

      if (country) {
        return country;
      }

      return null;

    }

  },
  methods: {
    logOut() {

      this.loggingOut = true;

      axios.get("/api/auth/logout")
          .then(res => {

            this.loggingOut = false;
            window.location.reload();
          })
          .catch(() => {
            this.loggingOut = false;

          })


    },
    saveBusiness() {

      if (this.$refs.saveBusinessForm.validate()) {

        this.processing = true;

        const payload = {
          name: this.name,
          email: this.email,
          address: this.address,
          phone_number: this.phone_number,
          invoice_id: "INV",
          industry_id: this.industry_id,
          country_id: this.country_id,
          currency_id: this.currency_id,
          employee_size: this.size
        };

        axios.post("/api/createbusiness", payload)
            .then(res => {
              window.location.reload();
            })
            .catch(()=>{
              this.processing=false;
            })

      }


    },
    getCountries() {
      axios.get("/api/getcountries")
          .then(res => {

            this.countries = res.data;

            if (this.userCountry) {

              const currentCountry = this.countries.find(country => {

                return country.code.toLowerCase() == this.userCountry.alpha2Code.toLowerCase();

              });

              if (currentCountry) {

                this.country_id = currentCountry.id;

              }

            }


          })
    },
    getCurrencies() {

      axios.get("/api/getcurrencies")
          .then(res => {
            this.currencies = res.data


            const userCountryCurrency = this.currencies.find(currency => {

              return currency.country.toLowerCase() == this.userCountry.name.toLowerCase();

            })


            if (userCountryCurrency) {
              this.currency_id = userCountryCurrency.id;

            }

          })
    },
    getIndustries() {

      axios.get("/api/getindustries")
          .then(res => {
            this.industries = res.data
          })
    },


  },
  mounted() {
    this.getCurrencies();
    this.getIndustries();
    this.getCountries();


  }
})
</script>

<style scoped>
.vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  margin-left: auto;

}

</style>