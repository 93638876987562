<template>
  <v-row dense>
    <v-col cols="12" sm="8" class="mx-auto">
      <v-card  :color="$vuetify.theme.isDark ? 'dark-orange-bg' : 'orange lighten-5'" flat>
        <v-card-text>

          <v-card flat>
            <v-card-title>General presets & permissions</v-card-title>
            <v-card-text>
              <v-alert color="orange" prominent text type="info">
                <strong>Note:</strong>
                <p>These presets will affect all user on the POS, these are supposed to reflect how you want all users to be restricted in your business in general</p>
              </v-alert>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                      v-model="selected_taxes"
                      :disabled="progress"
                      :eager="false"
                      :items="taxes"
                      chips
                      item-text="display_name"
                      item-value="id"
                      label="Taxes"
                      multiple
                      outlined
                      color="orange"
                      item-color="orange"
                  ></v-select>

                </v-col>
                <v-col cols="12" sm="6">
                  <account-dropdown
                      :account-types="['Assets']"
                      :default-account="account_id"
                      :sub-accounts="bankAccounts"
                      label="Payment Account*"
                      @accountChange="acc=>account_id=acc"
                  ></account-dropdown>
                </v-col>

                <v-col cols="12">
                  <v-checkbox color="orange" v-model="can_change_price" label="Users can change unit prices on POS"></v-checkbox>
                  <v-checkbox color="orange" v-model="can_change_taxes" label="Users can change taxes on  POS"></v-checkbox>
                  <v-checkbox color="orange" v-model="can_change_discount" label="Users can change discounts on  POS"></v-checkbox>
                  <v-checkbox color="orange" v-model="can_change_date" label="Users can change transactions date on  POS"></v-checkbox>
                  <v-checkbox color="orange" v-model="can_delete" label="Users can delete transactions on  POS"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn block :loading="progress" color="orange" dark depressed rounded x-large
                         @click="saveSettings">Update</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card-title>
            <h2 class="ml-1 mb-3 orange--text font-weight-black mt-5"><v-icon x-large color="orange">mdi-account-multiple-outline</v-icon>Invited Users
            </h2>
            <v-spacer></v-spacer>

            <v-btn @click="showInviteDialog=true" color="orange" dark rounded depressed x-large>Invite Member</v-btn>
          </v-card-title>

          <v-list-item v-for="invite in invitations" :key="invite.id" :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
           <v-list-item-avatar rounded="xl" color="orange lighten-5">
             <v-icon color="orange">mdi-account-outline</v-icon>
           </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{invite.user_email}}</v-list-item-title>
              <v-list-item-subtitle>Invited At:{{invite.created_at | humanDateTime}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip v-if="invite.confirmed" color="green lighten-5" class="green--text">Accepted</v-chip>
              <v-chip v-else color="orange lighten-5" class="orange--text">Pending</v-chip>
            </v-list-item-action-text>
            <v-list-item-action>
              <v-btn @click="selectedInvite=invite; showDeleteDialog=true;" color="red" text rounded x-large>Delete</v-btn>
            </v-list-item-action>
          </v-list-item>

        </v-card-text>
      </v-card>
    </v-col>


    <v-dialog width="500" v-model="showDeleteDialog" :persistent="deleting">
      <v-card>
        <v-card-title>Confirm delete</v-card-title>
        <v-card-text>
          <h1 class="font-weight-light">Do you want to delete this invite?</h1>
        </v-card-text>
        <v-card-actions><v-btn @click="remove_invite" :loading="deleting" color="red" block dark rounded depressed x-large>Yes,Delete</v-btn></v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInviteDialog" width="500">
      <v-card>
        <v-card-title>Invite User</v-card-title>
        <v-card-text>
          <v-form ref="inviteForm">
            <v-text-field
                outlined
                placeholder="Email"
                v-model="email"
                color="orange"
                :rules="[$store.state.validationRules.required,$store.state.validationRules.email]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="progress" @click="send_invite" block color="orange" rounded x-large dark depressed>Invite user</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="orange" v-model="show_success">
      {{success_msg}}
    </v-snackbar>
    <v-snackbar
        v-model="error_message"
        color="error"
    >

      {{error_msg}}

    </v-snackbar>
  </v-row>
</template>

<script>
import {defineComponent} from 'vue'
import AccountDropdown from "./accountDropdown.vue";

export default defineComponent({
  name: "UsersAndPermissionComponent",
  components: {AccountDropdown},
  data(){
    return{
      success_msg:"",
      show_success:false,
      showInviteDialog:false,
      error_message:false,
      error_msg:'',
      can_change_price: false,
      can_change_discount: false,
      can_change_taxes: false,
      can_delete: false,
      can_change_date: false,
      selected_taxes: [],
      account_id: null,
      progress:false,
      assets:null,
      invitations:[],
      selectedInvite:null,
      showDeleteDialog:false,
      deleting:false,
      email:"",
      roles:[
        {
          id:1,
          name:'Invoices',
          children:[
            { id: 2, name: 'View' },
            { id: 3, name: 'Create' },
            { id: 4, name: 'Edit' },
            { id: 5, name: 'Delete' },
            { id: 6, name: 'Send' },
            { id: 7, name: 'Payments' },
            { id: 8, name: 'Terms' },
            { id: 9, name: 'Download' },

          ]
        },
        {
          id:10,
          name:'Quotes',
          children:[
            { id: 11, name: 'View' },
            { id: 12, name: 'Create' },
            { id: 13, name: 'Edit' },
            { id: 14, name: 'Delete' },
            { id: 15, name: 'Send' },
            { id: 16, name: 'Download' },

          ]
        },
        {
          id:22,
          name:'Other Income',
          children:[

            { id: 23, name: 'View' },
            { id: 24, name: 'Create' },
            { id: 25, name: 'Edit' },
            { id: 26, name: 'Delete' },

          ]
        } ,
        {
          id:27,
          name:'Customers',
          children:[

            { id: 28, name: 'View' },
            { id: 29, name: 'Create' },
            { id: 30, name: 'Edit' },
            { id: 31, name: 'Delete' },

          ]
        } ,
        {
          id:32,
          name:'Products & Services',
          children:[

            { id: 33, name: 'View' },
            { id: 34, name: 'Create' },
            { id: 35, name: 'Edit' },
            { id: 36, name: 'Delete' },

          ]
        },
        {
          id:37,
          name:'Bills',
          children:[

            { id: 38, name: 'View' },
            { id: 39, name: 'Create' },
            { id: 40, name: 'Edit' },
            { id: 41, name: 'Delete' },
            { id: 42, name: 'Download' },

          ]
        },

        {
          id:43,
          name:'Paid Expenses',
          children:[
            { id: 44, name: 'View' },
            { id: 45, name: 'Create' },
            { id: 46, name: 'Edit' },
            { id: 47, name: 'Delete' },
          ]
        },

        {
          id:48,
          name:'Suppliers',
          children:[
            { id: 49, name: 'View' },
            { id: 50, name: 'Create' },
            { id: 51, name: 'Edit' },
            { id: 52, name: 'Delete' },
          ]
        },
        {
          id:53,
          name:'Tax Payments',
          children:[
            { id: 54, name: 'View' },
            { id: 55, name: 'Create' },
            { id: 56, name: 'Edit' },
            { id: 57, name: 'Delete' },
          ]
        },

        {
          id:58,
          name:'Accounts',
          children:[
            { id: 59, name: 'View' },
            { id: 60, name: 'Create' },
            { id: 61, name: 'Edit' },
          ]
        },
        {
          id:62,
          name:'Journal',
          children:[
            { id: 63, name: 'View' },
            { id: 64, name: 'Create' },
            { id: 65, name: 'Edit' },
          ]
        },
        {
          id:66,
          name:'General Ledger',
          children:[
            { id: 67, name: 'View' },

          ]
        },

        {
          id:68,
          name:'Trial Balance',
          children:[
            { id: 69, name: 'View' },

          ]
        },


        {
          id:70,
          name:'Cash Flow',
          children:[
            { id: 71, name: 'View' },

          ]
        },
        {
          id:72,
          name:'Income Statement',
          children:[
            { id: 73, name: 'View' },

          ]
        },
        {
          id:74,
          name:'Balance Sheet',
          children:[
            { id: 75, name: 'View' },

          ]
        },
        {
          id:76,
          name:'Tax Report',
          children:[
            { id: 77, name: 'View' },

          ]
        },

      ],

    }
  },
  computed:{
    bankAccounts() {
      if (!this.assets){
        return [];
      }

      const cash_sub = this.assets.subtypes.filter(account_type => {
        return account_type.name === "Cash & Bank";
      });

      return cash_sub[0].accounts.sort((a, b) => a.account_name.localeCompare(b.account_name)).filter(acc=>{
        return acc.currency== this.$store.state.user.current_business.currency.code || !acc.currency;
      });
    },
    taxes() {
      return this.$store.state.user.current_business.taxes;
    }
  },
  methods:{

    remove_invite(){
      this.deleting = true;
      axios.delete('/api/deleteinvite/'+this.selectedInvite.id)
          .then(res=>{
            this.deleting = false;
            this.get_invitations();
            this.showDeleteDialog=false;

          })
          .catch(()=>{
            this.deleting = false;

          });
    },
    get_invitations(){
      this.progress =true;
      axios.get('/api/getinvitations')
          .then(res=>{
            this.invitations = res.data;
            this.progress=false;
          })
          .catch(err=>{

          });

    },
    getSettings() {
      this.progress = true;
      axios.get("/api/pos/settings")
          .then(res => {
            this.progress = false;
            if (res.data) {
              this.account_id = res.data.account_id ;
              this.can_change_discount = res.data.can_change_discounts ;
              this.can_change_price = res.data.can_change_unit_price;
              this.can_change_taxes = res.data.can_change_taxes;
              this.can_delete = res.data.can_delete;
              this.can_change_date = res.data.change_change_date;
              this.selected_taxes = [];
              res.data.taxes.split(',').forEach(tax => {
                this.selected_taxes.push(Number(tax));
              });

            }
          })

    },
    saveSettings(){

      this.progress=true;

      const data = {
        account_id: this.account_id,
        taxes: this.selected_taxes.toString(),
        change_discounts: this.can_change_discount || false,
        change_taxes: this.can_change_taxes || false,
        change_price: this.can_change_price || false,
        can_delete: this.can_delete || false,
        change_date: this.can_change_date || false,
      };

      axios.post("/api/pos/settings",data)
          .then(res=>{
            this.progress=false;

          })

    },
    getAssetAccountsCategory() {

      axios.get("/api/getassets")
          .then(res => {
            this.assets = res.data;
          })

    },
    invite_exist(email){


      return this.invitations.filter(invite=>{

        return invite.user_email === email;

      })

    },

    update_permissions(id){

      axios.post("/api/updatepermisions",{
        has_dashboard:0,
        has_payroll:0,
        can_use_pos:1

      })

    },
    send_invite(){

      if (this.$refs.inviteForm.validate()){

        if(this.email === this.$store.state.user.email)
        {
          this.error_msg= "The owner of this email is currently the owner of this business, you cannot invite yourself.";
          this.error_message=true;

          return false;
        }
        this.error_message =false;
        if(this.invite_exist(this.email).length > 0){

          this.error_msg= "A user with this email was already invited";
          this.error_message=true;

          return  false;

        }


        this.progress = true;

        const formdata = {
          email: this.email,
          business_id:this.$store.state.user.current_business.id,
          roles: this.roles
        };

        axios.post('/api/inviteuser',formdata)
            .then(res=>{
              this.progress = false;
              this.success_msg="Invitation sent successfully";
              this.show_success =true;
              this.showInviteDialog = false;

              if (res.data && res.data.id){

                this.update_permissions(res.data.id);
              }


              this.get_invitations();

            })
            .catch(err=>{
              // this.error_msg="Something went wrong, could not create invitation ";
              // this.error_message = true;

            });
      }



    },

  },
  mounted() {
    this.getAssetAccountsCategory();
    this.getSettings();
    this.get_invitations();
  }
})
</script>

<style scoped>
.dark-orange-bg {
  background-color: #FF98000D;
}
</style>