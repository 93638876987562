<template>
  <v-row>
    <v-progress-linear indeterminate v-if="loadingOrder" color="orange"></v-progress-linear>
    <v-col cols="12" sm="6">
      <v-card flat class="fill-height">
        <v-card-text v-if="localOrder">
          <iframe :style="fullHeight ? 'height: 90vh' : 'height: 70vh'"
                  style="width: 100%!important; border:0; background-color: white"
                  class="w-100 fill-height"
                  :src="store.state.baseUrl+'/print-order-receipt/'+localOrder.enc_id"></iframe>
        </v-card-text>
      </v-card>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col cols="12" sm="6">
      <v-tabs centered color="orange" v-model="tab">
        <v-tab tab-value="activities">Activities</v-tab>
        <v-tab tab-value="items">Manage Items</v-tab>
        <v-tab tab-value="payments">Payments</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="activities" :style="fullHeight ? 'height: 90vh' : 'height: 70vh'" class="rounded-lg">
          <v-card flat v-if="localOrder">
            <v-card-title>
              Status:
              <v-chip class="ml-2" :class="{
                'blue lighten-5 blue--text':localOrder.state=='pending',
                'cyan lighten-5 cyan--text':localOrder.state=='processing',
                'red lighten-5 red--text':localOrder.state=='cancelled',
                'green lighten-5 green--text':localOrder.state=='completed',
                'purple lighten-5 purple--text':localOrder.state=='confirmed',
                'red lighten-5 red--text':localOrder.state=='failed',
                'orange lighten-5 orange--text':localOrder.state=='returned',

              }">{{ localOrder.state }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn @click="showUpdateDialog=true" x-large rounded depressed color="orange" dark>Change Status</v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="max-height:auto; overflow:auto" v-if="localOrder && localOrder.activities">
              <v-timeline clipped>
                <v-timeline-item right fill-dot small color="orange" v-for="activity in localOrder.activities"
                                 :key="activity.id">
                  <template v-slot:opposite>
                    {{ activity.created_at | humanDateTime }}
                  </template>
                  <v-card :class="{
                'dark-orange':$vuetify.theme.isDark,
                'orange lighten-5':!$vuetify.theme.isDark
              }" flat>
                    <v-card-title>
                      <v-spacer></v-spacer>
                      <v-chip class="ml-2" :class="{
                'blue lighten-5 blue--text':activity.state=='pending',
                'cyan lighten-5 cyan--text':activity.state=='processing',
                'red lighten-5 red--text':activity.state=='cancelled',

                'green lighten-5 green--text':activity.state=='completed',

                'purple lighten-5 purple--text':activity.state=='confirmed',
                'red lighten-5 red--text':activity.state=='failed',
                'orange lighten-5 orange--text':activity.state=='returned',

              }">{{ activity.state }}
                      </v-chip>
                    </v-card-title>
                    <v-card-text>
                      {{ activity.comment }}
                      <p v-if="activity.user" class="font-weight-black">
                        By:
                        <v-icon>mdi-account-circle</v-icon>
                        {{ activity.user.first_name }} {{ activity.user.last_name }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>

            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="items" :style="fullHeight ? 'height: 90vh' : 'height: 70vh'">
          <v-card flat>
            <v-card-title v-if="localOrder && localOrder.state!='completed'">
              <v-spacer></v-spacer>
              <v-btn
                  color="orange"
                  rounded
                  depressed
                  x-large
                  dark
                  @click="showAddItemDialog=true; getSaleItems();"
              >Add Item
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-alert type="info" color="orange" prominent text v-if="localOrder && localOrder.state=='completed'">
                <h4>You cannot remove or add items to this order, it was completed.</h4>
              </v-alert>
              <v-list v-if="localOrder && localOrder.items">
                <v-list-item style="border:1px solid lightgrey" class="rounded-lg mt-2" v-for="item in localOrder.items"
                             :key="item.id">
                  <v-list-item-avatar size="60" rounded="lg">
                    <v-img :src="item.product.photo"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-black">{{ item.product.name }}</v-list-item-title>
                    <v-list-item-subtitle>Quantity:{{ item.quantity }}</v-list-item-subtitle>
                    <v-list-item-subtitle>Amount:{{ item.amount_due | toMoney | currency_symbol }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="localOrder && localOrder.state!='completed'">
                    <v-btn @click="selectedItem=item; showDeleteItemDialog=true;" text color="red" small rounded>Remove
                      <v-icon small>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <no-record-component v-else title="No items" description="This order has not items"></no-record-component>
            </v-card-text>

          </v-card>

        </v-tab-item>
        <v-tab-item value="payments" :style="fullHeight ? 'height: 90vh' : 'height: 70vh'">
          <v-card-title v-if="localOrder && localOrder.state!='completed'">
            <v-spacer></v-spacer>
            <v-btn color="orange"
                   dark
                   rounded
                   depressed
                   x-large
                   @click="showAddPaymentDialog=true"
            >Add Payments
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-alert type="info" color="orange" prominent text v-if="localOrder && localOrder.state=='completed'">
              <h4>You cannot remove or add payments to this order, it was completed.</h4>
            </v-alert>
            <v-list v-if="localOrder && localOrder.payments && localOrder.payments.length>0">

              <v-list-item class="rounded-lg mt-2" style="border:1px solid lightgrey"
                           v-for="payment in localOrder.payments"
                           :key="payment.id">
                <v-list-item-icon>
                  <v-icon color="orange">mdi-bank</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ payment.amount | toMoney | currency_symbol }}</v-list-item-title>
                  <v-list-item-subtitle>Paid to: {{ payment.account.account_name }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="localOrder && localOrder.state!='completed'">
                  <v-btn @click="selectedPayment = payment; showDeletePaymentDialog=true;" text color="red" small
                         rounded>
                    Remove
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <no-record-component v-else title="No payments"
                                 description="This order has no payments"></no-record-component>
          </v-card-text>
        </v-tab-item>

      </v-tabs-items>

      <v-dialog
          :persistent="deletingItem"
          width="500"
          v-model="showDeleteItemDialog"
      >
        <v-card>
          <v-card-title>Confirm delete</v-card-title>
          <v-card-text>
            <h2 class="font-weight-light">Do you want to remove this item from the order?</h2>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="deleteItem" :loading="deletingItem" block color="red" dark x-large depressed rounded>Yes,
              Remove Item
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog :persistent="updating" width="500" v-model="showUpdateDialog">
        <v-card>
          <v-card-title>Update order</v-card-title>
          <v-card-text>
            <v-form ref="updateForm">

              <v-autocomplete
                  :items="orderStatuses"
                  outlined
                  color="orange"
                  label="Order Status"
                  item-color="orange"
                  item-text="title"
                  item-value="id"
                  v-model="orderState"
                  :rules="[store.state.validationRules.required]"
              >
                <template v-slot:item="{item}">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>

              <v-textarea
                  v-model="comment"
                  label="Comment"
                  color="orange"
                  outlined
                  placeholder="Add some context to this update"
                  rows="3"
                  auto-grow></v-textarea>

              <v-alert color="error" text prominent type="error"
                       v-if="orderState=='completed' && localOrder.payments.length==0">
                <h4>This order has no payment, to complete the order, add payments.</h4>
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
                @click="updateOrder"
                block
                color="orange"
                dark
                depressed
                rounded
                x-large
                :loading="updating"
            >Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog :persistent="addingItem" scrollable v-model="showAddItemDialog" width="500">
        <v-card>
          <v-card-title>
            <v-text-field v-if="addItemStep==1" color="orange" v-model="search" clearable rounded filled flat
                          placeholder="Search..."></v-text-field>
            <span v-else-if="selectedItem">
           <v-btn rounded small text class="mr-3" color="orange" @click="addItemStep=1"><v-icon
               small>mdi-arrow-left</v-icon> Back</v-btn> Add {{ selectedItem.name }}
          </span>
          </v-card-title>
          <v-card-text>
            <v-stepper elevation="0" v-model="addItemStep">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-skeleton-loader type="list-item@10" v-if="loadingItems"></v-skeleton-loader>
                  <v-list v-else>
                    <v-list-item class="rounded-lg"
                                 :disabled="item.track_inventory && item.quantity<=0"
                                 @click="setSelectedItem(item); addItemStep=2"
                                 v-for="item in filteredSalesItems"
                                 :key="item.id">
                      <v-list-item-avatar size="60" rounded="lg">
                        <v-img :src="item.photo"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>Current QTY:{{ item.quantity }}</v-list-item-subtitle>
                        <v-list-item-subtitle>Type:{{ item.type }}</v-list-item-subtitle>
                        <v-list-item-subtitle>Track Inventory:{{
                            item.track_inventory ? "Yes" : "No"
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.track_inventory && item.quantity<=0" class="red--text">Out of
                          stock
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon color="grey" small>mdi-chevron-right</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-stepper-content>
                <v-stepper-content step="2">

                  <p v-if="selectedItem" class="text-center">
                    <v-avatar rounded="lg" size="70" class="d-block mx-auto">
                      <v-img :src="selectedItem.photo"></v-img>
                    </v-avatar>
                    {{ selectedItem.name }}
                  </p>
                  <v-form v-if="selectedItem" ref="itemForm">

                    <h4>Amount:{{ selectedItem.amount | toMoney | currency_symbol }}</h4>
                    <h4>Discount:{{ selectedItem.discount | toMoney | currency_symbol }}</h4>
                    <h4>Net Amount:{{ selectedItem.net_amount | toMoney | currency_symbol }}</h4>
                    <h4>Tax Amount:{{ selectedItem.tax_amount | toMoney | currency_symbol }}</h4>
                    <h3 class=" mb-3">Amount Due:{{ selectedItem.amount_due | toMoney | currency_symbol }}</h3>
                    <v-text-field
                        label="QTY"
                        @input="applyChanges()"
                        color="orange"
                        v-model="selectedItem.quantity"
                        dense
                        outlined
                        placeholder="Quantity"
                        type="number"></v-text-field>

                    <v-text-field
                        @input="applyChanges()"
                        color="orange"
                        v-model="selectedItem.unit_price"
                        :prefix="$store.state.user.current_business.currency.code"
                        dense
                        outlined
                        placeholder="Price"
                        type="number"
                        label="Price"
                    ></v-text-field>

                    <v-text-field color="orange"
                                  v-model="selectedItem.discount"
                                  :prefix="$store.state.user.current_business.currency.code"
                                  dense
                                  outlined
                                  @input="applyChanges()"
                                  placeholder="Discount"
                                  label="Discount"
                                  type="number"></v-text-field>
                    <v-autocomplete
                        deletable-chips
                        item-color="orange"
                        item-text="display_name"
                        item-value="id"
                        :items="businessTaxes"
                        v-model="selectedItem.applied_taxes"
                        multiple
                        outlined
                        chips
                        dense
                        color="orange"
                        @change="applyChanges()"
                    ></v-autocomplete>

                    <v-textarea v-model="selectedItem.description" dense rows="1" color="orange" outlined
                                auto-grow
                                placeholder="Description"></v-textarea>

                  </v-form>

                  <v-btn :loading="addingItem" @click="addItem" rounded x-large depressed dark color="orange" block>Add
                    Item
                  </v-btn>

                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-dialog width="500" v-model="showDeletePaymentDialog" :persistent="deletingPayment">
      <v-card>
        <v-card-title>Confirm delete</v-card-title>
        <v-card-text>
          <h2 class="font-weight-light">Do you want to remove this payment?</h2>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deletePayment" :loading="deletingPayment" block color="error" dark rounded depressed x-large>
            Yes, Remove Payment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        width="500"
        v-model="showAddPaymentDialog"
    >
      <v-card>
        <v-card-title>Add payments to order</v-card-title>
        <v-card-text v-if="localOrder && localOrder.items.length">
          <span v-if="amount_due>0">
          <h2 class="text-center">Total Amount:{{ localOrder.amount_due | toMoney | currency_symbol }}</h2>
          <h3 class="text-center" v-if="amount_due">Amount Due:{{ amount_due | toMoney | currency_symbol }}</h3>
          <p class="text-center">Record Payments for this order</p>
          <p class="text-center red--text" v-if="payments.length && totalPayments!==amount_due">Sum of your
            payments is not the same
            as the amount due
            <v-btn text color="green" small @click="setSettingsAccount">Reset</v-btn>
          </p>
          </span>

          <v-alert color="orange" prominent text type="warning" v-else>
            <h4>This order was fully paid, to add new payments remove or replace some payments</h4>
          </v-alert>

          <v-form ref="addPaymentForm" v-if="amount_due>0">

            <v-simple-table>
              <tr v-for="(payment,index) in payments" :key="index">
                <td>
                  <v-autocomplete :rules="[store.state.validationRules.required]"
                                  item-color="orange"
                                  color="orange"
                                  class="mt-2"
                                  item-value="id"
                                  label="Account"
                                  dense
                                  item-text="account_name"
                                  outlined
                                  :items="accounts"
                                  v-model="payment.account_id"></v-autocomplete>
                </td>
                <td>

                  <v-text-field :rules="[store.state.validationRules.required]"
                                :prefix="store.state.user.current_business.currency.code"
                                color="orange"
                                class="mt-2"
                                outlined
                                type="number"
                                dense
                                label="Amount"
                                v-model="payment.amount"></v-text-field>
                </td>
                <td>
                  <v-btn @click="removePayment(index)" icon small color="red">
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>

                </td>
              </tr>
            </v-simple-table>

          </v-form>

          <v-btn :disabled="payments.length>2 || addingPayments"
                 block
                 v-if="amount_due>0"
                 text
                 small
                 color="orange"
                 class="mt-3"
                 @click="addPaymentMethod">Add payment method
          </v-btn>
        </v-card-text>
        <v-card-actions v-if="amount_due>0">
          <v-btn
              block
              color="orange"
              x-large
              rounded
              depressed
              dark
              @click="savePayments"
              :loading="addingPayments"
          >Save Payments
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="error" v-model="show_error">{{ error_message }}</v-snackbar>
  </v-row>
</template>

<script>
import {defineComponent} from 'vue'
import store from "../store";
import NoRecordComponent from "./noRecordComponent.vue";

export default defineComponent({
  components: {NoRecordComponent},
  computed: {
    settings() {
      return this.$store.state.settings;
    },
    store() {
      return store
    },
    orderID() {
      return this.$route.query.order_id;
    },
    filteredSalesItems() {
      if (!this.search) {
        return this.saleItems;
      }

      return this.saleItems.filter(item => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    businessTaxes() {
      return this.$store.state.user.current_business.taxes;
    },
    totalPayments() {
      let sum = 0;
      this.payments.forEach(payment => {
        sum += Number(payment.amount);
      })

      return sum;
    },
    total_amount_paid() {

      return this.localOrder.payments_sum_amount;
    },
    amount_due() {


      if (!this.localOrder) {
        return 0;
      }

      return this.localOrder.amount_due - this.total_amount_paid;

    }
  },
  props: {
    order: {
      type: Object,
      default: null
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  name: "OrderPreviewComponent",
  data() {
    return {
      payments: [],
      accounts: [],
      showAddPaymentDialog: false,
      selectedPayment: null,
      showDeletePaymentDialog: false,
      deletingPayment: false,
      orderStatuses: [
        {
          title: "Confirmed",
          id: "confirmed",
          description: "Acknowledge receipt of the order"
        },
        {
          title: "Processing",
          id: "processing",
          description: "Acknowledge processing of the order"
        },
        {
          title: "Completed",
          id: "completed",
          description: "Acknowledge Completion of the order, a sale will be created"
        },
        {
          title: "Cancelled",
          id: "cancelled",
          description: "Canceled the order, order will be disabled"
        },
        {
          title: "Failed",
          id: "failed",
          description: "Indicate that the order failed"
        },
        {
          title: "Returned",
          id: "returned",
          description: "Return the order, sale will be returned"
        },

      ],
      showDeleteItemDialog: false,
      localOrder: null,
      tab: "activities",
      showUpdateDialog: false,
      orderState: "",
      comment: "",
      updating: false,
      loadingOrder: false,
      deletingItem: false,
      saleItems: [],
      loadingItems: false,
      showAddItemDialog: false,
      addItemStep: 1,
      search: "",
      selectedItem: null,
      addingItem: false,
      show_error: false,
      error_message: "",
      addingPayments: false
    }
  },
  watch: {
    order() {
      this.localOrder = this.order;
      this.orderState = this.order.state;
    },
    orderID() {
      this.getOrder();
    }
  },
  methods: {
    savePayments() {

      if (this.$refs.addPaymentForm.validate()) {

        if (this.totalPayments !== this.amount_due) {

          this.error_message = "Sum of your payments is not the same as the amount due";
          this.show_error = true;
          return;
        }

        this.addingPayments = true;

        const URL = "/api/pos/orders-add-payments";
        axios.post(URL, {payments: this.payments, order_id: this.localOrder.id})
            .then(res => {
              this.addingPayments = false;
              this.showAddPaymentDialog = false;
              this.payments = [];
              this.getOrder();

            })
            .catch(() => {
              this.addingPayments = false;
            })

      }


    },
    addPaymentMethod() {

      if (this.payments.length < 1) {
        this.payments.push({
          account_id: null,
          amount: this.totalAmountDue,
        });

      } else {
        this.payments.push({
          account_id: null,
          amount: 0,
        });
      }

    },
    getAccounts() {
      axios.get("/api/pos/bank-accounts").then((res) => {
        this.accounts = res.data;
      });
    },
    deletePayment() {
      this.deletingPayment = true;

      const URL = "/api/pos/orders-remove-payment/" + this.selectedPayment.id;
      axios.delete(URL)
          .then(res => {
            this.deletingPayment = false;
            this.showDeletePaymentDialog = false;
            this.getOrder();
          })
          .catch(() => {
            this.deletingPayment = false;

          })

    },
    setSettingsAccount() {
      this.payments = [{
        account_id: this.settings.account_id,
        amount: this.totalAmountDue
      }];
    },
    addItem() {

      if (this.$refs.itemForm.validate()) {

        this.addingItem = true;
        const data = this.selectedItem;

        const URL = "/api/pos/orders/" + this.localOrder.uuid + "/add-item";
        axios.post(URL, data)
            .then(res => {
              this.addingItem = false;
              this.showAddItemDialog = false;
              this.getOrder();

            })
            .catch(() => {
              this.addingItem = false;
            })


      }
    },
    applyChanges() {

      this.selectedItem.amount = this.selectedItem.unit_price * this.selectedItem.quantity;

      const netAmount = this.selectedItem.amount - this.selectedItem.discount;

      this.selectedItem.net_amount = netAmount;

      const businessTaxes = this.businessTaxes.filter(t => this.selectedItem.applied_taxes && this.selectedItem.applied_taxes.includes(t.id));

      const taxAmount = this.get_tax_amount(businessTaxes, netAmount);

      this.selectedItem.tax_amount = taxAmount;
      this.selectedItem.amount_due = netAmount - taxAmount;

    },


    get_tax_amount(taxes, amount) {
      let sum = 0;

      if (taxes.length) {

        const net_amount = amount;

        taxes.forEach(tax => {

          if (tax.type.toLowerCase() === "flat") {
            sum += (Number(tax.rate) / 100) * Number(net_amount);
          } else {
            const subTax =
                tax.sub_rate > 0
                    ? (Number(tax.sub_rate) / 100) * Number(net_amount)
                    : 0;

            const compoundAmount = net_amount + Number(subTax);

            const totaltax = Number(tax.rate / 100) * Number(compoundAmount);

            sum += Number(subTax) + Number(totaltax);
          }
        });

      }


      return sum;

    },
    setSelectedItem(item) {

      this.selectedItem = {
        id: item.id,
        product_id: item.id,
        name: item.name,
        quantity: 1,
        unit_price: item.selling_price,
        amount: item.selling_price,
        photo: item.photo,
        discount: 0,
        tax_amount: 0,
        net_amount: item.selling_price,
        amount_due: item.selling_price,
        applied_taxes: item.applied_taxes,
        description: item.description

      };

    },

    getSaleItems() {
      this.loadingItems = true;

      axios.get("/api/getinvoiceitems")
          .then(res => {
            this.saleItems = res.data;
            this.loadingItems = false;

          })
    },

    deleteItem() {
      this.deletingItem = true;

      const URL = "/api/pos/orders/" + this.selectedItem.id + "/remove-item";
      axios.delete(URL)
          .then(res => {

            this.getOrder();
            this.deletingItem = false;
            this.showDeleteItemDialog = false;

          })
          .catch(() => {
            this.deletingItem = false;

          })

    },

    removePayment(index) {

      this.payments.splice(index, 1);

    },
    getActivites() {

      const URL = "/api/pos/order-activities/" + this.orderID;
      axios.get(URL)
          .then(res => {
            this.localOrder.activities = res.data;
          })

    },
    getOrder() {
      this.loadingOrder = true;
      const URL = "/api/pos/orders/" + this.orderID;
      axios.get(URL)
          .then(res => {
            this.localOrder = res.data;
            this.loadingOrder = false;
          })
    },

    updateOrder() {

      if (this.$refs.updateForm.validate()) {

        const data = {
          state: this.orderState,
          comment: this.comment
        };


        if (this.localOrder.payments.length == 0 && this.orderState == "completed") {

          this.error_message = "This order has no payment, to complete the order, add payments.";
          this.show_error = true;
          return;

        }
        this.updating = true;


        const URL = "/api/pos/orders/" + this.localOrder.uuid + "/change-state";
        axios.post(URL, data)
            .then(res => {

              this.updating = false;
              this.showUpdateDialog = false;

              this.getActivites();

            })
            .catch(() => {
              this.updating = false;

            })

      }

    }
  },
  mounted() {
    if (this.order) {
      this.localOrder = this.order;
      this.orderState = this.order.state;

    } else {

      if (this.orderID) {
        this.getOrder();

      }
    }

    this.getAccounts();

  }
})
</script>


<style>
.dark-orange {
  background-color: #FF98000D;
}

</style>