<template>
<span>
  <v-row>
    <v-col cols="12" sm="3">
      <v-skeleton-loader type="card"></v-skeleton-loader>
      <v-skeleton-loader type="list-item@10"></v-skeleton-loader>

    </v-col>
    <v-col cols="12" sm="9">
      <v-row>

        <v-col cols="12" sm="4">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="4">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="4">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      <v-col cols="4">
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </v-col>
        <v-col cols="4">
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </v-col>
        <v-col cols="12">
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>

      </v-row>

    </v-col>
  </v-row>
</span>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ContactDetailLoadingSekeletonComponent"
})
</script>

<style scoped>

</style>