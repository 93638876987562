<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-btn rounded depressed color="orange" small text to="/inventory">
          <v-icon small>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <h4 class="font-weight-light">Preview Product/Service</h4>
      </v-card-title>
      <div class="pa-4 d-flex justify-space-between align-center">
        <v-row>
          <v-col cols="12" sm="3">
            <v-menu
                ref="fromMenu"
                v-model="fromMenu"
                :close-on-content-click="false"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="fromDate"
                    label="From"
                    filled
                    flat
                    rounded
                    color="orange"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="fromDate"
                  no-title
                  @input="fromMenu = false"
                  color="orange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
                ref="toMenu"
                v-model="toMenu"
                :close-on-content-click="false"
                max-width="290px"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="toDate"
                    label="To"
                    filled
                    flat
                    color="orange"
                    rounded
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="toDate"
                  no-title
                  color="orange"
                  @input="toMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn
                v-if="!filterApplied"
                color="orange"
                dark
                depressed
                x-large
                rounded
                @click="filterTransaction"
            >Apply Filter
            </v-btn>
            <v-btn
                v-else
                color="red"
                dark
                depressed
                large
                @click="getTransactions"
            >Clear Filter
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
                v-if="tab === 'tab-purchase'"
                v-model="purchaseSearch"
                placeholder="Search Transactions"
                filled
                flat
                rounded
                color="orange"
                append-icon="mdi-magnify"
            ></v-text-field>
            <v-text-field
                v-else-if="tab === 'tab-sale'"
                v-model="saleSearch"
                placeholder="Search Transactions"
                filled
                flat
                rounded
                color="orange"
                append-icon="mdi-magnify"
            ></v-text-field>
            <v-text-field
                v-else
                v-model="detailSearch"
                placeholder="Search Transactions"
                filled
                flat
                rounded
                color="orange"
                append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-card-text class="pt-0 mt-0">
        <contact-detail-loading-sekeleton-component v-if="progress"></contact-detail-loading-sekeleton-component>
        <v-row v-if="item">
          <v-col cols="12" sm="3">
            <div class="text-center">
              <v-avatar class="d-block ma-auto" rounded="lg" width="100%" height="auto">
                <v-img :src="imgUrl" alt="product image"/>
              </v-avatar>

              <v-btn
                  v-if="CAN_EDIT"
                  color="orange"
                  small
                  text
                  @click="
                  selectedItem = item.item;
                  editing = true;
                "
              >Edit
              </v-btn>
              <v-btn
                  v-if="item.item.track_inventory && CAN_EDIT"
                  color="orange"
                  small
                  text
                  @click="adjust_dialog = true"
              >Adjust Stock
              </v-btn>

              <v-btn
                  v-if="CAN_DELETE"
                  color="red"
                  small
                  text
                  @click="delete_dialog = true"
              >Delete
              </v-btn>
            </div>

            <v-alert v-if="quantytLimit" prominent text type="warning">
              This item has reached it's reorder quantity, consider restocking it before your next sale.
            </v-alert>
            <div>
              <p class="font-weight-light mt-3 text-h3">
                {{ item.item.name }}
              </p>

              <p class="text-light">Created At</p>
              <p class="font-weight-black"> {{ item.item.created_at | humanDateTime }}</p>
            </div>
            <div>
              <p class="text-light">Code</p>
              <p class="font-weight-black">
                {{ item.item.code }}
              </p>
            </div>

            <div>
              <p class="text-light">Description</p>
              <p class="font-weight-black">
                {{ item.item.description }}
              </p>
            </div>
            <div>
              <p class="text-light">Type</p>
              <p class="font-weight-black">
                {{ item.item.type }}
              </p>
            </div>

            <div>
              <p class="text-light">Unit Cost</p>
              <p class="font-weight-black">
                {{ item.item.unit_cost | toMoney | currency_symbol }}
              </p>
            </div>

            <div>
              <p class="text-light">Selling Price</p>
              <p class="font-weight-black">
                {{ item.item.selling_price | currency_symbol }}
              </p>
            </div>

            <div>
              <p class="text-light">Computed cost per stock</p>
              <p class="font-weight-black">
                {{ item.item.real_unitPrice | toMoney | currency_symbol }}
              </p>
            </div>

            <div v-if="item.item.track_inventory == 1">
              <p class="text-light">Inventory Balance</p>
              <p class="font-weight-black">
                {{ item.item.balance | currency_symbol }}
              </p>
            </div>
            <div>
              <p class="text-light">Current Quantity</p>
              <p class="font-weight-black">
                {{ item.item.quantity }}
                <v-btn
                    v-if="item.item.track_inventory && CAN_EDIT"
                    color="orange"
                    dark
                    depressed
                    rounded
                    small
                    text
                    @click="adjust_dialog = true"
                >Adjust
                  <v-icon>mdi-tune-vertical</v-icon>
                </v-btn>
              </p>
            </div>

            <div>
              <p class="text-light">Quantity Limit</p>
              <p class="font-weight-black">
                {{ item.item.quantity_limit }}
              </p>
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="9" style="max-height: 90vh; overflow: auto">
            <div
                style="border-radius: 15px"
                :class="
                $vuetify.theme.isDark ? 'dark-orange pa-2' : 'orange lighten-5 pa-3'
              "
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <h3
                      v-if="filterApplied"
                      class="orange--text  font-weight-bolder"
                  >
                    {{ fromDate }} - {{ toDate }}
                  </h3>
                  <h3 v-else class="orange--text font-weight-bolder">
                    All Transactions
                  </h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-card :loading="isLoading"  flat>
                    <template v-slot:progress>
                      <v-progress-linear color="orange" indeterminate></v-progress-linear>
                    </template>
                    <v-card-text>
                      <p class="font-weight-light text-primary mb-2">
                        Total Purchases
                      </p>
                      <h3 class="font-weight-bold">
                        {{ item.total_purchases | toMoney | currency_symbol }}
                        <small class="d-block"
                        >QTY. Purchased: {{ totalPurchases }}</small
                        >
                      </h3>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-card :loading="isLoading" flat>
                    <template v-slot:progress>
                      <v-progress-linear color="orange" indeterminate></v-progress-linear>
                    </template>
                    <v-card-text>
                      <p class="text-primary mb-2">Total Sales</p>
                      <h3 class="font-weight-bold">
                        {{ item.total_sales | toMoney | currency_symbol }}
                        <small class="d-block"
                        >QTY. Sold: {{ totalSales }}</small
                        >
                      </h3>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="4">
                  <v-card :loading="isLoading" flat height="105">
                    <template v-slot:progress>
                      <v-progress-linear color="orange" indeterminate></v-progress-linear>
                    </template>
                    <v-card-text>
                      <p class="text-primary">Current Quantity</p>
                      <h3 class="font-weight-bold">
                        {{ item.item.quantity }}
                      </h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-tabs color="orange" v-model="tab" centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-sale"> Sales</v-tab>
              <v-tab
                  v-if="item.item.track_inventory"
                  href="#tab-adjustments"
                  @click="$store.state.totalBalance = item.item.balance"
              >
                Adjustment History
              </v-tab>

              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-sale">
                  <v-skeleton-loader
                      v-if="isLoading"
                      type="table"
                  ></v-skeleton-loader>

                  <v-data-table
                      v-else
                      :headers="headers"
                      :items="item.transactions.sales"
                      :items-per-page="20"
                      :search="saleSearch"
                      fixed-header
                      height="80vh"
                  >
                    <template v-slot:item.route="{ item }">
                      <v-btn
                          v-if="item.route=='/invoices/'"
                          text
                      >
                        {{ item.document_number }}
                      </v-btn>
                      <v-btn
                          text
                          color="orange"
                          :to="'/transactions/'+item.sale_id"
                          v-else
                      >
                        {{ item.document_number }}

                      </v-btn>
                    </template>
                    <template v-slot:item.amount="{ item }">
                      {{ item.fx_currency }} {{ item.amount | toMoney }}
                      <br v-if="item.rate != 1"/>
                      <small v-if="item.rate != 1" class="text--disabled"
                      >{{
                          item.base_currency
                        }}{{ item.fx_amount | toMoney }}</small
                      >
                    </template>

                    <template v-slot:item.unit_price="{ item }">
                      {{ item.fx_currency }} {{ item.unit_price | toMoney }}
                    </template>

                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at }}
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item
                    v-if="item.item.track_inventory"
                    value="tab-adjustments"
                >
                  <adjustments-component
                      :itemID="item.item.id"
                      @changed="getItem"
                  ></adjustments-component>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="hasError" color="error">
      {{ errorMsg }}
    </v-snackbar>
    <v-snackbar v-model="isSuccess">
      {{ successMsg }}
    </v-snackbar>


    <filter-dialog
        :routeId="routeId"
        :showDialog="showFilterDialog"
        :showExportType="!canFilterTransaction"
        type="customer"
        @cancel="resetDialog"
        @filter="handleOnFilter"
    ></filter-dialog>

    <v-dialog v-model="editing" width="500">
      <NewItemComponent
          :editing="editing"
          :item="selectedItem"
          @ItemCreated="item_edited"
          @closed="itemDialogClose"
      ></NewItemComponent>
    </v-dialog>

    <v-dialog
        v-model="adjust_dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        persistent
        scrollable
        width="600"
    >
      <adjust-component
          v-if="item"
          :item="item.item"
          @closed="adjust_dialog = false"
          @saved="adjusted"
      ></adjust-component>
    </v-dialog>

    <v-dialog v-model="delete_dialog" width="400">
      <v-card>
        <v-card-title class="font-weight-light">
          Comfirm Delete
        </v-card-title>
        <v-card-text>
          <p>Do you want to delete this item?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :disabled="deleting"
              :loading="deleting"
              block
              color="red"
              dark
              depressed
              large
              rounded
              @click="deleteItem"
          >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import AdjustComponent from "../components/AdjustComponent";
import NewItemComponent from "../components/NewItemComponent";
import AdjustmentsComponent from "../components/adjustmentsComponent";
import ContactDetailLoadingSekeletonComponent from "../components/ContactDetailLoadingSekeletonComponent.vue";

const DetailTransaction = () => import("../components/detailTransaction.vue");
const FilterDialog = () => import("../components/filterDialog");
export default {
  props: ["id"],
  components: {
    ContactDetailLoadingSekeletonComponent,
    AdjustmentsComponent,
    NewItemComponent,
    AdjustComponent,
    FilterDialog,
    DetailTransaction,
  },
  data() {
    return {
      selectedItem: null,
      filterApplied: false,
      fromMenu: false,
      toMenu: false,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      deleting: false,
      delete_dialog: false,
      editing: false,
      adjust_dialog: false,
      isLoading: false,
      progress: false,
      hasError: false,
      isSuccess: false,
      showFilterDialog: false,
      canFilterTransaction: false,
      item: null,

      search: "",
      saleSearch: "",
      purchaseSearch: "",
      detailSearch: "",
      errorMsg: "",
      successMsg: "",
      defaultImg: "/img/item_photo.png",
      tab: "tab-purchase",

      headers: [
        {
          text: "Operation",
          align: "center",
          value: "route",
        },
        {
          text: "Unit Price",
          value: "unit_price",
          align: "right",
        },

        {
          text: "Quantity",
          value: "quantity",
          align: "center",
        },

        {
          text: "Amount",
          value: "amount",
          align: "right",
        },

        {
          text: "Date",
          value: "issue_date",
          align: "center",
        },
        {
          text: "Created At",
          value: "created_at",
          align: "center",
        },
      ],
    };
  },
  computed: {
    totalSales() {
      let sum = 0;

      this.item.transactions.sales.forEach((sales) => {
        sum += sales.quantity;
      });

      return sum;
    },
    totalPurchases() {
      let sum = 0;
      this.item.transactions.purchase.forEach((purchase) => {
        sum += purchase.quantity;
      });

      return sum;
    },
    quantytLimit() {
      return (
          this.item &&
          this.item.item.quantity_limit >= this.item.item.quantity &&
          this.item.item.track_inventory === 1
      );
    },

    CAN_EDIT() {
      if (this.$store.state.user.is_admin === 1) {
        return true;
      } else
        return this.$store.state.user.components[5].roles.edit === 1;
    },
    CAN_DELETE() {
      if (this.$store.state.user.is_admin === 1) {
        return true;
      } else
        return (
            this.$store.state.user.components[5].roles.delete === 1
        );
    },
    CAN_ADD() {
      if (this.$store.state.user.is_admin === 1) {
        return true;
      } else
        return (
            this.$store.state.user.components[5].roles.create === 1
        );
    },
    itemDateCreated() {
      return this.item
          ? moment(this.item.item.created_at).format("MMMM, DD YYYY")
          : "";
    },
    routeId() {
      return this.$route.params.id;
    },
    baseUrl() {
      return this.$store.state.baseURL;
    },
    imgUrl() {
      return this.item.item.photo;
    },
  },
  watch: {
    routeId() {
      this.getItem();
    },
  },
  methods: {
    deleteItem() {
      this.deleting = true;
      axios
          .delete("/api/deleteitem/" + this.item.item.id)
          .then(() => {
            this.deleting = false;

            this.$router.back();
            this.delete_dialog = false;
            this.success_message = true;
            this.toast_msg = "Item was deleted"
          })
          .catch((error) => {
            // this.errorMsg =
            //   error.response.status == 302
            //     ? error.response.data
            //     : this.$t("item.delete_failed");
            // this.hasError = true;
            this.deleting = false;
          });
    },
    item_edited(item) {
      this.editing = false;

      this.getItem();
    },
    itemDialogClose() {
      this.editing = false;
    },
    adjusted(item) {
      this.getItem();
      this.adjust_dialog = false;
      this.toast_msg = "Item quantity adjusted";
      this.success_message = true;
    },
    getItem() {
      this.progress = true;
      axios
          .get(`/api/item/${this.routeId}`)
          .then((res) => {
            this.item = res.data;
            this.defaultImg = this.item.item.photo;
            this.getTransactions();

            this.progress = false;
          })
          .catch((err) => {
            this.progress = false;
            // this.errorMsg = this.$t("item.get_failed");
            // this.hasError = true;
          });
    },
    filterTransaction() {
      const from = this.fromDate;
      const to = this.toDate;
      this.isLoading = true;
      axios
          .get(`/api/item/transactions/${this.routeId}?from=${from}&to=${to}`)
          .then((res) => {
            this.item = {...this.item, ...res.data};
            this.isLoading = false;
            this.filterApplied = true;
          })
          .catch(() => {
            this.isLoading = false;
            // this.errorMsg = this.$t("item.get_failed");
            // this.hasError = true;
          });
    },
    getTransactions() {
      this.isLoading = true;
      axios
          .get(`/api/item/transactions/${this.routeId}`)
          .then((res) => {
            this.item = {...this.item, ...res.data};
            this.isLoading = false;
            this.filterApplied = false;
          })
          .catch(() => {
            this.isLoading = false;
            // this.errorMsg = this.$t("item.get_failed");
            // this.hasError = true;
          });
    },

    openFilterDialog() {
      this.canFilterTransaction = true;
      this.showFilterDialog = true;
    },
    resetDialog() {
      this.showFilterDialog = false;
      this.canFilterTransaction = false;
    },
    handleOnFilter(filterObj) {
      this.fromDate = filterObj.from;
      this.toDate = filterObj.to;
      this.filterTransaction(filterObj);
      this.resetDialog();
    },
    handleOnErrors(msg) {
      this.hasError = true;
      this.errorMsg = msg;
    },
  },

  mounted() {
    const now = moment();
    const lastMonth = moment(now).subtract(3, "M");
    this.fromDate = lastMonth.startOf("month").format("YYYY-MM-DD");
    this.getItem();
  },
};
</script>

<style scoped>
.bg-grey {
  background-color: #e5e5e5;
}

.text-light {
  color: #9e9e9e !important;
  margin: 0;
}

.font-sm {
  font-size: 14px;
}
</style>