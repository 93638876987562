<template>
  <v-row>
    <v-col cols="12" sm="4">
      <v-card flat class="fill-height">
        <v-card-title>
          {{from | humanDate}} - {{to | humanDate}}
          <v-spacer></v-spacer>
          <v-menu transition="slide-y-transition" :close-on-content-click="false" min-width="500">
            <template v-slot:activator="{on}">
              <v-btn outlined color="orange" v-on="on" x-large>Filter<v-icon>mdi-filter-menu-outline</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title>Filter orders</v-card-title>
              <v-card-text>
                <v-autocomplete
                    :items="orderStatuses"
                    outlined
                    color="orange"
                    label="Order Status"
                    item-color="orange"
                    item-text="title"
                    item-value="id"
                    v-model="orderState"
                    clearable
                >
                  <template v-slot:item="{item}">
                    <v-list-item-content>
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-menu transition="slide-y-transition" min-width="auto">
                  <template v-slot:activator="{on}">
                    <v-text-field outlined color="orange" v-on="on" v-model="from" label="From"></v-text-field>
                  </template>
                  <v-date-picker no-title color="orange" v-model="from"></v-date-picker>
                </v-menu>

                   <v-menu transition="slide-y-transition" min-width="auto">
                  <template v-slot:activator="{on}">
                    <v-text-field outlined color="orange" v-on="on" v-model="to" label="To"></v-text-field>
                  </template>
                  <v-date-picker no-title color="orange" v-model="to"></v-date-picker>
                </v-menu>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="getOrders" :loading="filterLoading" block color="orange" rounded depressed x-large dark>Apply Filter</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-card-title>
        <v-card-text>

          <v-skeleton-loader type="list-item@18" v-if="loadingOrders"></v-skeleton-loader>
          <v-list style="height: 80vh; overflow:auto" v-else-if="orders && orders.data">
            <v-list-item color="orange"  :to="'/orders/list/'+order.uuid+'?order_id='+order.uuid" class="border-bottom rounded-lg" v-for="order in orders.data" :key="order.id">
              <v-list-item-avatar :class="{
                'dark-orange':$vuetify.theme.isDark,
                'orange lighten-5':!$vuetify.theme.isDark
              }"  size="60" rounded="xl">
                <v-icon color="orange" large>mdi-tag-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-black">#{{ order.order_number }}</v-list-item-title>
                <v-list-item-subtitle>Created At:{{order.created_at | humanDateTime}}</v-list-item-subtitle>
                <v-list-item-subtitle>Amount:{{order.amount_due | toMoney | currency_symbol}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>
                <v-chip class="ml-2" :class="{
                'blue lighten-5 blue--text':order.state=='pending',
                'cyan lighten-5 cyan--text':order.state=='processing',
                'red lighten-5 red--text':order.state=='cancelled',

                'green lighten-5 green--text':order.state=='completed',

                'purple lighten-5 purple--text':order.state=='confirmed',
                'red lighten-5 red--text':order.state=='failed',
                'orange lighten-5 orange--text':order.state=='returned',

              }">{{ order.state }}
                </v-chip>
              </v-list-item-action-text>
              <v-list-item-icon><v-icon small color="grey">mdi-chevron-right</v-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
          <no-record-component v-else></no-record-component>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="8">
      <order-preview-component full-height v-if="order_id"></order-preview-component>

    </v-col>

  </v-row>
</template>

<script>
import {defineComponent} from 'vue'
import NoRecordComponent from "../components/noRecordComponent.vue";
import OrderPreviewComponent from "../components/OrderPreviewComponent.vue";
import moment from "moment";

export default defineComponent({
  name: "OrderList",
  components: {OrderPreviewComponent, NoRecordComponent},
  data() {
    return {
      orders: null,
      loadingOrders: false,
      from:"",
      to:"",
      orderState:null,
      filterLoading:false,
      orderStatuses: [
        {
          title: "Confirmed",
          id: "confirmed",
          description: "Acknowledge receipt of the order"
        },
        {
          title: "Processing",
          id: "processing",
          description: "Acknowledge processing of the order"
        },
        {
          title: "Completed",
          id: "completed",
          description: "Acknowledge Completion of the order, a sale will be created"
        },
        {
          title: "Canceled",
          id: "canceled",
          description: "Canceled the order, order will be disabled"
        },
        {
          title: "Failed",
          id: "failed",
          description: "Indicate that the order failed"
        },
        {
          title: "Returned",
          id: "returned",
          description: "Return the order, sale will be returned"
        },

      ]
    }
  },
  computed:{
    order_id(){
      return this.$route.params.order_id;
    }
  },
  methods: {
    getOrders() {

      this.filterLoading=true;

      let URL = "/api/pos/orders?from="+this.from+"&to="+this.to;

      if (this.orderState){

        URL+="&state="+this.orderState;

      }

      axios.get(URL)
          .then(res => {
            this.orders = res.data;
            this.filterLoading=false;

          })
          .catch(() => {
            this.filterLoading=false;
          })
    }
  },
  mounted() {
    this.getOrders();
    this.from = moment().subtract(1,"months").format("Y-M-D");
    this.to = moment().format("Y-M-D");
  }
})
</script>


<style >
.dark-orange {
  background-color: #FF98000D;
}

</style>