<template>
	<div>
		<v-card flat v-if="items">
			<v-card-text>
				<v-skeleton-loader
					type="list-item@10"
					v-if="loading"
				></v-skeleton-loader>
				<v-list>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>Stock Adjustment History</v-list-item-title>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn color="green" icon @click="getAdjustments()"
								><v-icon>mdi-reload</v-icon></v-btn
							>
						</v-list-item-action>
					</v-list-item>

					<v-list-item
						class="border-bottom"
						:key="item.id"
						v-for="item in items.data"
					>
						<v-list-item-icon>
							<v-icon color="green" large v-if="item.type === 'increase'"
								>mdi-plus</v-icon
							>
							<v-icon color="red" large v-else>mdi-minus</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title
								>{{ item.type }}d by: {{ item.quantity }}</v-list-item-title
							>
							<v-list-item-subtitle>
								<strong>Reason:</strong>{{ item.note }}
							</v-list-item-subtitle>
							<v-list-item-subtitle>
								<strong>Adjustment Account:</strong
								>{{ item.adjustment_account.account_name }}
							</v-list-item-subtitle>
							<v-list-item-subtitle>
								<strong>Date:</strong>{{ item.date | humanDate }}
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action-text>
							Amount:{{ item.amount | toMoney }} <br />
							Unit price: {{ item.unit_price | toMoney }}
						</v-list-item-action-text>
						<v-list-item-action>
							<v-menu>
								<template v-slot:activator="{ on }">
									<v-btn v-on="on" icon
										><v-icon>mdi-dots-vertical</v-icon></v-btn
									>
								</template>
								<v-list>

									<v-list-item
										@click="
											deleteAdjustmentDialog = true;
											adjustmentItem = item;
										"
									>
										<v-list-item-title class="red--text"
											>Delete</v-list-item-title
										>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item-action>
					</v-list-item>
				</v-list>

			</v-card-text>
			<v-card-actions v-if="items.total > 0">
				<v-container>
					<v-row justify="center">
						<v-col cols="3">
							<small
								>Page {{ items.current_page }} of
								{{ items.last_page }}</small
							>
						</v-col>
						<v-col cols="1">
							<v-btn
								:disabled="items.prev_page_url == null"
								@click="prev_page()"
								icon
								small
								><v-icon>mdi-chevron-left</v-icon></v-btn
							>
						</v-col>
						<v-col cols="4">
							<v-autocomplete
								:loading="loading"
								:disabled="loading"
								:items="pages"
								item-value="value"
								item-text="text"
								v-model="items.current_page"
								@change="go_Topage(items.current_page)"
							></v-autocomplete>
						</v-col>
						<v-col cols="1">
							<v-btn
								:disabled="items.next_page_url == null"
								@click="next_page()"
								icon
								small
								><v-icon>mdi-chevron-right</v-icon></v-btn
							>
						</v-col>
					</v-row>
				</v-container>
			</v-card-actions>
		</v-card>

		<v-dialog
			:persistent="deletingAdjustment"
			v-model="deleteAdjustmentDialog"
			width="400"
		>
			<v-card>
				<v-card-title class="font-weight-black">Confirm</v-card-title>
				<v-card-text>
					<h2 class="font-weight-light">
						Do you want to delete this adjustment record?
					</h2>
				</v-card-text>
				<v-card-actions>
					<v-btn
						:loading="deletingAdjustment"
						@click="deleteAdjustment"
						color="red"
						block
						rounded
						depressed
						large
						dark
						>Delete</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
        v-model="nextAction"
        width="500"
        persistent
        scrollable>
			<found-transactions
				@closed="nextAction = false"
				:instance="deleteResults"
				@invoiceDeleted="adjustInvoices"
				@saleDeleted="adjustSales"
				@done="deleteAdjustment"
			></found-transactions>
		</v-dialog>
		<v-snackbar color="red" v-model="error">{{ error_message }}</v-snackbar>
	</div>
</template>

<script>
import FoundTransactions from "./FoundTransactions";
export default {
	components: { FoundTransactions },
	props: ["itemID"],
	name: "adjustmentsComponent",
	data() {
		return {
			items: null,
			loading: false,
			deleteAdjustmentDialog: false,
			deletingAdjustment: false,
			adjustmentItem: null,
			nextAction: false,
			deleteResults: null,
			error: false,
			error_message: "",
		};
	},
	computed: {
		pages() {
			const p = [];
			for (let i = 1; i <= this.items.last_page; i++) {
				p.push({
					value: i,
					text: "Page " + i,
				});
			}
			return p;
		},
	},
	methods: {
		adjustInvoices(index) {
			this.deleteResults.invoices.splice(index, 1);
		},
		adjustSales(index) {
			this.deleteResults.sales.splice(index, 1);
		},

		deleteAdjustment() {
			this.deletingAdjustment = true;
			axios
				.delete("/api/v2/item/adjust/" + this.adjustmentItem.id)
				.then((res) => {
					if (res.data.status) {
						this.deleteAdjustmentDialog = false;
						this.getAdjustments();
						this.$emit("changed", this.adjustmentItem);
					} else {
						this.deletingAdjustment = false;
						this.deleteResults = res.data;
						this.nextAction = !res.data.status;
						this.deleteAdjustmentDialog = false;
					}

          this.deletingAdjustment=false;

				})
				.catch((error) => {
					// this.error_message =
					// 	error.response.status === 302
					// 		? error.response.data
					// 		: "Something went wrong, could not delete this record";
					// this.error = true;
					this.deletingAdjustment = false;
				});
		},

		getAdjustments() {
			this.loading = true;
			axios.get("/api/v2/item/adjustments/" + this.itemID).then((res) => {
				this.items = res.data;
				this.loading = false;
			});
		},

		next_page() {
			this.loading = true;
			axios.get(this.items.next_page_url).then((res) => {
				this.items = res.data;

				this.loading = false;
			});
		},
		prev_page() {
			this.loading = true;
			axios.get(this.items.prev_page_url).then((res) => {
				this.items = res.data;

				this.loading = false;
			});
		},

		go_Topage(page) {
			this.loading = true;
			axios
				.get("/api/v2/item/adjustments/" + this.itemID + "page=" + page)
				.then((res) => {
					this.items = res.data.data;

					this.loading = false;
				});
		},
	},
	mounted() {
		this.getAdjustments();
	},
};
</script>

<style scoped></style>
