<template>
  <v-container fluid>

    <v-row dense class="rounded-lg" :class="$vuetify.theme.isDark ? 'dark-orange pa-3' : 'orange lighten-5 pa-3'">
      <v-col cols="12" sm="3">
        <v-card flat height="100px">
          <v-card-text class="text-center">
            <strong class="d-block font-weight-black text-h5">{{ summaries.total_count | toMoney }}</strong>
            <small>Total items count
              <help-bubble title="What does this mean?"
                description="This number represents the total count of all unique items. It is not the summation of their quantities"></help-bubble>
            </small>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3">
        <v-card flat height="100px">
          <v-card-text class="text-center">
            <strong class="d-block font-weight-black text-h5">{{ summaries.sum_of_quantities | toMoney }}</strong>
            <small>Total sum of quantities
              <help-bubble title="What does this mean?"
                description="This is the summation of all item quantities"></help-bubble>
            </small>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card flat height="100px">
          <v-card-text class="text-center">
            <strong class="d-block font-weight-black text-h5 green--text">{{
              summaries.total_inventory_worth | absolute | currency_symbol
            }}</strong>
            <small>Total value of inventory
              <help-bubble title="What does this mean?"
                description="This is how much all the items that track inventory cost. This only includes items you chose to track inventory"></help-bubble>
            </small>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="2">
        <v-card flat height="100px">
          <v-card-text class="text-center">
            <v-btn id="moreInventorySummariesButton" @click="showDetailsDialog = true" text rounded color="orange">
              More summaries...
            </v-btn>

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>


    <v-dialog scrollable width="500" v-model="showDetailsDialog">
      <v-card>
        <v-card-title>
          Inventory summary details
        </v-card-title>
        <v-card-text>

          <v-row dense class="rounded-lg" :class="$vuetify.theme.isDark ? 'black' : 'grey lighten-5'">

            <v-col cols="12" sm="6">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{ summaries.total_count | toMoney }}</strong>
                  <small>Total items count.
                    <help-bubble title="What does this mean?"
                      description="This number represents the total count of all unique items. It is not the summation of their quantities"></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{ summaries.sum_of_quantities | toMoney }}</strong>
                  <small>Total sum of quantities.
                    <help-bubble title="What does this mean?"
                      description="This is the summation of all item quantities"></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5 green--text">{{
                    summaries.total_inventory_worth | absolute | currency_symbol
                  }}</strong>
                  <small>Total value of inventory
                    <help-bubble title="What does this mean?"
                      description="This is how much all the items that track inventory cost. This only includes items you chose to track inventory"></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>




            <v-col cols="12">
              <strong class="font-weight-black">Other Summaries:</strong>
            </v-col>


            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_product_count | toMoney
                  }}</strong>
                  <small>Total products.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items with type product. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_services_count | toMoney
                  }}</strong>
                  <small>Total services.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items with type service. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.sum_of_inventory_quantities | toMoney
                  }}</strong>
                  <small>Sum of quantities of items that track inventory
                    <help-bubble title="What does this mean?"
                      description="This is the sum of quantities of items that you chose to track inventory."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_fixed_assets_count | toMoney
                  }}</strong>
                  <small>Total fixed assets.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items with type Fixed Asset. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_inventory_items_count | toMoney
                  }}</strong>
                  <small>Total count of items that track inventory.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items that you chose to track their inventory. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_inventory_non_items_count | toMoney
                  }}</strong>
                  <small>Total count of items that don't track inventory.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items that you chose not to track their inventory. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_retail_items_count | toMoney
                  }}</strong>
                  <small>Total count of items that you sell.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items that you indicated that you sell. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>


            <v-col cols="12">
              <v-card flat height="100px">
                <v-card-text class="text-center">
                  <strong class="d-block font-weight-black text-h5">{{
                    summaries.total_buying_items_count | toMoney
                  }}</strong>
                  <small>Total count of items that you buy.
                    <help-bubble title="What does this mean?"
                      description="This is a count of all items that you indicated that you buy. Not a sum of their quantities."></help-bubble>
                  </small>
                </v-card-text>
              </v-card>
            </v-col>


          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>

</template>
<script>
import { defineComponent } from 'vue'
import HelpBubble from "./HelpBubble.vue";

export default defineComponent({
  name: "ItemSummariesComponent",
  components: { HelpBubble },
  data() {
    return {
      showDetailsDialog: false,
      summaries: {
        total_count: 0,
        total_buying_items_count: 0,
        total_fixed_assets_count: 0,
        total_inventory_items_count: 0,
        total_inventory_non_items_count: 0,
        total_product_count: 0,
        total_retail_items_count: 0,
        total_services_count: 0,
        sum_of_quantities: 0,
        sum_of_inventory_quantities: 0
      }
    }
  },
  methods: {
    getSummaries() {
      axios.get("/api/v2/items/summaries")
        .then(res => {
          this.summaries = res.data;
        })

    }
  },
  mounted() {
    this.getSummaries();
  }
})
</script>

<style>

.dark-orange{
  background-color: #FF98000D;
}

</style>