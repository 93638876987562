<template>

  <v-card flat>
    <v-card-title class="font-weight-light">
      {{ editing ? "Edit" : "Create" }} Product or Service
      <v-spacer></v-spacer>

      <v-menu :close-on-content-click="false" max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn color="orange" text x-small v-on="on"
          >Tax Calculator
            <v-icon x-small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <vat-calculator :show-close="false"></vat-calculator>
      </v-menu>
      <v-btn
          color="red"
          text
          depressed
          rounded
          x-small
          @click="$store.state.new_item = false; saving_progress = false; editing=false; track_inventoryAlt=false; clear_values(); error_message = false; $emit('closed');"
      >Close
        <v-icon small>mdi-close</v-icon>
      </v-btn>
      <v-alert
          v-if="error_message"
          id="errorAlert"
          class="w-100 mt-2"
          color="red"
          prominent
          text
          type="error"
          transition="fade"
          style="width: 100% !important;"
      >
        {{ error_msg }}
      </v-alert>
    </v-card-title>

    <v-card-text id="container">
      <v-row dense>
        <v-col class="mx-auto" cols="12" sm="4">
          <photo-selector-component
              :default_path="default_photo"
              :size="120"
              class="mb-3"
              directory="item_photos"
              @updated="(p) => (photo_path = p)"
          ></photo-selector-component>
        </v-col>
      </v-row>

      <v-form ref="newitem_form" @keyup.native.enter="create_item($event)">
        <v-row dense>
          <v-col cols="12" sm="12">
            <v-text-field
                v-model="name"
                :disabled="saving_progress"
                placeholder="Name"
                :rules="[$store.state.validationRules.required]"
                autofocus
                outlined
                color="orange"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
                v-model="category_id"
                :items="categories"
                placeholder="Category"
                :loading="cat_loading"
                item-text="name"
                item-value="id"
                outlined
                color="orange"
                item-color="orange"

            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="code"
                placeholder="Code Or SKU"
                color="orange"
                outlined
                persistent-hint
                type="text"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="12">
            <v-textarea
                v-model="description"
                :disabled="saving_progress"
                placeholder="Description"
                color="orange"
                outlined
                rows="2"
                auto-grow
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="12">
            <v-select
                v-model="item_type"
                :disabled="editing"
                :items="item_types"
                placeholder="Type"
                color="orange"
                item-color="orange"
                outlined
                @change="type_change"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox
                v-model="retail"
                :disabled="off_selling"
                :label="'I sell this' + ' ' + item_type"
                color="orange"
            >
            </v-checkbox>
          </v-col>

          <v-col v-if="retail" cols="12" sm="12">
            <v-text-field
                v-model="selling_price"
                :disabled="saving_progress"
                placeholder="Selling Price"
                :prefix="$store.state.user.current_business.currency.symbol"
                color="orange"
                outlined
                type="number"
            ></v-text-field>

            <v-text-field
                persistent-hint
                color="orange"
                outlined
                placeholder="Discount(%)"
                v-model="discount"
            ></v-text-field>

            <v-btn
                v-if="retail"
                color="orange"
                small
                style="transition: 0.5s ease-in-out"
                text
                @click="show_accounts = !show_accounts"
            >
              {{ show_accounts ? 'Hide' : 'Change' }}
              Account
              <v-icon>mdi-menu-{{ show_accounts ? "up" : "down" }}</v-icon>
            </v-btn>
          </v-col>

          <v-col
              v-if="retail"
              v-show="show_accounts"
              class="border-bottom"
              cols="12"
              sm="12"
          >
            <account-dropdown
                v-if="retail"
                :id="sales_account"
                :accountTypes="['Income']"
                :defaultAccount="sales_account"
                label="Sales Account"
                :main-types="incomeSubTypes"
                :subAccounts="sales_accounts"
                @accountChange="salesAccountChange"
            ></account-dropdown>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox
                v-model="buy"
                :disabled="off_buying"
                :label="'I buy this' + ' ' + item_type"
                color="orange"
                dense
                @change="unit_price = buy ? unit_price : 0"
            >
            </v-checkbox>
          </v-col>
          <v-col v-if="buy" cols="12" sm="12">
            <v-text-field
                v-model="unit_price"
                :disabled="saving_progress"
                placeholder="Unit Cost"
                :prefix="$store.state.user.current_business.currency.symbol"
                color="orange"
                outlined
                type="number"
            ></v-text-field>
            <v-btn
                v-if="buy"
                color="orange"
                small
                style="transition: 0.5s ease-in-out"
                text
                @click="show_accounts2 = !show_accounts2"
            >
              {{ show_accounts2 ? 'Hide' : 'Change' }}
              Account
              <v-icon>mdi-menu-{{ show_accounts2 ? "up" : "down" }}</v-icon>
            </v-btn>
          </v-col>

          <v-col
              v-show="show_accounts2"
              class="border-bottom"
              cols="12"
              sm="12"
          >
            <account-dropdown
                v-if="buy"
                :id="purchase_account"
                :accountTypes="['Expense']"
                :defaultAccount="purchase_account"
                label="Purchase Account"
                :subAccounts="purchase_accounts"
                :main-types="expenseSubTypes"
                @accountChange="purchaseAccountChange"
            >
            </account-dropdown>

          </v-col>

          <v-col cols="12" sm="12">

            <v-checkbox
                v-if="is_product"
                v-model="track_inventory"
                :disabled="track_inventoryAlt"
                label="Track Inventory"
                color="orange"
                dense
            ></v-checkbox>

            <v-text-field
                v-if="track_inventory"
                v-model="quantity"
                :disabled="track_inventoryAlt"
                label="Initial Quantity"
                outlined
                min="0"
                type="number"
                color="orange"
            ></v-text-field>

            <v-text-field
                v-if="track_inventory"
                v-model="quantityLimit"
                placeholder="Reorder Limit"
                hint="You would be notified when the quantity of this item becomes less than or equal to this value."
                outlined
                persistent-hint
                type="number"
                color="orange"
            >
            </v-text-field>

            <v-menu
                v-if="track_inventory"
                v-model="Date_menu"
                :close-on-content-click="false"
                :disabled="track_inventoryAlt"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >

              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="entrydate"
                    :disabled="track_inventoryAlt"
                    placeholder="Stock Date"
                    outlined
                    readonly
                    color="orange"
                    v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                  color="orange"
                  no-title
                  v-model="entrydate"
                  @input="Date_menu = false"
              ></v-date-picker>
              <!-- <template v-slot:activator="{ on }"> -->

              <!-- </template> -->
            </v-menu>

            <h5 v-if="track_inventory">
              Initial Stock Amount(Unit Cost x Quantity) =
              {{ (quantity * unit_price) | toMoney | currency_symbol }}
            </h5>

          </v-col>

        </v-row>
      </v-form>

      <v-snackbar v-model="success_message">
        {{ toast_msg }}
        <v-btn
            color="success"
            text
            timeout="360"
            @click="success_message = false"
        >
          Close
        </v-btn>
      </v-snackbar>

    </v-card-text>
    <v-card-actions>
      <v-btn
          :disabled="initializing && cat_loading"
          :loading="saving_progress"
          block
          color="orange"
          dark
          depressed
          x-large
          rounded
          @click="create_item"
      >{{ editing ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-card>


</template>

<script>
import accountDropdown from "./accountDropdown";
import moment from "moment";
import VatCalculator from "./VatCalculator";
import PhotoSelectorComponent from "./PhotoSelectorComponent";

export default {
  emits: ['ItemCreated'],
  props: [
    "editing",
    "item",
    "selling_default",
    "buy_default",
    "inventory_default",
  ],
  name: "NewItemComponent",
  components: {
    PhotoSelectorComponent,
    VatCalculator,
    accountDropdown,
  },
  data() {
    return {
      incomeAccountsCategory: null,
      expenseAccountsCategory: null,
      assetAccountsCategory: null,
      default_photo: "",
      photo_path: "",
      discount: 0,
      off_inventory: false,
      entrydate: "",
      Date_menu: false,
      off_buying: false,
      id: null,
      initializing: false,
      track_inventory: false,
      track_inventoryAlt: false,
      code: "",
      show_accounts: false,
      show_accounts2: false,
      saving_progress: false,
      toast_msg: "",
      error_msg: "",
      purchase_account: null,
      sales_account: null,
      item_tax: null,
      alltaxes: [],
      buy: false,
      has_photo: false,
      img_preview: "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/photo_placeholder.jpg",
      selling_price: 0,
      display_unitPrice: 0,
      categories: [],
      cat_loading: false,
      category_id: null,
      retail: false,
      item_type: "Product",
      is_product: true,
      taxed: false,
      success_message: false,
      error_message: false,
      accounts: [],
      item_types: [
        {text: "Product", value: "Product"},
        {text: "Service", value: "Service"}
      ],
      clients: [],
      name: "",
      description: "",
      type_switch: true,
      type: this.type_switch ? "Product" : "Service",
      quantity: 0,
      unit_price: 0,
      quantityLimit: 1,
    };
  },

  methods: {

    getExpenseAccountsCategory() {

      axios.get("/api/getexpense")
          .then(res => {
            this.expenseAccountsCategory = res.data;
          })

    },

    getAssetAccountsCategory() {

      axios.get("/api/getassets")
          .then(res => {
            this.assetAccountsCategory = res.data;
          })

    },

    getIncomeAccountsCategory() {

      axios.get("/api/getincome")
          .then(res => {
            this.incomeAccountsCategory = res.data;
          })

    },
    validateNumber(value) {
      if (!value) return "Please enter a number";
      if (Number(value) < 0) return "Please enter a non-negative number";
      if (!/^\d+$/.test(value)) return "Only numbers are allowed";
      return true;
    },
    remove_photo() {
      this.photo = null;
      this.img_preview = this.baseUrl + "/img/item_photo.png";
      this.has_photo = false;
      this.change_photo = true;
    },

    select_image() {
      this.change_photo = true;

      let image_selector = document.getElementById("item_photo");
      image_selector.click();
    },

    set_image(e) {
      this.photo = e.target.files[0];

      const fr = new FileReader();
      fr.readAsDataURL(this.photo);
      fr.onload = () => {
        this.img_preview = fr.result;
        this.has_photo = true;
      };
    },

    create_item() {


      if (!this.buy && !this.retail && this.item_type !== "Fixed Asset") {
        this.error_msg = "Please specify if you sell or buy this item";
        this.error_message = true;

        return false;
      }

      if (this.$refs.newitem_form.validate()) {
        this.track_inventory =
            this.item_type !== "Product" ? false : this.track_inventory;
        this.saving_progress = true;
        this.quantity = this.quantity ? this.quantity : 0;
        let formdata = new FormData();
        formdata.append("name", this.name);
        formdata.append("description", this.description);
        formdata.append("code", this.code);
        formdata.append("type", this.item_type);
        formdata.append("taxed", this.taxed ? 1 : 0);
        formdata.append("unit_price", this.unit_price ? this.unit_price : 0);
        formdata.append("account_id", this.sales_account);
        formdata.append("selling_price", this.selling_price);
        formdata.append("tax", this.item_tax);
        formdata.append("track_inventory", this.track_inventory ? 1 : 0);
        formdata.append("retail_item", this.retail ? 1 : 0);
        formdata.append("buy_item", this.buy ? 1 : 0);
        formdata.append("purchase_accountID", this.purchase_account);
        formdata.append("photo", this.photo);
        formdata.append("category_id", this.category_id);
        formdata.append("track_inventory", this.track_inventory ? 1 : 0);
        formdata.append("id", this.id);
        formdata.append("quantity", this.quantity);
        formdata.append("photo_path", this.photo_path);
        formdata.append("date", this.entrydate);
        formdata.append("discount_amount", this.discount_amount);
        formdata.append("discount_percent", this.discount);
        formdata.append("quantity_limit", this.quantityLimit);

        if (!this.editing) {
          axios
              .post("/api/createitem", formdata)
              .then((item) => {

                this.saving_progress = false;
                this.$store.state.new_item = false;
                this.toast_msg = "Item created";
                this.success_message = true;
                this.editing = false;
                this.clear_values();
                this.$emit("ItemCreated", item.data);
                this.error_message = false;

              })
              .catch(() => {
                this.saving_progress = false;

              });
        } else {
          axios
              .post("/api/item/" + this.id, formdata)
              .then((item) => {

                this.clear_values();
                this.$store.state.new_item = false;
                this.saving_progress = false;
                this.toast_msg = "Item updated";
                this.success_message = true;
                this.get_categories();
                this.$emit("ItemCreated", item.data);

              })
              .catch(() => {
                this.saving_progress = false;

              });
        }
      }
    },
    clear_values() {
      this.name = "";
      this.description = "";
      this.quantity = 0;
      this.unit_price = 0;
      this.selling_price = 0;
      this.code = "";
      this.taxed = false;
      this.track_inventory = false;
      this.track_inventoryAlt = false;
      this.buy = false;
      this.buy_default = false;
      this.off_buying=false;
      this.retail=false;
      this.sales_account = null;
      this.purchase_account = null;
      this.photo = null;

    },

    edit_item() {
      this.name = this.item.name;
      this.selling_price = this.item.selling_price;
      this.description = this.item.description;
      this.quantity = this.item.quantity;
      this.sales_account = this.item.account_id;
      this.taxed = this.item.taxed;
      this.date = this.item.entry_date;
      this.unit_price = this.item.unit_cost;
      this.item_type = this.item.type;
      this.credit = this.item.credit;
      this.purchase_account = this.item.purchase_accountID;
      this.id = this.item.id;
      this.code = this.item.code;
      this.$store.state.new_item = true;
      this.item_tax = this.item.tax_id;
      this.track_inventory = this.item.track_inventory;
      this.track_inventoryAlt = this.item.track_inventory;
      this.buy = this.item.buying_item === 1;
      this.retail = this.item.retail_item === 1;
      this.has_photo = this.item.photo !== "/img/item_photo.png";
      this.img_preview = this.baseUrl + this.item.photo;
      this.category_id = this.item.category_id;
      this.discount = this.item.discount_percent;
      this.type_change();
      this.change_photo = false;

      this.off_buying = this.buy;
    },

    purchaseAccountChange(account) {
      this.purchase_account = account;
    },

    salesAccountChange(account) {
      this.sales_account = account;
    },
    type_change() {
      this.is_product = this.item_type === "Product" ? true : false;
      if (this.item_type === "Fixed Asset") {
        this.retail = false;
      }
    },
    get_categories() {
      this.cat_loading = true;
      axios.get("/api/itemcategories").then((res) => {
        this.categories = res.data;

        this.cat_loading = false;
      });
    },
  },
  watch: {

    item() {

      this.photo_path = this.default_photo;

      if (this.item && this.editing) {
        this.default_photo = this.item.photo;
        this.edit_item();
      }
    },
    buy_default() {
      this.buy = this.buy_default;
      this.off_buying = true;
    },

    selling_default() {
      this.retail = this.selling_default;
    },
    editing() {
      if (this.editing) {
        this.edit_item();
      }
    },
    show_dialog() {
      this.get_categories();
    },
    inventory_default() {
      this.track_inventory = this.inventory_default;
    },
  },
  computed: {

    incomeSubTypes() {
      return !this.incomeAccountsCategory ? [] : this.incomeAccountsCategory.subtypes;
    },
    expenseSubTypes() {
      return !this.expenseAccountsCategory ? [] : this.expenseAccountsCategory.subtypes;

    },
    discount_amount() {
      const p = Number(this.discount);
      if (p > 0) {
        return (p / 100) * this.selling_price;
      }
      return 0;
    },

    off_selling() {
      return this.selling_default;
    },
    currentBusiness() {
      return this.$store.state.user.user_infor.current_business;
    },
    taxes() {
      return this.currentBusiness.taxes;
    },
    shouldDisableInventory() {
      return this.track_inventoryAlt && this.editting;
    },

    baseUrl() {
      return this.$store.state.baseURL;
    },

    show_dialog() {
      return this.$store.state.new_item;
    },

    sales_accounts() {
      if (this.incomeAccountsCategory) {
        let sub = this.incomeAccountsCategory.subtypes.filter(
            (account_type) => {
              return account_type.name == "Revenue";
            }
        );
        return sub[0].accounts;
      }
      return [];
    },

    purchase_accounts() {

      if (this.assetAccountsCategory && this.expenseAccountsCategory) {

        let expense_sub = this.expenseAccountsCategory.subtypes.filter(
            (account_type) => {
              return account_type.name == "Cost of Sale";
            }
        );

        let expense_sub2 = this.assetAccountsCategory.subtypes.filter(
            (account_type) => {
              return account_type.name == "Property, Plant and Equipments";
            }
        );

        return (expense_sub.length && expense_sub2.length) ? [...expense_sub[0].accounts, ...expense_sub2[0].accounts] : [];

      }

      return [];

    }
  },
  mounted() {

    if (this.item) {
      this.default_photo = this.item.photo;
      this.photo_path = this.default_photo;
    }

    if (!this.editing) {
      this.photo_path = "https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/photo_placeholder.jpg";
      this.default_photo = this.photo_path;
    }

    if (this.selling_default) {
      this.retail = true;
    }

    this.entrydate = moment().format("Y-M-D");


    if (this.$store.state.user.is_admin == 1) {
      if (this.editing && this.item) {
        this.edit_item();
      }

      this.get_categories();

    } else {
      if (this.$store.state.user.components[5].roles.view == 1) {
        if (this.editing && this.item) {
          this.edit_item();
        }
        this.get_categories();
      }
    }


    this.getIncomeAccountsCategory();
    this.getAssetAccountsCategory();
    this.getExpenseAccountsCategory();
  },
};
</script>

<style scoped></style>
