<template>
  <v-row>
    <v-col cols="12" sm="8" class="mx-auto">
      <v-card :color="$vuetify.theme.isDark ? 'dark-orange-bg' : 'orange lighten-5'" flat >
        <v-card-text>
          <v-avatar  size="200" rounded="xl" >
              <v-img  :src="user.photo"></v-img>
          </v-avatar>

          <v-btn @click="selectFile" :loading="loading" text color="orange" class="d-block mt-2">Change Photo</v-btn>
          <v-file-input class="d-none" id="fileSelector" v-model="photo" @change="updatePhoto" accept="image/*"></v-file-input>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-5">
            <v-list-item-icon class="mr-1 pa-0">
            <v-icon color="orange" large>
              mdi-account-outline
            </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title class="font-weight-black">{{user.first_name}}</v-list-item-title>
            <v-list-item-subtitle>First Name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class=" rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
            <v-icon color="orange" large>
              mdi-account-outline
            </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title class="font-weight-black">{{user.last_name}}</v-list-item-title>
            <v-list-item-subtitle>Last Name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0" >
            <v-icon color="orange" large class="ma-0">
              mdi-gender-male-female-variant
            </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pl-0 ml-0">
            <v-list-item-title class="font-weight-black ml-0 pl-0">{{user.gender}}</v-list-item-title>
            <v-list-item-subtitle>Gender</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0" >
            <v-icon color="orange" large class="ma-0">
              mdi-phone-outline
            </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pl-0 ml-0">
            <v-list-item-title class="font-weight-black ml-0 pl-0">{{user.phone_number}}</v-list-item-title>
            <v-list-item-subtitle>Phone Number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0" >
            <v-icon color="orange" large class="ma-0">
              mdi-email-outline
            </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pl-0 ml-0">
            <v-list-item-title class="font-weight-black ml-0 pl-0">{{user.email}}</v-list-item-title>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="showEmailDialog=true; email=user.email;" text rounded color="orange">Change Email</v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0" >
            <v-icon color="orange" large class="ma-0">
              mdi-clock-time-nine-outline
            </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pl-0 ml-0">
            <v-list-item-title class="font-weight-black ml-0 pl-0">{{user.created_at | humanDateTime}}</v-list-item-title>
            <v-list-item-subtitle>Signed Up At</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-btn @click="setEditDialog" block x-large color="orange" depressed rounded dark class="mt-3">Edit</v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog width="500" v-model="showEditDialog" :persistent="loading">
      <v-card>
        <v-card-title>Edit your profile <v-spacer></v-spacer><v-btn icon color="red" @click="showEditDialog=false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
          <v-form ref="profileForm">
            <v-text-field :rules="[$store.state.validationRules.required]" outlined label="First Name" v-model="first_name" color="orange"></v-text-field>
            <v-text-field :rules="[$store.state.validationRules.required]" outlined label="Last Name" v-model="last_name" color="orange"></v-text-field>
            <phone-number-input @input="n=>phone_number_full=n" :default_number="phone_number"></phone-number-input>
            <v-select :rules="[$store.state.validationRules.required]"  item-color="orange" outlined label="Gender" color="orange" v-model="gender" :items="['male','female','none']"></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :loading="loading"
              @click="saveChanges"
              color="orange"
              block
              depressed
              x-large
              dark
              rounded
          >Save changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :persistent="loadingEmail" width="500" v-model="showEmailDialog">
      <v-card>
        <v-card-title>Change your email</v-card-title>
        <v-card-text>
          <v-alert type="info" color="orange" prominent text>
            <h2>Note:</h2>
            <p>
              You will be required to login after this
            </p>
            <p>
              You will would be required to verify your new email
            </p>

          </v-alert>
          <v-form ref="emailForm">
            <v-text-field
                outlined
                color="orange"
                label="Current Password"
                type="password"
                v-model="password"
                :rules="[$store.state.validationRules.required]"
            >
            </v-text-field>

            <v-text-field
                v-model="email"
                outlined
                type="email"
                color="orange"
                label="New email"
                :rules="[$store.state.validationRules.required,$store.state.validationRules.email]"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loadingEmail" @click="changeEmail" block color="orange" depressed rounded dark x-large>Change Email</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>
import {defineComponent} from 'vue'
import PhoneNumberInput from "./PhoneNumberInput.vue";

export default defineComponent({
  name: "ProfileComponent",
  components: {PhoneNumberInput},
  data(){
    return{
      first_name:"",
      last_name:"",
      email:"",
      phone_number:"",
      phone_number_full:"",
      gender:"",
      password:"",
      showEmailDialog:false,
      showEditDialog:false,
      loading:false,
      loadingEmail:false,
      photo:null

    }
  },
  computed:{
    user(){
      return this.$store.state.user;
    }
  },
  methods:{

    selectFile(){

     document.getElementById("fileSelector").click();

    },
    updatePhoto(){

      this.loading=true;

      const formData =  new FormData();
      formData.append("photo",this.photo);

      axios.post("/api/changephoto",formData)
          .then(res=>{

            console.log(res.data);
            this.$store.state.user.photo = res.data
            this.loading=false;

          })
          .catch(()=>{
            this.loading=false;
          })

    },
    setEditDialog(){

      this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
      this.phone_number = this.user.phone_number.slice(-9);
      this.gender = this.user.gender;
      this.showEditDialog=true;

    },
    changeEmail(){

      if (this.$refs.emailForm.validate()){

        this.loadingEmail=true;
        const data = {
          password:this.password,
          email:this.email

        };
        axios.post("/api/update-email",data)
            .then(rea=>{
              location.reload();
            })
            .catch(()=>{
              this.loadingEmail=false;
            })

      }


    },
    saveChanges() {
      if (this.$refs.profileForm.validate()) {
        this.loading = true;

        const data = {
          first_name:this.first_name,
          last_name:this.last_name,
          gender:this.gender,
          phone_number:this.phone_number_full
        };

        axios.post("/api/updateuser",data)
            .then(res=>{
             window.location.reload();
             this.showEditDialog=false;
              this.loading=false;

            })
            .then(error=>{
              this.loading=false;
            })


      }
    }
  }
})
</script>

<style scoped>
.dark-orange-bg {
  background-color: #FF98000D;
}

</style>