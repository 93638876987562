<template>
  <v-row>
    <v-col cols="12" sm="8" class="mx-auto">
      <v-card :color="$vuetify.theme.isDark ? 'dark-orange-bg' : 'orange lighten-5'" flat>
        <v-card-text>

          <v-avatar size="200" rounded="xl">
            <v-img contain :src="business.logo"></v-img>
          </v-avatar>

          <v-btn @click="selectFile" :loading="loading" text color="orange" class="d-block mt-2">Change Photo</v-btn>
          <v-file-input class="d-none" id="logoSelector" v-model="logo" @change="updatePhoto"
                        accept="image/*"></v-file-input>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-5">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-card-account-details-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">
                {{ business.owner_id }}-{{ business.country_id }}-{{ business.id }}
              </v-list-item-title>
              <v-list-item-subtitle>Business ID</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-briefcase-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.name }}</v-list-item-title>
              <v-list-item-subtitle>Business Name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-email-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.email }}</v-list-item-title>
              <v-list-item-subtitle>Email</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-map-marker-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.address }}</v-list-item-title>
              <v-list-item-subtitle>Address</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-phone-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.phone_number }}</v-list-item-title>
              <v-list-item-subtitle>Phone number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.industry.name }}</v-list-item-title>
              <v-list-item-subtitle>Industry</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-flag-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.country.name }}</v-list-item-title>
              <v-list-item-subtitle>Country</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-currency-sign
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.currency.code }}</v-list-item-title>
              <v-list-item-subtitle>Currency</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :class="$vuetify.theme.isDark ? 'black' : 'white'" class="rounded-lg mt-2">
            <v-list-item-icon class="mr-1 pa-0">
              <v-icon color="orange" large>
                mdi-account-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black">{{ business.employees_size }}</v-list-item-title>
              <v-list-item-subtitle>Company size</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>


          <v-btn @click="setEditBusiness" block color="orange" rounded depressed x-large dark class="mt-4">Edit
            Business
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>


    <v-dialog
        :persistent="loading"
        scrollable
        width="500"
        v-model="showEditDialog"
    >
      <v-card>
        <v-card-title>Edit business info.</v-card-title>
        <v-card-text>
          <v-form ref="updateBusinessForm">
            <v-img src=""></v-img>
            <v-text-field
                color="orange"
                outlined
                placeholder="Business Name"
                v-model="name"
                :rules="[$store.state.validationRules.required]"
            ></v-text-field>

            <phone-number-input
                :default_number="phone_number"
                @input="n=>phone_number_full=n"></phone-number-input>

            <v-text-field
                color="orange"
                outlined
                placeholder="Business Email"
                v-model="email"
                :rules="[$store.state.validationRules.required,$store.state.validationRules.email]"

            ></v-text-field>
            <v-textarea
                placeholder="Business address"
                rows="2"
                outlined
                color="orange"
                v-model="address"
                auto-grow
                :rules="[$store.state.validationRules.required]"
            ></v-textarea>
            <v-autocomplete
                color="orange"
                outlined
                placeholder="Country"
                :items="countries"
                item-text="name"
                item-value="id"
                item-color="orange"
                :rules="[$store.state.validationRules.required]"
                v-model="country_id"
            >

              <template v-slot:selection="{item}">
                <v-list-item-icon class="mr-0">
                  <flag :iso="item.code"></flag>
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:item="{item}">
                <v-list-item-icon class="mr-0">
                  <flag :iso="item.code"></flag>
                </v-list-item-icon>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

            </v-autocomplete>

            <v-autocomplete
                color="orange"
                outlined
                placeholder="Industry"
                :items="industries"
                item-value="id"
                item-text="name"
                item-color="orange"
                v-model="industry_id"
                :rules="[$store.state.validationRules.required]"

            ></v-autocomplete>

            <v-autocomplete
                :items="businessSizes"
                color="orange"
                outlined
                placeholder="Business Size"
                item-color="orange"
                v-model="size"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="loading" @click="saveChanges" block x-large rounded depressed color="orange" dark>Save Changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import {defineComponent} from 'vue'
import PhoneNumberInput from "./PhoneNumberInput.vue";

export default defineComponent({
  name: "BusinessProfileComponent",
  components: {PhoneNumberInput},
  data() {
    return {
      name: "",
      address: "",
      email: "",
      industry_id: null,
      currency_id: null,
      country_id: null,
      loading: false,
      size: "",
      phone_number: "",
      showEditDialog: false,
      currencies: [],
      countries: [],
      logo: null,
      phone_number_full: "",
      industries: [],
      businessSizes: [
        {
          value: "less_than_5_employees",
          text: "Less than 5 employees",
        },
        {
          value: "5_to_10_employees",
          text: "5 to 10 employees",
        },
        {
          value: "10_to_30_employees",
          text: "10 to 30 employees",
        },

        {
          value: "30_to_50_employees",
          text: "30 to 50 employees",
        },
        {
          value: "More_than_50_employees",
          text: "More than 50 employees",
        },
      ],
    }
  },
  computed: {
    business() {
      return this.$store.state.user.current_business;
    }
  },
  methods: {

    selectFile(){

      document.getElementById("logoSelector").click();

    },
    updatePhoto(){
this.loading=true;
      const formData = new FormData();
      formData.append("logo",this.logo);

      axios.post("/api/changelogo",formData)
          .then(res=>{
            this.$store.state.user.current_business.logo = res.data;
            this.loading=false;
            this.logo=null;

          })
          .catch(()=>{
            this.loading=false;

          })
    },

    getCountries() {
      axios.get("/api/getcountries")
          .then(res => {

            this.countries = res.data;

            if (this.userCountry) {

              const currentCountry = this.countries.find(country => {

                return country.code.toLowerCase() == this.userCountry.alpha2Code.toLowerCase();

              });

              if (currentCountry) {

                this.country_id = currentCountry.id;

              }

            }


          })
    },
    saveChanges() {

      if (this.$refs.updateBusinessForm.validate()) {
        this.loading = true;

        const data = {
          name: this.name,
          country_id: this.country_id,
          industry_id: this.industry_id,
          address: this.address,
          phone_number: this.phone_number_full,
          employee_size: this.size,
          email: this.email

        };

        axios.post("/api/business/" + this.business.id, data)
            .then(res => {
              location.reload();
              this.loading=false;

            })
            .catch(()=>{
              this.loading=false;
            })


      }

    },
    getCurrencies() {

      axios.get("/api/getcurrencies")
          .then(res => {
            this.currencies = res.data

          })
    },
    getIndustries() {

      axios.get("/api/getindustries")
          .then(res => {
            this.industries = res.data
          })
    },

    setEditBusiness() {

      this.name = this.business.name;
      this.address = this.business.address;
      this.email = this.business.email;
      this.industry_id = this.business.industry_id;
      this.currency_id = this.business.currency_id;
      this.country_id = this.business.country_id;
      this.size = this.business.employees_size;
      this.phone_number = this.business.phone_number.slice(-9)
      this.showEditDialog = true;

    }

  },
  mounted() {

    this.getCurrencies();
    this.getIndustries();
    this.getCountries();

  }
})
</script>

<style scoped>
.dark-orange-bg {
  background-color: #FF98000D;
}
</style>