<template>
<v-container>
  <v-card flat>
  <v-card-title>
    <v-tabs color="orange">
      <v-tab to="/reports">
        Income Statement
      </v-tab>

      <v-tab to="/reports/cash-register">
        Cash Register
      </v-tab>

      <v-tab to="/reports/products-income">
        Income By Products
      </v-tab>

    </v-tabs>
  </v-card-title>
  <v-card-text>

      <router-view></router-view>


  </v-card-text>
</v-card>
</v-container>

</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "index"
})
</script>

<style scoped>

</style>