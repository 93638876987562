<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" sm="8" class="mx-auto">
        <v-row dense v-if="loading">
          <v-col cols="6">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader type="list-item@10"></v-skeleton-loader>
          </v-col>

        </v-row>
        <span v-else>
        <v-row dense v-if="summaries">
          <v-col cols="10">
            <h2>{{from | humanDate}} - {{to | humanDate}}</h2>
          </v-col>
          <v-col cols="2">
          <v-menu transition="slide-y-transition" :close-on-content-click="false" min-width="500">
            <template v-slot:activator="{on}">
              <v-btn outlined color="orange" v-on="on" x-large>Filter<v-icon>mdi-filter-menu-outline</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title>Filter orders</v-card-title>
              <v-card-text>
                <v-menu transition="slide-y-transition" min-width="auto">
                  <template v-slot:activator="{on}">
                    <v-text-field outlined color="orange" v-on="on" v-model="from" label="From"></v-text-field>
                  </template>
                  <v-date-picker no-title color="orange" v-model="from"></v-date-picker>
                </v-menu>

                   <v-menu transition="slide-y-transition" min-width="auto">
                  <template v-slot:activator="{on}">
                    <v-text-field outlined color="orange" v-on="on" v-model="to" label="To"></v-text-field>
                  </template>
                  <v-date-picker no-title color="orange" v-model="to"></v-date-picker>
                </v-menu>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="getSummaries" block color="orange" rounded depressed x-large dark>Apply Filter</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          </v-col>
          <v-col cols="12" sm="6">
            <v-card flat>
              <v-card-text class="text-center">
                <v-row dense>
                  <v-col cols="4">
                    <v-avatar class="orange lighten-5" size="70">
                      <v-icon size="40" color="orange">mdi-shopping-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <h1 class="orange--text mt-5">{{ Number(summaries.total_summary.total_orders) | short_number }}</h1>
                    <small>Total Number of orders</small>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card flat>
              <v-card-text class="text-center">
                <v-row dense>
                  <v-col cols="4">
                    <v-avatar class="green lighten-5" size="70">
                      <v-icon size="40" color="green">mdi-wallet-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="8">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <h1 v-on="on" class="green--text mt-5">
                          {{ Number(summaries.total_summary.total_amount) | short_number | currency_symbol }}</h1>
                      </template>
                      <span>{{ Number(summaries.total_summary.total_amount) | toMoney | currency_symbol }}</span>
                    </v-tooltip>
                    <small>Total Amount</small>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card flat class="mt-4">
          <v-card-title>Order Status summary</v-card-title>
          <v-card-text>
            <v-list v-if="summaries">
              <v-list-item class="border-bottom"  v-for="(item,index) in summaries.state_summary" :key="index">
                <v-list-item-avatar>
                  <v-icon x-large :color="progressColors[item.state]">mdi-flag-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>

                <v-list-item-title
                    class="font-weight-black"
                    :class="{
                'blue--text':item.state=='pending',
                'cyan--text':item.state=='processing',
                'red--text':item.state=='cancelled',
                'green--text':item.state=='completed',
                'purple--text':item.state=='confirmed',
                'pink--text':item.state=='failed',
                'orange--text':item.state=='returned',
              }"
                >{{ item.state }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-list-item-subtitle>{{item.total_orders}} Orders</v-list-item-subtitle>
                  <v-progress-linear
                      :color="progressColors[item.state]"
                      :value="getPercent(item.total_amount)"></v-progress-linear>
                </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text>
                  {{item.total_amount |  toMoney | currency_symbol}}
                </v-list-item-action-text>

              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        </span>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {defineComponent} from 'vue'
import moment from "moment/moment";

export default defineComponent({
  name: "orderSummaries",
  data() {
    return {
      summaries: null,
      progressColors:{
        pending:"blue",
        processing:"cyan",
        cancelled:"red",
        completed:"green",
        confirmed:"purple",
        failed:"pink",
        returned:"orange",
      },
      loading:false,
      from:"",
      to:""
    }
  },
  computed: {
    totalState() {

      let sum = 0;

      if (this.summaries) {

        this.summaries.state_summary.forEach(state => {
          sum += Number(state.total_amount)
        })
      }

      return sum;
    }
  },
  methods: {

    getPercent(amount) {

      const ratio = amount / this.totalState;
      return ratio * 100;
    },

    getSummaries() {

      this.loading=true;
      const URL = "/api/pos/orders-summary?from="+this.from+"&to="+this.to;
      axios.get(URL)
          .then(res => {
            this.summaries = res.data;
            this.loading=false;

          })
          .catch(() => {

          })

    }
  },
  mounted() {
    this.getSummaries();
    this.from = moment().subtract(1,"months").format("Y-M-D");
    this.to = moment().format("Y-M-D");
  }
})
</script>


<style scoped>

</style>