<template>
  <v-card flat>
    <v-card-title>
      <h2 class="font-weight-bolder">
        {{ products.length }} Products & Services
      </h2>
      <v-menu offset-y transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" depressed x-large rounded class="m-3">
            Export
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              :href="
							baseURL +
							'/downloadproductsPDF/analytics/' +
							business.enc_id +
							'/?from=' +
							dates[0] +
							'&to=' +
							dates[1]
						"
          >
            <v-list-item-title> Download PDF Format</v-list-item-title>
          </v-list-item>

          <v-list-item
              :href="
							baseURL +
							'/downloadproductsexcel/analytics/' +
							business.enc_id +
							'/?from=' +
							dates[0] +
							'&to=' +
							dates[1]
						"
          >
            <v-list-item-title> Download Excel Format</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-menu
          v-model="Dates_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          :close-on-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="dates"
              label="Period"
              readonly
              flat
              filled
              rounded
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            show-adjacent-months
            :multiple="true"
            range
            no-title
            v-model="dates"
        ></v-date-picker
        >
        <br/>
        <v-btn
            @click="
						Dates_menu = false;
						get_summary();
					"
            color="blue"
            text
            small
            :disabled="dates.length < 2"
        >Apply
        </v-btn
        >
        <v-btn text small @click="Dates_menu = false">
          Cancel
        </v-btn>
      </v-menu>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>

          <apexchart
              v-else
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
          ></apexchart>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            :class="$vuetify.theme.isDark ? '' : 'bg-light'"
        >
          <v-row>
            <V-col cols="12" sm="6">
              <v-card flat class="text-center pa-5">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attr }">
                    <h1
                        v-on="on"
                        v-bind="attr"
                        class="font-weight-light mb-0 green--text"
                    >
                      {{ TOTAL_PURCHASES | short_number | currency_symbol }}
                    </h1>
                  </template>
                  <span>{{ TOTAL_PURCHASES | toMoney | currency_symbol }}</span>
                </v-tooltip>
                <small>Total Purchases</small>
              </v-card>
            </V-col>

            <V-col cols="12" sm="6">
              <v-card flat class="text-center pa-5">
                <h1 class="font-weight-light mb-0">
                  {{ TOTAL_QTY_PURCHASED | short_number }}
                </h1>
                <small>Total QTY Purchased</small>
              </v-card>
            </V-col>

            <V-col cols="12" sm="6">
              <v-card flat class="text-center pa-5">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attr }">
                    <h2
                        v-on="on"
                        v-bind="attr"
                        class="font-weight-light blue--text mb-0"
                    >
                      {{ TOTAL_SALE | short_number | currency_symbol }}
                    </h2>
                  </template>
                  <span>{{ TOTAL_SALE | toMoney | currency_symbol }}</span>
                </v-tooltip>
                <small>Total Sales</small>
              </v-card>
            </V-col>

            <V-col cols="12" sm="6">
              <v-card flat class="text-center pa-5">
                <h2 class="font-weight-light mb-0">
                  {{ TOTAL_QTY_SOLD | short_number }}
                </h2>
                <small>Total QTY Sold</small>
              </v-card>
            </V-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
          <v-data-table
              v-else
              id="virtual-scroll-table"
              v-scroll:#virtual-scroll-table="onScroll"
              :headers="headers"
              :items="transformedProducts"
              item-key="name"
              disable-pagination
              hide-default-footer
          >
            <template v-if="start > 0" v-slot:body.prepend>
              <tr>
                <td
                    :colspan="headers.length"
                    :style="'padding-top:' + startHeight + 'px'"
                ></td>
              </tr>
            </template>
            <template
                v-if="start + perPage < this.transformedProducts.length"
                v-slot:body.append
            >
              <tr>
                <td
                    :colspan="headers.length"
                    :style="'padding-top:' + endHeight + 'px'"
                ></td>
              </tr>
            </template>
            <template v-slot:item.purchases="{ item }">
              <v-chip color="green lighten-5" class="green--text">
                {{ item.purchases | absolute | currency_symbol }}
              </v-chip>
            </template>
            <template v-slot:item.sales="{ item }">
              <v-chip color="blue lighten-5" class="blue--text">
                {{
                  item.sales
                      | absolute
                      | currency_symbol
                }}
              </v-chip>
            </template>
            <template v-slot:item.margin="{ item }">

              {{
                item.margin
                    | absolute
                    | currency_symbol
              }}

            </template>
            <template v-slot:item.item="{ item }">
              <strong>{{ item.item }}</strong>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "allproducts",

  data() {
    return {
      dates: [],
      Dates_menu: false,
      products: [],
      transformedProducts: [],
      loading: true,
      series: [
        {
          name: "Purchases",
          type: "bar",
          color: "#00E395",
          data: [],
        },
        {
          name: "Sales",
          type: "bar",
          color: "#2196F3",
          data: [],
        },
      ],
      chartOptions: {
        fill: {
          opacity: 0.55,
          colors: ["#2196F3", "#00E395"],
          gradient: {
            enabled: true,
            opacityFrom: 0.55,
            opacityTo: 0,
          },
        },
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [0, 0],
          curve: "smooth",
        },
        title: {
          text: "Products & Services Analysis",
          align: "left",
          offsetX: 110,
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
            },
            title: {
              text: "Sales and purchases",
              style: {
                color: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft",
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      headers: [
        {
          text: "Name",
          align: "start",
          value: "item",
        },
        {text: "Purchases", value: "purchases"},
        {text: "Quantity Purchased", value: "quantity_purchased"},
        {text: "Sales", value: "sales"},
        {text: "Quantity Sold", value: "quantity_sold"},
        {text: "Margin", value: "margin"},
      ],
      start: 0,
      timeout: null,
      rowHeight: 45,
      perPage: 6,
    };
  },

  mounted() {
    let startdate = moment().format("YYYY-MM-DD");
    startdate = startdate.substring(0, 8) + "01";
    let enddate = moment().format("YYYY-MM-DD");
    this.dates.push(startdate);
    this.dates.push(enddate);
    this.get_summary();
  },

  computed: {
    TOTAL_PURCHASES() {
      let sum = 0;
      if (this.products) {
        this.products.forEach((item) => {
          sum += Number(item.purchase_amount);
        });

        return sum;
      }
    },

    TOTAL_QTY_PURCHASED() {
      let sum = 0;
      if (this.products) {
        this.products.forEach((item) => {
          sum += Number(item.purchase_quantity);
        });

        return sum;
      }
    },
    TOTAL_SALE() {
      let sum = 0;
      if (this.products) {
        this.products.forEach((item) => {
          sum += Number(item.sales_amount) + Number(item.invoice_amount);
        });

        return sum;
      }
      return sum;
    },
    TOTAL_QTY_SOLD() {
      let sum = 0;
      if (this.products) {
        this.products.forEach((item) => {
          sum += Number(item.sales_quantity) + Number(item.invoice_quantity);
        });

        return sum;
      }
    },

    baseURL() {
      return this.$store.state.baseUrl;
    },
    business() {
      return this.$store.state.user.current_business;
    },
    startHeight() {
      return this.start * this.rowHeight - 32;
    },
    endHeight() {
      return this.rowHeight * (this.transformedProducts.length - this.start);
    },
  },
  methods: {
    set_chart(products) {
      this.chartOptions.title.text =
          "Products & Services Analysis (" +
          this.dates[0] +
          " - " +
          this.dates[1] +
          ")";
      this.series[0].data = [];
      this.series[1].data = [];
      this.chartOptions.xaxis.categories = [];
      products.forEach((product) => {
        let income = (product.sales_amount - product.purchase_amount).toFixed(
            2
        );
        if (income != 0) {
          this.series[0].data.push(product.purchase_amount.toFixed(2));
          this.series[1].data.push(product.sales_amount.toFixed(2));
          this.chartOptions.xaxis.categories.push(product.name);
        }
      });
    },
    get_summary() {
      this.loading = true;
      axios
          .get("/api/analytics/products/" + this.dates[0] + "/" + this.dates[1])
          .then((res) => {
            this.products = res.data;
            // TRANSFORM PRODUCTS ARRAY FOR TABLE
            this.transformedProducts = this.transformProducts(this.products);
            this.set_chart(this.products);
            this.loading = false;
          });
    },
    transformProducts(products) {
      return products.map((product) => {
        return {
          item: product.name,
          purchases: product.purchase_amount,
          quantity_purchased: product.purchase_quantity,
          sales: product.invoice_amount + product.sales_amount,
          quantity_sold:
              Number(product.sales_quantity) + Number(product.invoice_quantity),
          margin: (
              product.invoice_amount +
              product.sales_amount -
              product.purchase_amount
          ).toFixed(2),
        };
      });
    },
    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const {scrollTop} = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start =
            rows + this.perPage > this.transformedProducts.length
                ? this.transformedProducts.length - this.perPage
                : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 30);
    },
  },
};
</script>

<style scoped>
#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}
</style>
