<template>
  <div class="text-center" style="margin-top: 50px">
    <center>
      <div
          class="orange lighten-5"
          style="width: 100px; height: 100px; border-radius: 50%"
      >
        <v-icon
            class="mt-4"
            color="orange"
            style="font-size: 60px; margin: auto"
        >mdi-receipt-text-check-outline</v-icon
        >
      </div>

      <p class="orange--text" style="font-size: 1.5rem">
       {{title}}
      </p>
      <small class="text--disabled"
      >{{description}}</small
      >
    </center>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "noRecordComponent",
  props:{
    title:{
      type:String,
      default:"No Record"
    },
    description:{
      type:String,
      default:"You do not have transactions here"
    }
  }
})
</script>


<style scoped>

</style>