<template>
  <span>
    <v-list-item
      class="ma-2 cart-item"
      dense
      selectable
      style="border: 1px solid #e8e8e8; border-radius: 15px"
      two-line
    >
      <v-list-item-avatar
        height="80px"
        rounded
        style="border-radius: 15px !important"
        width="80px"
      >
        <v-img :src="item.photo"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold">
          {{ item.name }}

          <v-btn
            class="align-self-end mr-auto"
            color="red"
            icon
            small
            style="float: right !important"
            title="Click to remove this item"
            @click="$emit('remove', index)"
          >
            <v-icon small>mdi-delete-outline</v-icon>
          </v-btn>
        </v-list-item-title>
        <v-list-item-subtitle>
          <h3 class="orange--text font-weight-bold">
            {{ business.currency.code }}{{ amount | toMoney }}
          </h3>
          <!-- <small v-if="Number(total_discount) !== 0" class="text--disabled"
          >Discount:-{{ business.currency.code
          }}{{ total_discount | toMoney }}</small
        > -->
          <small v-if="discount_amount" class="text--disabled"
            >Discount:-{{ business.currency.code
            }}{{ discount_amount | toMoney }}</small
          >
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-text-field
            v-model="quantity"
            :hint="item.net_amount + ' x ' + quantity"
            color="orange"
            dense
            flat
            min="0"
            solo
            style="
              border: none !important;
              background-color: transparent !important;
            "
            type="number"
          >
            <template v-slot:append-outer>
              <v-btn
                class="pa-0"
                color="orange"
                dark
                depressed
                width="3px"
                x-small
                @click="
                  quantity++;
                  $emit('quantityChanged');
                "
              >
                <v-icon x-small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:prepend>
              <v-btn
                color="orange lighten-2"
                dark
                depressed
                width="5px"
                x-small
                @click="
                  quantity > 0 ? quantity-- : (quantity = 0);
                  $emit('quantityChanged');
                "
              >
                <v-icon x-small>mdi-minus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-list-item-subtitle>

        <v-list-item-subtitle>
          <small class="d-block text--disabled">{{ description }}</small>

          <v-chip v-if="taxes.length" color="orange" outlined x-small>
            {{ taxes.length }} tax{{ taxes.length > 1 ? "es" : "" }} applied
            <v-btn
              :disabled="!settings.can_change_taxes"
              color="red"
              icon
              x-small
              @click="taxes = []"
              ><v-icon title="remove taxes" x-small>mdi-close</v-icon></v-btn
            >
          </v-chip>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="error.status" class="red--text">
          {{ error.message }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          class="mr-auto"
          color="orange"
          icon
          outlined
          small
          title="Edit this item in advance mode"
          @click="detailsDialog = true"
        >
          <v-icon small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      v-model="detailsDialog"
      persistent
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title>
          <h3 class="font-weight-light">{{ item.name }}</h3>
          <v-spacer></v-spacer>
          <v-btn
            color="red lighten-5 red--text"
            depressed
            rounded
            small
            @click="detailsDialog = false"
            >close <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <center>
            <v-avatar size="250" style="border-radius: 15px">
              <v-img :src="item.photo"></v-img>
            </v-avatar>
          </center>

          <v-list class="mt-3 mr-5 ml-5">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title
                  class="text-h4 font-weight-light text-center orange--text"
                  >Sub Total:{{ business.currency.code
                  }}{{ amount | toMoney }}</v-list-item-title
                >

                <v-list-item-subtitle
                  v-if="tax_amount"
                  class="text-h5 font-weight-light text-center orange--text"
                >
                  Taxes:{{ business.currency.code }}{{ tax_amount | toMoney }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                  class="text-h5 font-weight-light text-center orange--text"
                >
                  Amount Due:{{ business.currency.code
                  }}{{ net_amount | toMoney }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="ma-2 cart-item"
              dense
              selectable
              style="border: 1px solid #e8e8e8; border-radius: 15px"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title class="pt-3">
                  <v-text-field
                    v-model="quantity"
                    :hint="item.net_amount + ' x ' + quantity"
                    color="orange"
                    dense
                    flat
                    min="0"
                    persistent-hint
                    solo
                    style="
                      border: none !important;
                      background-color: transparent !important;
                    "
                    type="number"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        class="pa-0"
                        color="orange"
                        dark
                        depressed
                        width="3px"
                        x-small
                        @click="
                          quantity++;
                          $emit('quantityChanged');
                        "
                      >
                        <v-icon x-small>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:prepend>
                      <v-btn
                        color="orange lighten-2"
                        dark
                        depressed
                        width="5px"
                        x-small
                        @click="
                          quantity > 0 ? quantity-- : (quantity = 0);
                          $emit('quantityChanged');
                        "
                      >
                        <v-icon x-small>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-list-item-title>
                <v-list-item-subtitle v-if="error.status" class="red--text">
                  {{ error.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense>
              <v-list-item-title>
                <v-text-field
                  v-model="unit_price"
                  :disabled="!settings.can_change_unit_price"
                  :prefix="business.currency.code"
                  @input="quantity = quantity"
                  class="mt-2"
                  color="orange"
                  type="number"
                  min="0"
                  label="Unit price"
                  outlined
                ></v-text-field>
              </v-list-item-title>
            </v-list-item>
            <!-- <v-alert style="width: 400px" v-if="total_discount"
            outlined type="warning" prominent
            border="left">
            A general discount has already been applied, so attempting to apply a discount here will be ineffective until you remove the general discount
        </v-alert> -->
            <v-list-item>
              <v-list-item-title>
                <v-switch
                  inset
                  class="mx-2"
                  v-model="isDiscountPercent"
                  label="Enter discount %"
                  color="orange"
                ></v-switch>
                <v-text-field
                  :class="snackbar ? 'red--text' : ''"
                  type="number"
                  v-model="discount"
                  :disabled="!settings.can_change_discounts"
                  :prefix="isDiscountPercent ? '%' : business.currency.code"
                  class="mt-2"
                  :color="snackbar ? 'red' : 'orange'"
                  :label="
                    isDiscountPercent
                      ? !snackbar
                        ? 'Discount Percent'
                        : 'Incorrect Discount Percent'
                      : snackbar
                      ? 'Incorrect Discount Amount'
                      : 'Absolute Discount Amount'
                  "
                  outlined
                ></v-text-field>
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="mt-3" dense>
              <v-list-item-title
                class="text-h3 font-weight-light text-center orange--text"
              >
                <v-autocomplete
                  v-model="taxes"
                  :disabled="!settings.can_change_taxes"
                  :items="business.taxes"
                  chips
                  color="orange"
                  deletable-chips
                  filled
                  flat
                  item-color="orange"
                  item-text="display_name"
                  item-value="id"
                  label="Add taxes"
                  multiple
                  solo
                  @change="callUpdate()"
                  @input="callUpdate()"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.display_name }}({{
                          item.rate
                        }}%)</v-list-item-title
                      >
                      <v-list-item-subtitle v-if="item.type === 'Compound'">
                        Includes:<span v-for="sub in item.sub_tax" :key="sub.name">
                          {{ sub.name }}({{ sub.rate }}%),
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-textarea
                  v-model="description"
                  auto-grow
                  class="mt-4"
                  color="orange"
                  flat
                  label="Description"
                  outlined
                  rows="2"
                ></v-textarea>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-list-item
            class="orange lighten-5 orange--text rounded"
            style="border-radius: 15px !important"
            two-line
          >
            <v-list-item-icon class="ma-auto">
              <v-icon class="ma-auto" color="orange" x-large
                >mdi-tag-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="item.discount_percent ? 'cancel-text' : ''"
                class="text-h4 font-weight-light"
                >{{ business.currency.code
                }}{{ item.selling_price | toMoney }}</v-list-item-title
              >
              <v-list-item-subtitle
                v-if="item.discount_percent"
                class="text-h5 font-weight-light orange--text"
              >
                {{ business.currency.code }}
                {{ item.net_amount | toMoney }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action-text v-if="item.discount_percent">
              <v-chip
                class="font-weight-bold"
                color="orange lighten-4 orange--text"
                large
              >
                {{ item.discount_percent }}% - OFF
              </v-chip>
            </v-list-item-action-text>
          </v-list-item>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" color="red" :timeout="snackbarTimeout">{{
        snackbarMessage
      }}</v-snackbar>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: ["item", "index", "total_discount"],
  name: "cartItem",
  data() {
    return {
      quantity: 1,
      showMenu: false,
      taxes: [],
      detailsDialog: false,
      description: "",
      discount: this.item.discount_amount,
      unit_price: 0,
      isDiscountPercent: false,
      discountPercent: 0,
      absoluteDiscount: 0,
      snackbar: false,
      snackbarMessage: "",
      snackbarTimeout: 0,
    };
  },
  computed: {
    businessTaxes() {
      return this.$store.state.taxes;
    },

    settings() {
      return this.$store.state.settings;
    },

    tax_amount() {
      let sum = 0;

      if (this.taxes.length) {
        const net_amount =
          Number(this.gross_amount) - Number(this.discount_amount);

        this.taxes.forEach((t) => {
          const tax = this.business.taxes.find((item) => item.id == t);

          if (tax.type.toLowerCase() === "flat") {
            sum += (Number(tax.rate) / 100) * Number(net_amount);
          } else {
            const subTax =
              tax.sub_rate > 0
                ? (Number(tax.sub_rate) / 100) * Number(net_amount)
                : 0;

            const compoundAmount = net_amount + Number(subTax);

            const totaltax = Number(tax.rate / 100) * Number(compoundAmount);

            sum += Number(subTax) + Number(totaltax);
          }
        });
      }

      return sum;
    },

    itemTemplate() {
      return {
        index: this.index,
        quantity: this.quantity,
        amount: this.gross_amount,
        id: this.item.id,
        discount_amount: this.discount_amount,
        taxes: this.taxes,
        unit_price: this.unit_price,
        amount_due: this.tax_amount + this.amount,
        tax_amount: this.tax_amount,
        description: this.description,
      };
    },

    error() {
      if (
        this.item.type.toLowerCase() == "product" &&
        this.item.track_inventory
      ) {
        if (this.quantity > Number(this.item.quantity)) {
          return {
            status: true,
            message:
              "The quantity you entered is higher than the current item quantity",
          };
        }

        return {
          status: false,
          message: "",
        };
      }
      return {
        status: false,
        message: "",
      };
    },
    business() {
      return this.$store.state.user.current_business;
    },
    amount() {
      return this.unit_price * this.quantity;
    },

    gross_amount() {
      return Number(this.unit_price) * this.quantity;
    },
    discount_amount() {
      if (this.settings.can_change_discounts) {
        if (this.isDiscountPercent) {
          return (this.discount / 100) * this.unit_price * this.quantity;
        }
        return Number(this.discount) * this.quantity;
      }

      return Number(this.quantity) * Number(this.discount);
    },
    invoice_quantity() {
      return this.item.invoice_quantity;
    },
    net_amount() {
      return this.amount + this.tax_amount;
    },
  },
  watch: {
    isDiscountPercent(newval) {
      console.log(this.discount);
      this.discountComputation();
    },
    discount(newDiscount, oldDiscount) {
      if (isNaN(this.discount) || this.discount < 0) {
        this.snackbar = true;
        this.snackbarMessage = "Please enter a valid number!";
        this.snackbarTimeout = -1;
      } else {
        this.snackbarTimeout = 5000;
        this.snackbar = false;
      }
        this.callUpdate();
    },
    total_discount(){
      this.discount = this.item.discount_amount
      this.callUpdate()
    },
    description() {
      this.callUpdate();
    },
    tax_amount() {
      this.$emit("changed", this.itemTemplate);
    },
    amount(){
      this.$emit("changed", this.itemTemplate)
    },  

    invoice_quantity() {
      this.quantity = this.invoice_quantity;

      this.$emit("changed", this.itemTemplate);
    },
    quantity() {
      this.$emit("changed", this.itemTemplate);
    },
    item() {
      this.quantity = this.item.invoice_quantity;

      this.$emit("changed", this.itemTemplate);
    },
  },
  methods: {
    callUpdate() {
      this.$emit("changed", this.itemTemplate);
    },
    // TODO CHECK WHY SWITCHING BACK AND FORTH MESSES WITH THE DECIMAL PLACES
    discountComputation() {
      if (this.isDiscountPercent) {
        this.discount = (this.discount / this.unit_price) * 100;
      } else {
        this.discount = (this.discount / 100) * this.unit_price;
      }
      // console.log(this.discount);
    },
  },

  mounted() {
    const taxList = [];
    this.businessTaxes.forEach((id) => {
      if (Number(id) > 0) {
        taxList.push(Number(id));
      }
    });

    this.taxes = taxList;

    this.quantity = this.item.invoice_quantity;
    this.discount = this.item.discount_amount;

    this.unit_price = this.item.selling_price;
    this.callUpdate();
  },
};
</script>

<style>
.cart-item {
  transition: 0.25s ease-in-out;
}

.cart-item:hover {
  border: 1px solid #ff9800 !important;
  background-color: rgba(255, 152, 0, 0.1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.border-bottom {
  border-bottom: 1px solid #eee;
}
</style>
