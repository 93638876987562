<template>
  <span>
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <span v-else>
      <v-row v-if="!item" dense>
        <v-col class="mx-auto" cols="12" sm="7">
          <div class="text-center" style="margin-top: 50px">
            <center>
              <div
                class="orange lighten-5"
                style="width: 100px; height: 100px; border-radius: 50%"
              >
                <v-icon
                  class="mt-4"
                  color="orange"
                  style="font-size: 60px; margin: auto"
                  >mdi-receipt-text-check-outline</v-icon
                >
              </div>

              <p class="orange--text" style="font-size: 1.5rem">
                No transaction selected
              </p>
              <small class="text--disabled"
                >The preview of a selected transaction would show here, to
                preview a transaction select a transaction from the left
                pane</small
              >
            </center>
          </div>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col class="ml-auto" cols="12" sm="11">
          <v-card class="mt-5" flat>
            <v-card-text class="pa-6 text-center orange" style="height: 200px">
              <v-chip color="orange lighten-2" dark x-large
                ><v-icon>mdi-tag-outline</v-icon> {{ item.sales_number }} :
                {{ formatDate(item.entry_date) }}</v-chip
              >
              <h2 class="white--text font-weight-light mt-4">
                {{ currency.code }}{{ item.amount_due | toMoney }}
                <v-btn
                  class="ml-2"
                  color="orange lighten-2"
                  dark
                  depressed
                  fab
                  @click="print"
                  ><v-icon>mdi-printer-outline</v-icon></v-btn
                >
                <v-menu transition="slide-y-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ml-2"
                      color="orange lighten-2"
                      dark
                      depressed
                      fab
                      v-on="on"
                      ><v-icon>mdi-menu</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item @click="sendDialog = true">
                      <v-list-item-action-text>
                        <v-icon class="mr-2">mdi-share-outline</v-icon>
                      </v-list-item-action-text>
                      <v-list-item-content>
                        <v-list-item-title>Send Receipt</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :href="
                        $store.state.baseUrl + '/viewreceipt/' + item.enc_id
                      "
                      target="_blank"
                    >
                      <v-list-item-action-text>
                        <v-icon class="mr-2">mdi-link</v-icon>
                      </v-list-item-action-text>
                      <v-list-item-content>
                        <v-list-item-title>View Receipt</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="settings.can_delete"
                      @click="deleteDialog = true"
                    >
                      <v-list-item-action-text>
                        <v-icon class="mr-2" color="red"
                          >mdi-delete-outline</v-icon
                        >
                      </v-list-item-action-text>
                      <v-list-item-content>
                        <v-list-item-title class="red--text"
                          >Delete</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </h2>
              <small class="white--text">Amount Due</small>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Item</th>
                    <th class="text-right">Amount</th>
                    <th class="text-right">Discount Amount</th>
                    <th class="text-right">Tax Amount</th>
                    <th class="text-right">Amount Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in item.items" :key="item.id">
                    <td>
                      <v-avatar
                        class="ma-2"
                        rounded
                        size="60"
                        style="border-radius: 15px !important"
                        ><v-img
                          :src="item.details.photo"
                        ></v-img
                      ></v-avatar>
                    </td>
                    <td>{{ item.name }}</td>
                    <td class="text-right">
                      {{ currency.code }}{{ item.amount | toMoney }}
                    </td>
                    <td class="text-right">
                      {{ currency.code }}{{ item.discount_amount | toMoney }}
                    </td>
                    <td class="text-right">
                      {{ currency.code }}{{ item.tax_amount | toMoney }}
                    </td>
                    <td class="font-weight-black text-right">
                      {{ currency.code }}{{ item.amount_due | toMoney }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-divider></v-divider>

              <v-row class="mt-4">
                <v-col class="text-right" cols="12">
                  <h4 class="text-h6 font-weight-light">
                    Subtotal: {{ currency.code
                    }}{{ item.gross_amount | toMoney }}
                  </h4>
                  <h4 class="text-h6 font-weight-light">
                    Discount: ({{ currency.code
                    }}{{ item.discount_amount | toMoney }})
                  </h4>
                  <span v-for="tax in item.taxes" :key="tax.id">
                    <template v-if="tax.type === 'Compound'">
                      <h4
                        v-for="sub_tax in tax.sub_tax"
                        :key="sub_tax.id"
                        class="text-h6 font-weight-light"
                      >
                        {{ sub_tax.name }}({{ sub_tax.rate }}%):{{
                          currency.code
                        }}{{
                          ((sub_tax.rate / 100) * tax.total_amount) | toMoney
                        }}
                      </h4>

                      <h4 class="text-h6 font-weight-light">
                        {{ tax.name }}({{ tax.rate }}%):{{ currency.code
                        }}{{ tax.tax_amount | toMoney }}
                      </h4>
                    </template>
                    <template v-else>
                      <h4 class="text-h6 font-weight-light">
                        {{ tax.name }}({{ tax.rate }}%):{{ currency.code
                        }}{{ tax.tax_amount | toMoney }}
                      </h4>
                    </template>
                  </span>

                  <h4 class="font-weight-black text-h6">
                    Amount Due: {{ currency.code
                    }}{{ item.amount_due | toMoney }}
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="ml-auto" cols="12" sm="11">
          <v-card flat>
            <v-list-item two-line>
              <v-list-item-avatar rounded>
                <v-img
                  src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/user.png"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h4">{{
                  item.server_name
                }}</v-list-item-title>
                <v-list-item-subtitle>Served by</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col
          v-if="item && item.pos_sale && paymentTransaction"
          class="ml-auto"
          cols="12"
          sm="11"
        >
          <v-card color="orange lighten-5" flat>
            <v-list-item>
              <v-list-item-title class="font-weight-bold"
                >Payment Info:</v-list-item-title
              >
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-avatar rounded size="80">
                <v-img
                  src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/user.png"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h5 orange--text"
                  >By: {{ paymentTransaction.account_name }}</v-list-item-title
                >
                <v-list-item-subtitle
                  >Account Code: {{ paymentNetwork }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Account Number:
                  {{ paymentTransaction.account_number }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  color="error"
                  dark
                  depressed
                  rounded
                  x-large
                  @click="reversal_dialog = true"
                  >Reverse<v-icon x-large>mdi-undo</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </span>

    <v-dialog v-model="reversal_dialog" persistent width="500">
      <v-card>
        <v-card-title>
          Reverse transaction <v-spacer></v-spacer>
          <v-btn
            :disabled="reversing"
            icon
            @click="
              reversal_dialog = false;
              reverseStep = 1;
              ticket = '';
              otp = '';
            "
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-stepper
            v-if="item"
            v-model="reverseStep"
            class="pa-0"
            style="box-shadow: none"
          >
            <v-stepper-items class="pa-0">
              <v-stepper-content class="text-center pa-0" step="1">
                <h2 class="font-weight-light">
                  Reverse sales transaction <br /><span
                    class="font-weight-black"
                    >{{ item.sales_number }}</span
                  >
                </h2>
                <h2 class="font-weight-light">
                  Amount:<strong
                    >{{ currency.code }}{{ item.amount_due | toMoney }}</strong
                  >
                </h2>

                <v-card color="orange lighten-5 mt-4" flat>
                  <v-list-item>
                    <v-list-item-title class="font-weight-bold text-left"
                      >Payment would be reversed to:</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    v-if="paymentTransaction"
                    class="text-left"
                    two-line
                  >
                    <v-list-item-avatar size="80">
                      <v-img
                        src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/user.png"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 orange--text"
                        >By:
                        {{ paymentTransaction.account_name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Account Code:
                        <strong>
                          {{ paymentNetwork }}</strong
                        ></v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Account Number:
                        <strong>
                          {{ paymentTransaction.account_number }}</strong
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-btn
                  block
                  class="mt-4"
                  color="orange"
                  dark
                  depressed
                  rounded
                  x-large
                  @click="reverseStep = 2"
                  >Confirm reversal <v-icon>mdi-arrow-right</v-icon></v-btn
                >
              </v-stepper-content>
              <v-stepper-content step="2">
                <h2
                  v-if="!ticket"
                  style="transition: 0.3s ease-in-out"
                  class="font-weight-light"
                >
                  Confirm your wallet pin to reverse this transaction.
                </h2>

                <v-progress-linear
                  v-if="otpLoading"
                  color="orange"
                  indeterminate
                ></v-progress-linear>
                <v-otp-input
                  color="orange"
                  v-show="!ticket"
                  v-model="otp"
                  :disabled="otpLoading"
                  class="mt-3"
                  length="4"
                  style="transition: 0.3s ease-in-out"
                  @finish="otpDone"
                ></v-otp-input>

                <span v-if="ticket">
                  <h2 class="red--text mt-4 font-weight-light mb-4">
                    This transaction will be deleted after a successful payout.
                  </h2>
                  <v-btn
                    :loading="reversing"
                    block
                    color="red"
                    dark
                    depressed
                    rounded
                    x-large
                    @click="confirmReversal"
                    >Confirm reversal<v-icon x-large>mdi-check</v-icon></v-btn
                  >
                </span>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" :persistent="deleting" width="400">
      <v-card v-if="item && !item.pos_sale">
        <v-card-title>Confirm delete</v-card-title>
        <v-card-text>
          <h3 class="font-weight-light red--text">
            Do you want to delete this transaction?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="deleting"
            block
            color="red"
            dark
            depressed
            rounded
            x-large
            @click="deleteSale"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-text class="pa-4 red--text">
          <h3>
            This transaction cannot be deleted directly because it was recorded
            through a customer charge, to remove this transaction you need to
            reverse the payment.
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            color="error"
            dark
            depressed
            rounded
            x-large
            @click="
              reversal_dialog = true;
              deleteDialog = false;
            "
            >Reverse transaction</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sendDialog" :persistent="sending" width="400">
      <v-card>
        <v-card-title
          >Send receipt <v-spacer></v-spacer
          ><v-btn
            :disabled="sending"
            color="red"
            icon
            @click="sendDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="sendForm">
            <v-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              color="orange"
              label="Email"
              outlined
              type="email"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="sending"
            block
            color="orange"
            dark
            depressed
            rounded
            x-large
            @click="sendReciept"
            >Send</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="green" dark>{{ message }}</v-snackbar>
    <v-snackbar v-model="error" color="error" dark>{{
      error_message
    }}</v-snackbar>
  </span>
</template>

<script>
import store from "../store";
import moment from "moment";

export default {
  name: "TransactionPreview",
  data() {
    return {
      error: false,
      error_message: "",
      reversal_dialog: false,
      message: "",
      success: false,
      item: null,
      loading: false,
      deleteDialog: false,
      otp: "",
      deleting: false,
      sendDialog: false,
      reverseStep: 1,
      email: "",
      paymentNetwork: "",
      reversing: false,
      ticket: "",
      otpLoading: false,
      sending: false,
      paymentTransaction: null,
      transactionInfo: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    settings() {
      return this.$store.state.settings;
    },
    itemID() {
      const id = this.$route.params.id;
      if (!id) {
        return this.$route.query.item_id;
      }

      return id;
    },
    business() {
      return this.$store.state.user.current_business;
    },
    currency() {
      return this.business.currency;
    },
  },
  watch: {
    itemID() {
      this.getReciept();
    },
  },
  methods: {
    confirmReversal() {
      this.reversing = true;

      window.axios
        .post("/api/bw/reverse/pos", {
          transaction_id: this.transactionInfo.id,
          ticket: this.ticket,
        })
        .then((res) => {
          this.ticket = null;
          this.otp = "";
          this.reverseStep = 1;
          this.reversing = false;
          this.$router.push({ path: "/transactions" });
          this.$emit("changed");
        })
        .catch((error) => {
          this.reversing = false;
        });
    },

    otpDone() {
      this.otpLoading = true;
      window.axios
        .post("/api/bw/verifypin", {
          pin: this.otp,
        })
        .then((res) => {
          this.ticket = res.data.passport;
          this.otpLoading = false;
        })
        .catch(() => {
          this.otpLoading = false;
        });
    },

    resolveAccount(code, account_number) {
      window.axios
        .get(
          "/api/resolveaccount?bank_code=" +
            code +
            "&account_number=" +
            account_number
        )
        .then((res) => {
          this.paymentTransaction = res.data;
        });
    },
    sendReciept() {
      if (this.$refs.sendForm.validate()) {
        this.sending = true;

        window.axios
          .post("/api/sendreciept/" + this.item.id, {
            email: this.email,
          })
          .then((res) => {
            this.message = "Receipt sent successfully";
            this.success = true;
            this.sendDialog = false;
            this.sending = false;
          });
      }
    },

    formatDate(date) {
      return moment(date).format("MMM Do, YYYY");
    },

    deleteSale() {
      this.deleting = true;
      const URL = "/api/sale/" + this.itemID;

      window.axios.delete(URL).then((res) => {
        this.$router.push({ path: "/transactions" });
        this.deleting = false;
        this.deleteDialog = false;
        this.$emit("changed");
      });
    },

    print() {
      this.$store.state.printURL =
        store.state.baseUrl + "/printposreceipt/" + this.item.enc_id;
      this.$store.state.showPrint = true;
    },

    getPaymentTransaction(saleID) {
      window.axios.get("/api/pos/transaction/" + saleID).then((res) => {
        this.transactionInfo = res.data;

        let code = "MTN";

        if (res.data.network == "VODAFONE") {
          code = "VOD";
        }

        this.paymentNetwork = res.data.network;

        this.resolveAccount(code, res.data.account_number);
      });
    },
    getReciept() {
      if (!this.itemID) {
        this.item = null;
        return;
      }
      this.loading = true;
      const URL = "/api/sale/" + this.itemID;

      window.axios.get(URL).then((res) => {
        this.item = res.data;

        if (this.item.pos_sale) {
          this.getPaymentTransaction(this.item.id);
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getReciept();
  },
};
</script>

<style scoped></style>
